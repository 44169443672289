import React, { Component } from "react";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";

import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap";
import SelectFollowers from "../../components/Network/SelectFollowers";
import classnames from "classnames";
import { CopyToClipboard } from "react-copy-to-clipboard";
import share_icon from "../../assets/icons/share-icon.png";
import JoyRide from "react-joyride";

const host = `${window.location.protocol}//${window.location.host}`;
class ShareSellerModal extends Component {
  initState = {
    processing: false,
    emails: [""],
    message: `Take a look at this home I found on Homematchx.\n${host}${
      this.props.location?.pathname || `/sellers/${this.props.seller}`
    }`,
    successMessage: "",
  };

  state = {
    isOpen: false,
    copied: false,
    currentUrl: `${host}${
      this.props.location?.pathname || `/sellers/${this.props.seller}`
    }`,
    activeTab: 0,
    ...this.initState,
    selectedGroup: "",
    joyRide: this.props.user?.hasOwnProperty("propertyPageJoyRide")
      ? this.props.user?.propertyPageJoyRide
      : true,
  };

  toggleTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  toggle = () => {
    const state = !this.state.isOpen;
    this.setState({
      isOpen: state,
    });
  };

  onEmailChange = ({ target }, index) => {
    const { emails } = this.state;
    const newEmails = [...emails];
    const { value } = target;
    newEmails[index] = value;

    this.setState({
      emails: newEmails,
    });
  };

  shareFacebookShareLink = () => {
    const { currentUrl } = this.state;
    const url = `https://web.facebook.com/sharer/sharer.php?u=${currentUrl}`;
    window.location = url;
  };
  shareTwitterShareLink = () => {
    const { message } = this.state;
    const url = `https://twitter.com/intent/tweet?text=${message}`;
    window.location = encodeURI(url);
  };
  shareLinkedInShareLink = () => {
    const { currentUrl } = this.state;
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=Home on Homematchx&summary=&source=`;
    window.location = url;
  };
  sharePintrestShareLink = () => {
    const { currentUrl } = this.state;
    const url = `http://pinterest.com/pin/create/link/?url=${currentUrl}`;
    window.location = url;
  };

  addEmail = () => {
    const { emails } = this.state;
    this.setState({
      emails: [...emails, ""],
    });
  };

  sendEmail = () => {
    const { emails, message } = this.state;
    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
    this.setState({ processing: true, successMessage: "" });
    sendEmail({
      subject: "Home at Homematchx",
      email: emails,
      body: message,
    })
      .then(() => {
        this.setState({
          ...this.initState,
          successMessage: "Email Sent Successfully.",
        });
      })
      .catch((error) => {
        alert("Error While Sending Email, ", error.message);
        console.log({ error });
        this.setState({ processing: false });
      });
  };

  onDropDownChange = ({ target }) => {
    const { value } = target;
    const {
      followers: { followers: followersData, followings },
      groups,
    } = this.props;

    let source = [];

    if (value === "selective") {
      return this.setState({
        selectedGroup: value,
        emails: [],
      });
    }

    if (value === "all") {
      source = [...(followersData || []), ...(followings || [])];
    }

    const selectedGroup = (groups || []).filter((e) => e.id === value);

    if ((selectedGroup || []).length > 0) {
      const { followers } = selectedGroup[0] || {};
      source = followers;
    }

    const selectedEmails = (source || []).map((follower) => {
      const { email } = follower;
      return email;
    });

    if ((selectedEmails || []).length > 0) {
      this.setState({
        emails: selectedEmails,
        selectedGroup: value,
      });
    }
  };

  onSelectChange = (selectedValue) => {
    const selectedEmails = (selectedValue || []).map((v) => {
      const { value } = v;
      const { email } = value || {};
      return email;
    });

    this.setState({
      emails: selectedEmails,
    });
  };

  render() {
    const {
      activeTab,
      isOpen,
      currentUrl,
      copied,
      emails,
      message,
      processing,
      successMessage,
      selectedGroup,
      joyRide,
    } = this.state;

    const { groups, user, seller } = this.props;
    const {
      shareFacebookShareLink,
      shareLinkedInShareLink,
      sharePintrestShareLink,
      shareTwitterShareLink,
      onEmailChange,
      addEmail,
      sendEmail,
    } = this;

    return (
      <>
        <JoyRide
          size={30}
          steps={[
            {
              target: "#share-btn",
              title: "Copy Link",
              content:
                "To recieve offers on this listing click the box to copy the link and paste in your MLS offer instructions.",
              disableBeacon: true,
              placement: "right",
            },
          ]}
          run={joyRide && user?.uid == this.props.seller.seller_id}
          callback={(data) => {
            const { action, status } = data;
            if (action === "close") {
              this.setState({ joyRide: false });
            }
            if (status === "finished") {
              this.setState({ joyRide: false });
              const db = firebase.firestore();
              db.collection("users").doc(user?.uid).update({
                propertyPageJoyRide: false,
              });
            }
          }}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          styles={{
            buttonNext: {
              color: "#ffffff",
              lineHeight: "0.9",
              padding: "10px",
            },
          }}
        />
        {this.props.comingFrom === "globalOffers" ? (
          <button
            style={{
              border: "1px solid #EBEBEB",
              borderRadius: "5px",
              padding: "5px 10px",
              fontSize: "12px",
              fontWeight: "600",
              background: "none",
              cursor: "pointer",
            }}
            className={`mr-2 info-icon ${this.props.mobile && "w-100"}`}
            onClick={this.toggle}
            id="share-btn"
          >
            <i className="fa fa-share-alt mr-1" />
            Share
          </button>
        ) : (
          <Button
            outline
            color="btn btn-success share-home-btn"
            block
            onClick={this.toggle}
            id="share-btn"
            className="m-0"
          >
            {/* <i className="fa fa-share" />  */}
            <img src={share_icon} />
            Share
          </Button>
        )}

        <Modal isOpen={isOpen} toggle={this.toggle} className="modal-danger">
          <ModalHeader toggle={this.toggle}> Share Home</ModalHeader>
          <ModalBody>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={0}>
                <Input
                  type="text"
                  name="currentUrl"
                  value={currentUrl}
                  disabled
                  className="mt-3"
                />
                <Row className="my-3">
                  <Col sm={{ size: 4, offset: 4 }}>
                    <CopyToClipboard
                      text={currentUrl}
                      onCopy={() => this.setState({ copied: true })}
                    >
                      <Button
                        color="danger"
                        className="btn-theme"
                        block
                        style={{ marginTop: "5px" }}
                      >
                        <i className="fa fa-clipboard" />
                        {copied ? " Copied" : " Copy Link"}
                      </Button>
                    </CopyToClipboard>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={1}>
                <Row>
                  <Col sm="12">
                    {emails.map((v, i) => {
                      return (
                        <FormGroup key={i}>
                          <Input
                            key={i}
                            type="email"
                            id={`email-${i}`}
                            onChange={(e) => onEmailChange(e, i)}
                            value={v}
                            placeholder={`Email ${
                              emails.length !== 1 ? i + 1 : ""
                            }`}
                          />
                        </FormGroup>
                      );
                    })}
                  </Col>
                </Row>
                <Row>
                  <Col sm={{ size: 4, offset: 8 }}>
                    <FormGroup>
                      <Button
                        onClick={addEmail}
                        color="link"
                        style={{ marginRight: "5px" }}
                        className="btn-theme"
                      >
                        <i className="fa fa-plus" /> Add More
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>

                <FormGroup>
                  <Label for="message">Your message</Label>
                  <Input
                    rows={10}
                    type="textarea"
                    name="message"
                    value={message}
                    onChange={({ target }) =>
                      this.setState({ message: target.value })
                    }
                    id="message"
                  />
                </FormGroup>

                <FormGroup>
                  <Button
                    color="danger"
                    className="btn-theme"
                    block
                    onClick={sendEmail}
                  >
                    {processing ? "Sending..." : "Send"}
                  </Button>
                </FormGroup>

                <FormGroup className="text-center">
                  <p className="text-success text-center">{successMessage}</p>
                </FormGroup>
              </TabPane>

              <TabPane tabId={2}>
                <FormGroup>
                  <Input
                    type="select"
                    value={selectedGroup}
                    onChange={this.onDropDownChange}
                  >
                    <option value="">Choose...</option>
                    <option value="all">All Users In my network</option>
                    <option value="selective">Selected Users</option>
                    {(groups || []).map((group, key) => {
                      const { id, name } = group || {};
                      return (
                        <option key={key} value={`${id}`}>
                          Group: {name}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                {selectedGroup === "selective" && (
                  <FormGroup>
                    <SelectFollowers
                      user={user}
                      onChange={this.onSelectChange}
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="message">Your message</Label>
                  <Input
                    rows={10}
                    type="textarea"
                    name="message"
                    value={message}
                    onChange={({ target }) =>
                      this.setState({ message: target.value })
                    }
                    id="message"
                  />
                </FormGroup>

                <FormGroup>
                  <Button
                    color="danger"
                    className="btn-theme"
                    block
                    onClick={sendEmail}
                  >
                    {processing ? "Sending..." : "Send"}
                  </Button>
                </FormGroup>

                <FormGroup className="text-center">
                  <p className="text-success text-center">{successMessage}</p>
                </FormGroup>
              </TabPane>
            </TabContent>
            <Nav justified>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 0 })}
                  onClick={() => {
                    this.toggleTab(0);
                  }}
                >
                  <i
                    style={{ fontSize: "15px !important" }}
                    className="fa fa-link"
                    title="Share as link"
                  />
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={() => {
                    this.toggleTab(1);
                  }}
                >
                  <i
                    style={{ fontSize: "15px !important" }}
                    className="fa fa-envelope"
                    title="Share via Email"
                  />
                </NavLink>
              </NavItem>

              {this.props.comingFrom !== "globalOffers" && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === 1 })}
                    onClick={() => {
                      this.toggleTab(2);
                    }}
                  >
                    <i
                      style={{ fontSize: "15px !important" }}
                      className="fa fa-users"
                      title="Share in your network"
                    />
                  </NavLink>
                </NavItem>
              )}

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 0 })}
                  onClick={shareFacebookShareLink}
                  target="_blank"
                >
                  <i
                    style={{ fontSize: "15px !important" }}
                    className="fa fa-facebook"
                    title="Share to Facebook"
                  />
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 0 })}
                  onClick={shareLinkedInShareLink}
                  target="_blank"
                >
                  <i
                    style={{ fontSize: "15px !important" }}
                    className="fa fa-linkedin"
                    title="Share to Linkedin"
                  />
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={shareTwitterShareLink}
                >
                  <i
                    style={{ fontSize: "15px !important" }}
                    className="fa fa-twitter"
                    title="Share to Twitter"
                  />
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === 1 })}
                  onClick={sharePintrestShareLink}
                >
                  <i
                    style={{ fontSize: "15px !important" }}
                    className="fa fa-pinterest"
                    title="Share to Pinterest"
                  />
                </NavLink>
              </NavItem>
            </Nav>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ShareSellerModal;
