import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

export const drawCustomBorderRadius = (element, ctx, isRatio = false) => {
    const x = isRatio ? 5 : 10;
    const y = isRatio ? 2.7 : 2.5;
    const startX1 = element._view.x + element._model.width / y;
    const startY1 = element._view.y - element._model.width / x;
    const startX2 = element._view.x - element._model.width / y;

    const length = 7;
    ctx.strokeStyle = '#fff';
    ctx.lineWidth = 4;
    const endX1 = startX1 + Math.cos(Math.PI / 4) * length;
    const endY1 = startY1 + Math.sin(Math.PI / 4) * length;
    const endX2 = startX2 - Math.cos(Math.PI / 4) * length;
    const endY2 = startY1 + Math.sin(Math.PI / 4) * length;

    ctx.beginPath();
    ctx.moveTo(startX1, startY1);
    ctx.lineTo(endX1, endY1);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(startX2, startY1);
    ctx.lineTo(endX2, endY2);
    ctx.stroke();
}

export const drawCustomBorderRadiusHorizontal = (
    ctx,
    startX1,
    startY1,
    startY2,
    startX2
    ) => {
    const length = 3;
    ctx.strokeStyle = '#fff';
    ctx.lineWidth = 3;
    const endX1 = startX1 + Math.cos(Math.PI / 4) * length;
    const endY1 = startY1 + Math.sin(Math.PI / 4) * length;
    const endX2 = startX2 - Math.cos(Math.PI / 4) * length;
    const endY2 = startY2 + Math.sin(Math.PI / 4) * length;

    ctx.beginPath();
    ctx.moveTo(startX1, startY1);
    ctx.lineTo(endX1, endY1);
    ctx.stroke();

    ctx.beginPath();
    ctx.moveTo(startX2, startY2);
    ctx.lineTo(endX2, endY2);
    ctx.stroke();
}

export const markConversationAsRead = (senderID, receiverID) => {
    const db = firebase.firestore();
    db.collection('conversations').doc(`${senderID}-${receiverID}`).update({
        lastSeenAt: Math.floor(Date.now() / 1000)
    });
};

export const getSelectConversation = (selectedConverstation, conversations) => {
    let conversation = null;
    if (!selectedConverstation) {
      return conversation;
    }
    conversations.forEach(c => {
      if (c.id === selectedConverstation) {
        conversation = c;
      }
    });
    return conversation;
}

export const customizeLabels = array => {
  const result = [];
  for (let i = 0; i < array.length; i++) {
    const item = array[i];

    if(item.length < 10) {
      result.push(item);
    } else if(item.length > 15) {
      const splitItems = item.split(' ');
      const mergedItems = [];
      let currentItem = '';

      for (let j = 0; j < splitItems.length; j++) {
        const word = splitItems[j];
        if(currentItem.length === 0 || currentItem.length + word.length + 1 <= 15) {
          currentItem += (currentItem.length === 0 ? '' : ' ') + word;
        } else {
          mergedItems.push(currentItem);
          currentItem = word;
        }
      }
      if(currentItem.length > 0) {
        mergedItems.push(currentItem);
      }
      const totalLength = mergedItems.reduce((sum, item) => sum + item.length, 0);
      if(totalLength > 22) {
        const lastItemIndex = mergedItems.length - 1;
        const maxLength = 22 - (totalLength - mergedItems[lastItemIndex].length);
        mergedItems[lastItemIndex] = mergedItems[lastItemIndex].slice(0, maxLength) + '...';
      }
      const nestedArray = mergedItems.length > 2 ? [mergedItems[0], mergedItems[1]] : mergedItems;
      result.push(nestedArray);
    } else {
      result.push(item);
    }
  }
  return result;
};

export const drawWhiteLine = (chart) => {
  const moveX = chart.scales["x-axis-0"].left + 9;
  const moveY = 0;
  const lineX = chart.scales["x-axis-0"].left + 9;
  const lineY = chart.scales["x-axis-0"].top - 5

  const ctx = chart.ctx;
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(moveX, moveY);
  ctx.lineTo(lineX, lineY);
  ctx.strokeStyle = '#fff';
  ctx.lineWidth = 15;
  ctx.stroke();
  ctx.closePath();
  ctx.restore();
};

export const getFilteredBuyers = (buyers,filters) => {
    return  buyers.filter(d => {
        const buyer = d.data();
        let result = true;
        
        if (filters.year_built_min) {
            const min = parseInt(filters.year_built_min);
            result = buyer.year_built_min <= min && buyer.year_built_max >= min;
        }

        if (result && filters.year_built_max) {
            const max = parseInt(filters.year_built_max);
            result = buyer.year_built_min <= max && buyer.year_built_max >= max;
        }

        if (result && filters.min_price) {
            const minPrice = parseInt(filters.min_price);
            result = buyer.min_price <= minPrice && buyer.max_price >= minPrice;
        }

        if (result && filters.max_price) {
            const maxPrice = parseInt(filters.max_price);
            result = buyer.min_price <= maxPrice && buyer.max_price >= maxPrice;
        }
        
        if (result && filters.bedrooms) {
            const bedrooms = parseInt(filters.bedrooms);
            const buyerVal = parseInt((buyer.bedrooms || '0').replace('+',''));
            result = buyerVal >= bedrooms;
        }

        if (result && filters.bathrooms) {
            const bathrooms = parseInt(filters.bathrooms);
            const buyerVal = parseInt((buyer.bathrooms || '0').replace('+',''));
            result = buyerVal >= bathrooms;
        }

        if (result && filters.sqft_min) {
            const minSqft = parseInt(filters.sqft_min)
            result = buyer.sqft_min <= minSqft && buyer.sqft_max >= minSqft;
        }

        if (result && filters.sqft_max) {
            const maxSqft = parseInt(filters.sqft_max)
            result = buyer.sqft_min <= maxSqft && buyer.sqft_max >= maxSqft;
        }

        if(result && filters.location){
            const prefLocation = filters.location;
            const prefDistance = filters.distance || 10; // default is 10 miles 
            const {location} = buyer;
            // if location is not present, then skip that buyer
            if(!location){
                result = false;
            }else{
                // now lets calculate distance 
                const distance = calculateDistance(location,prefLocation);
                result = distance < prefDistance;
            }
        }
        
        return result;
    });
}

const calculateDistance = (posA, posB) => {
    const earthRadius = 6371;
    var lat = posB.lat - posA.lat; // Difference of latitude
    var lon = posB.lng - posA.lng; // Difference of longitude

    var disLat = (lat*Math.PI*earthRadius)/180; // Vertical distance
    var disLon = (lon*Math.PI*earthRadius)/180; // Horizontal distance

    var ret = Math.pow(disLat, 2) + Math.pow(disLon, 2); 
    ret = Math.sqrt(ret);

    return ret * 0.621371 // convert km to miles
} 

export const getFilteredHomes = (homes,filters) => {
    return  homes.filter(d => {
        const seller = d.data();
        let result = true;
        
        if (filters.year_built_min) {
            const min = parseInt(filters.year_built_min);
            result = seller.year_built >= min;
        }

        if (result && filters.year_built_max) {
            const max = parseInt(filters.year_built_max);
            result = seller.year_built <= max;
        }

        if (result && filters.sqft_min) {
            const minSqft = parseInt(filters.sqft_min)
            result = seller.sqft >= minSqft;
        }

        if (result && filters.sqft_max) {
            const maxSqft = parseInt(filters.sqft_max)
            result = seller.sqft <= maxSqft;
        }

        if (result && filters.bedrooms) {
            const bedrooms = parseInt(filters.bedrooms);
            const v = parseInt(seller.bedrooms);
            result = v >= bedrooms;
        }

        if (result && filters.bathrooms) {
            const bathrooms = parseInt(filters.bathrooms);
            const v = parseInt(seller.bathrooms);
            result = v >= bathrooms;
        }

        const sellerPrice = parseInt(seller.expected_sales_price || seller.projectedPrice || seller.current_value);

        if (result && filters.min_price) {
            const minPrice = parseInt(filters.min_price);
            result = sellerPrice >= minPrice;
        }

        if (result && filters.max_price) {
            const maxPrice = parseInt(filters.max_price);
            result = sellerPrice <= maxPrice;
        }

        if (result && filters.purchase_month && seller.type !== 'MLS') {
            console.log('in..........');
            const v = filters.purchase_month;
            result =  seller.expected_listing_month === v;
        }
      
        if (result && filters.purchase_year && seller.type !== 'MLS') {
            const v = parseInt(filters.purchase_year);
            result =  parseInt(seller.expected_listing_year) === v;
        }

        console.log(result)
        
        // if(result && filters.location){
        //     const prefLocation = filters.location;
        //     // const prefDistance = parseInt(filters.distance || 10); // default is 10 miles 
        //     const prefDistance = filters.distance || 10; // default is 10 miles 
        //     const { location } = seller;
        //     // if location is not present, then skip that buyer
        //     if(!location){
        //         result = false;
        //     }else{
        //         // now lets calculate distance 
        //         const distance = calculateDistance(location,prefLocation);
        //         result = distance < prefDistance;
        //         console.log(distance, prefDistance);
        //     }
        // }
        
        return result;
    });
}

export const real = {
    login: (email, password) => {
        return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
    },
    loginWithProvider: (providerName) => {
        
        let provider

        if(providerName === 'facebook'){
            provider = new firebase.auth.FacebookAuthProvider();
        }else if (providerName === 'google') {
            provider = new firebase.auth.GoogleAuthProvider();
        }

        return firebase.auth().signInWithPopup(provider)
    },
    updateFields(collection, id, updates) {
        const db = firebase.firestore()
        const ref = db.collection(collection).doc(id)
        return ref.update(updates)
    }
}

const state = {}

export const fake = {
    login: (email, password) => {
        return new Promise((resolve, reject) => {
            state.user = {email: email}
            resolve()
        })
    }
}