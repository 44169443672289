import React from 'react';
import { Container } from 'reactstrap';
import 'firebase/firestore';
import '../assets/css/analytics-page.css';
import offerDetails from "../templates/email/offer-details-browser-version.js";

const  EmailPreview = props => {

  if(!localStorage['email-preview']) return window.location.href = '/'

  const { inputs, seller, id, fromNewOffer } = JSON.parse(localStorage['email-preview'])

  return (
    <div className="analytics-main">
      <Container className="dashboard-main">
        <div dangerouslySetInnerHTML={{ __html: offerDetails(seller,inputs, id, fromNewOffer) }} />
      </Container>
    </div>
  );
}

export default EmailPreview;
