import DoughnutChart from "components/Charts/DoughnutChart";
import React, { useState, useEffect, useRef } from "react";
import { Bar, Line, Pie, Polar, Doughnut } from "react-chartjs-2";
import { drawCustomBorderRadius, drawWhiteLine } from "utils/Functions";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import firebase from "firebase";
import InfoCard from "./infoCard/InfoCard";

function MySubscriptionAnalytics(props) {
  const [properties, setProperties] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loader, setLoader] = useState(true);
  const [lowerSliceLimit, setLowerSliceLimit] = useState(0);
  const [upperSliceLimit, setUpperSliceLimit] = useState(20);
  const [dataValues, setDataValues] = useState([]);
  const [barChartLabels, setBarChartLabels] = useState([]);

  const chartContainerRef = useRef(null);

  useEffect(() => {
    const db = firebase.firestore();
    var data = [];
    db.collection("sellers")
      .where("seller_id", "==", props.user?.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setProperties(
          data
          // .filter((property) => {
          //   return property.MlsStatus === "Active";
          // })
        );
      });
  }, []);

  useEffect(() => {
    const data = properties
      .slice(lowerSliceLimit, upperSliceLimit)
      .map((property) => {
        return {
          name: property.UnparsedAddress,
          // views: Math.floor(Math.random() * 100) + 1,
          revenue: property?.totalThankMeRevenue || 0,
        };
      });
    setDataValues(data);
    setBarChartLabels(
      properties.slice(lowerSliceLimit, upperSliceLimit).map((property) => {
        return property.UnparsedAddress;
      })
    );
  }, [properties, lowerSliceLimit, upperSliceLimit]);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [properties]);

  const topFiveMostViewedProperties = () => {
    const topFive = properties
      .filter((property) => {
        return property.hasOwnProperty("impressions");
      })
      .sort((a, b) => {
        return b.impressions - a.impressions;
      })
      .slice(0, 20);
    //  return as array of objects
    return topFive.map((property) => {
      return {
        name: property.UnparsedAddress,
        views: property.impressions,
      };
    });
  };

  const calculateThankmeClicks = () => {
    let totalThankMeClicks = 0;
    properties.forEach((property) => {
      totalThankMeClicks += property?.thankMeClickCount || 0;
    });
    return totalThankMeClicks;
  };

  const calculateTotalSales = () => {
    let totalSales = 0;
    properties.forEach((property) => {
      totalSales += property?.totalThankMePurchase || 0;
    });
    return totalSales;
  };

  const calculateTotalRevenue = () => {
    let totalRevenue = 0;
    properties.forEach((property) => {
      totalRevenue += property?.totalThankMeRevenue || 0;
    });
    return totalRevenue;
  };

  const calculateTotalOfferTrendsViews = () => {
    let totalOfferTrendsViews = 0;
    properties.forEach((property) => {
      totalOfferTrendsViews += property?.offerTrendsClickCount || 0;
    });
    return totalOfferTrendsViews;
  };

  const canvasChart = useRef(null);
  const lineData = {
    labels: properties.map((property) => {
      return property.UnparsedAddress?.slice(0, 10) + "...";
    }),
    datasets: [
      {
        label: "",
        // data: properties.map((property) => {
        //   return Math.floor(Math.random() * 100) + 1;
        // }),
        data: properties.map((property) => {
          return property?.impressions || 0;
        }),
        fill: true,
        responsive: true,

        opacity: "0",
        borderColor: "#03CA70",
        backgroundColor: (context) => {
          const { chart } = context;
          const { ctx } = chart;
          const gradient = ctx.createLinearGradient(0, 100, 0, 300);
          gradient.addColorStop(0, "#fff");
          gradient.addColorStop(1, "#03ca70");
          return gradient;
        },
        hoverRadius: 8,
        hoverBackgroundColor: "#ffffff",
        hoverBorderColor: "#109CF1",
        hoverBorderWidth: 4,
      },
    ],
  };

  const lineOptions = {
    onHover: (_, event) => {
      if (event?.length) {
        drawCustomLabels2(canvasChart.current.chartInstance, event);
      }
    },
    tooltips: {
      enabled: true,
      backgroundColor: "#646464",
      titleAlign: "center",
      bodyAlign: "center",
      borderDash: 5,
      cornerRadius: 4,
      xPadding: 18,
      yPadding: 8,
      mode: "x",
      callbacks: {
        title: (e) => `${e[0].value} Views,`,
        // beforeLabel: (e) => formatDate(e.label),
        label: (e) => `${e.label}`,
      },
    },
    layout: {
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            borderDash: [4, 4],
            lineWidth: 2,
            zeroLineColor: "rgba(235, 235, 235, 1)",
            zeroLineWidth: 2,
            zeroLineBorderDash: [0, 2],
            zeroLineBorderDashOffset: 2,
            zeroLineColor: "#ffffff",
            drawBorder: false,
            z: 1,
          },
          ticks: {
            padding: 20,
            crossAlign: "start",
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            zeroLineColor: "rgba(235, 235, 235, 1)",
            zeroLineWidth: 2,
            zeroLineBorderDash: [0, 2],
            zeroLineBorderDashOffset: 2,
            zeroLineColor: "#ffffff",
          },
        },
      ],
    },
  };

  const drawCustomLabels2 = (chart, event) => {
    setTimeout(() => {
      const ctx = chart.ctx;
      ctx.closePath();
      ctx.stroke();
      ctx.save();
      ctx.beginPath();
      ctx.setLineDash([0, 0]);
      const labelX = event[0]._view.x;
      const labelY = event[0]._view.y;
      ctx.moveTo(labelX, labelY);
      ctx.lineTo(labelX, chart.height - 30);
      ctx.strokeStyle = "#b4c8e2";
      ctx.lineWidth = 1;
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    }, 400);
  };

  // const barChartLabels = properties.slice(0, 20).map((property) => {
  //   return property.UnparsedAddress;
  // });
  // const dataValues = properties.map((property) => {
  //   return {
  //     name: property.UnparsedAddress,
  //     // views: Math.floor(Math.random() * 100) + 1,
  //     revenue:
  //       property?.totalThankMeRevenue || Math.floor(Math.random() * 100) + 1,
  //   };
  // });

  const drawCustomLabels = (chart) => {
    drawWhiteLine(chart);
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;
      if (!meta.hidden)
        elements.forEach((element, elementIndex) => {
          const maxValue = Math.max(...dataset.data);
          if (maxValue / dataset.data[elementIndex] <= 40) {
            drawCustomBorderRadius(element, ctx);
          }
          const value = dataset.data[elementIndex];
          if (value > 0) {
            ctx.save();
            ctx.textBaseline = "middle";
            ctx.fillStyle = "black";
            ctx.font = "bold 10px Raleway";
            const labelX = element._view.x;
            const labelY = element._view.y;
            if (value < 1000) {
              ctx.fillText(
                value,
                labelX - value.toString().length * 3,
                labelY - 10
              );
            } else if (value >= 1000 && value < 1000000) {
              ctx.fillText(
                (value / 1000).toFixed(0) + "K",
                labelX - (value / 1000).toFixed(0).length * 3,
                labelY - 10
              );
            } else if (value >= 1000000 && value < 1000000000) {
              ctx.fillText(
                (value / 1000000).toFixed(0) + "M",
                labelX - (value / 1000000).toFixed(0).length * 3,
                labelY - 10
              );
            } else if (value >= 1000000000 && value < 1000000000000) {
              ctx.fillText(
                (value / 1000000000).toFixed(0) + "B",
                labelX - (value / 1000000000).toFixed(0).length * 3.75,
                labelY - 10
              );
            } else if (value >= 1000000000000) {
              ctx.fillText(
                (value / 1000000000000).toFixed(0) + "T",
                labelX - (value / 1000000000000).toFixed(0).length * 3,
                labelY - 10
              );
            }
            ctx.restore();
          }
        });
    });
  };

  const option = {
    layout: {
      padding: {
        top: 20,
      },
    },
    tooltips: {
      callbacks: {
        label: (e) => {
          const value = Number(e.value);
          if (value === 0) {
            return value;
          } else if (value < 1000) {
            return value;
          } else if (value >= 1000 && value < 1000000) {
            return (value / 1000).toFixed(0) + "K";
          } else if (value >= 1000000 && value < 1000000000) {
            return (value / 1000000).toFixed(0) + "M";
          } else if (value >= 1000000000 && value < 1000000000000) {
            return (value / 1000000000).toFixed(0) + "B";
          } else if (value >= 1000000000000) {
            return (value / 1000000000000).toFixed(2) + "T";
          }
        },
      },
    },
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },

    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: dataValues?.length > 0,
            borderDash: [5, 5],
            lineWidth: 2,
            borderDashOffset: 20,
            color: "rgba(235, 235, 235, 1)",
            drawBorder: false,
            drawTicks: false,
          },
          ticks: {
            callback: (value) => {
              if (dataValues?.length === 0) return "";
              if (value === 0) {
                return value;
              } else if (value < 1000) {
                return value;
              } else if (value >= 1000 && value < 1000000) {
                return (value / 1000).toFixed(0) + "K";
              } else if (value >= 1000000 && value < 1000000000) {
                return (value / 1000000).toFixed(0) + "M";
              } else if (value >= 1000000000 && value < 1000000000000) {
                return (value / 1000000000).toFixed(0) + "B";
              } else if (value >= 1000000000000) {
                return (value / 1000000000000).toFixed(2) + "T";
              }
            },
            fontFamily: "Raleway",
            fontSize: 14,
            padding: 20,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
            color: "#fff",
            drawBorder: false,
            drawTicks: false,
            zeroLineWidth: 0.5,
            drawOnChartArea: function (context) {
              return context.tick.index === 0;
            },
          },
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            fontSize: 10,
            padding: 20,
            callback: (value) => {
              if (value?.length > 10) {
                return value.slice(0, 10) + "...";
              }
              return value;
            },
          },
        },
      ],
    },
  };

  const data = {
    // labels: mobile
    //   ? Object.keys(allOffer).slice(lowerSliceLimit, upperSliceLimit)
    //   : Object.keys(allOffer),
    labels: barChartLabels,
    datasets: [
      {
        label: "",
        data: dataValues?.map((info) => {
          return info.revenue;
        }),
        backgroundColor: "#109CF1",
        // backgroundColor: dataValues?.map((_, index) => {
        //   const colors = ["#109CF1", "#04d0c8", "#03ca70", "#533af8"];
        //   return colors[index % colors.length];
        // }),
        stack: 1,
        barThickness: 32,
        borderWidth: 0,
      },
    ],
    categoryPercentage: 10,
  };

  return (
    <div className="content-area5 dashboard-content">
      <div className="dashboard-header">
        <h4 className="seller-main-heading">Sales Analytics</h4>
        <div className="row mt-3">
          <div className="col-6 col-lg-3 p-0">
            <InfoCard
              className="responsive-height"
              infoName={"Thank Me Clicks"}
              infoValue={calculateThankmeClicks()}
            />
          </div>
          <div className="col-6 col-lg-3 p-0 pl-0 pl-lg-2">
            <InfoCard
              className="responsive-height"
              infoName={"OfferTrends Views"}
              infoValue={calculateTotalOfferTrendsViews()}
            />
          </div>
          <div className="col-6 col-lg-3 p-0 mt-3 mt-lg-0 pl-0 pl-lg-2">
            <InfoCard
              className="responsive-height"
              infoName={"Total Number Of Sales"}
              infoValue={calculateTotalSales()}
            />
          </div>
          <div className="col-6 col-lg-3 p-0 mt-3 mt-lg-0 pl-0 pl-lg-2">
            <InfoCard
              className="responsive-height"
              infoName={"Total Revenue"}
              infoValue={"$" + calculateTotalRevenue()}
            />
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-lg-12 p-0 mb-5 mb-lg-0 p-3 my-analytics-container">
            <p>Property Listing Revenue</p>
            <div
              ref={chartContainerRef}
              className="chartWrapper-scroll chartWrapper-scroll-global"
              style={{
                ...((dataValues && dataValues.length) || !dataValues.length
                  ? { marginRight: 0 }
                  : { marginRight: 30 }),
              }}
              onScroll={(e) => {
                const container = chartContainerRef.current;
                const scrollEndPosition =
                  container.scrollWidth - container.clientWidth;
                const center = scrollEndPosition / 2;
                if (
                  properties.length === upperSliceLimit &&
                  Math.ceil(container.scrollLeft) >= scrollEndPosition
                ) {
                  return;
                }
                if (Math.ceil(container.scrollLeft) >= scrollEndPosition) {
                  container.scrollTo(center, 0);
                  if (properties.length - upperSliceLimit < 10) {
                    setLowerSliceLimit(properties.length - 20);
                    setUpperSliceLimit(properties.length);
                  } else {
                    setLowerSliceLimit((prev) => prev + 10);
                    setUpperSliceLimit((prev) => prev + 10);
                  }
                } else if (Math.ceil(container.scrollLeft) === 0) {
                  if (lowerSliceLimit >= 20) {
                    setLowerSliceLimit((prev) => prev - 10);
                    setUpperSliceLimit((prev) => prev - 10);
                    container.scrollTo(center, 0);
                  } else {
                    setLowerSliceLimit(0);
                    setUpperSliceLimit(20);
                  }
                }
              }}
            >
              <div
                style={{
                  width: `${
                    dataValues?.length * 100 > 950
                      ? dataValues?.length * 100
                      : "100%"
                  }px`,
                  minHeight: "400px",
                  // position: !dataValues?.length ? "relative" : "inherit",
                }}
              >
                {!loader ? (
                  <Bar
                    options={option}
                    data={data}
                    plugins={[
                      {
                        id: "customLabels",
                        afterDatasetsDraw: (chart) => drawCustomLabels(chart),
                      },
                    ]}
                    height={
                      (4500 /
                        (dataValues?.length * 100 > 950
                          ? dataValues?.length * 100
                          : 950)) *
                      30
                    }
                  />
                ) : (
                  <img
                    src="/img/loader.gif"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-lg-5 p-0 mb-5 mb-lg-0">
            <div className="p-3 my-analytics-container">
              <p>Property Listing Impressions</p>
              <div
                style={{
                  width: "100%",
                  overflowX: "auto",
                  // paddingBottom: "100px",
                }}
              >
                <div
                  style={{ width: "3000px", height: 300, cursor: "pointer" }}
                >
                  {!loader ? (
                    <Line
                      height={300}
                      width={0}
                      data={lineData}
                      ref={canvasChart}
                      options={lineOptions}
                    />
                  ) : (
                    <img
                      src="/img/loader.gif"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-3 p-0">
            <div className="p-3 my-analytics-container ml-0 ml-lg-3 h-100">
              <p>Thank Me Clicks VS Purchases</p>
              <div className="mt-5 text-center">
                <DoughnutChart
                  className="charts"
                  value={calculateTotalSales()}
                  total={calculateThankmeClicks()}
                  color={"#dc3545"}
                  title={"Purchases"}
                />
                <span
                  style={{
                    color: "#109CF1",
                    fontSize: "10px",
                    fontWeight: 500,
                    fontFamily: "Poppins",
                    backgroundColor: "rgba(16, 156, 241, 0.1)",
                    border: "1px solid rgba(16, 156, 241, 0.2)",
                    borderRadius: "2px",
                    padding: "0 4px ",
                    // left: "49px",
                    // position: " relative",
                    // bottom: "200px",
                  }}
                >
                  {`
                  ${calculateTotalSales()} purchases out of ${calculateThankmeClicks()} clicks
                  ${(
                    (calculateTotalSales() / calculateThankmeClicks()) *
                    100
                  ).toFixed(2)}`}
                  %
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-4 p-0 pl-0 pl-lg-3 mt-3 mt-lg-0">
            <div className="p-3 my-analytics-container h-100">
              <div className="row">
                <div className="col-9">
                  <h5 className="d-inline">Top Properties</h5>
                </div>
                <div className="col-3">
                  <h5 className="d-inline">Views</h5>
                </div>
              </div>
              <div className="scrolling-container p-0 mt-3">
                {topFiveMostViewedProperties().map((property, index) => (
                  <div key={index} className="row pl-3 pr-4 mt-15">
                    <div className="col-10 p-0">
                      <span className="text-blue mr-2">
                        <i class="fa fa-home" aria-hidden="true"></i>
                        {/* {index + 1}
                        {". "} */}
                      </span>
                      {property.name.slice(0, 25) + "..."}
                    </div>
                    <div className="col-2 p-0">
                      <span className="float-right text-blue">
                        {property.views}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MySubscriptionAnalytics;
