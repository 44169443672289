import React, { Component } from "react";

class TermsOfUse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
    };
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        <div className="about-real-estate content-area-5">
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8">
                <h3>Terms of Use and Privacy Policy</h3>
                <p>
                  <i>Last updated: August 24th, 2023</i>
                </p>

                <p>
                  Homematchx, LLC (“us”, “we”, “our”) operates
                  https://www.homematchx.com (the “Sites”). This page informs
                  you of our policies regarding the collection, use and
                  disclosure of Personal Information we receive from users of
                  the Site. Additionally, it informs you of the expectations of
                  you while using the Site. By accessing the Site, you agree to
                  the collection and use of information in accordance with the
                  Privacy Policy, and to use the Site in compliance with the
                  Terms of Use. All policies are governed by the laws of the
                  State of Texas without regard to conflict of law provisions.
                  <strong>
                    These Terms of Use affect your legal rights and obligations,
                    and should be read in full prior to use of the Site. Use of
                    the Site serves as your acceptance of the Terms of Use and
                    Privacy Policy below.
                  </strong>
                </p>

                <h4>Terms of Use</h4>
                <h5>Intellectual Property Ownership</h5>

                <p>
                  All information provided on the Site which is subject to
                  copyright, trademark, or patent protection is the property of
                  us, and will remain property of us. By using the site, you do
                  not have the right to copy, reproduce, sell, use, or otherwise
                  exercise the Intellectual Property rights in any way.
                </p>

                <h5>Privacy Policy</h5>

                <p>
                  Our privacy policies and use and collection of personal
                  information is outlined in our Privacy Policy, included on
                  this page. The Privacy Policy is incorporated herein by
                  reference.{" "}
                </p>

                <h5>Disclaimer of Warranties and Liability</h5>

                <p>
                  THIS SITE IS PROVIDED “AS IS”. WE MAKE NO REPRESENTATION OR
                  WARRANTY OF ANY KIND WHATSOEVER RELATING TO THE SITE, LINKED
                  SITES, OR OTHER CONTENT THAT MAY BE ACCESSIBLE DIRECTLY OR
                  INDIRECTLY THROUGHOUT THE SITE. WE DISCLAIM TO THE MAXIMUM
                  EXTENT PERMITTED BY LAW ANY AND ALL SUCH REPRESENTATIONS AND
                  WARRANTIES.{" "}
                </p>

                <p>
                  WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE DISCLAIM
                  TO THE MAXIMUM EXTENT PERMITTED BY LAW ANY AND ALL (a)
                  WARRANTIES OR MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                  PURPOSE, (b) WARRANTIES AGAINST INFRINGEMENT OF ANY
                  THIRD-PARTY INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS, (c)
                  WARRANTIES RELATING TO THE TRANSMISSION OR DELIVERY OF THE
                  SITE, (d) WARRANTIES RELATING TO THE ACCURACY, RELIABILITY,
                  CORRECTNESS, OR COMPLETENESS OF DATA MADE AVAILABLE ON THE
                  SITE OR OTHERWISE BY US, AND (e) WARRANTIES OTHERWISE RELATING
                  TO PERFORMANCE, NONPERFORMANCE, OR OTHER ACTS OR OMISSIONS BY
                  US OR ANY THIRD PARTY. FURTHER THERE IS NO WARRANTY THAT THE
                  SITE WILL MEET YOUR NEEDS OR REQUIREMENTS OR THE NEEDS OR
                  REQUIREMENTS OF ANY OTHER PERSON.
                </p>

                <p>
                  Additionally, no product or service purchased on the Site is
                  guaranteed to be suitable for a particular purpose. It is the
                  responsibility of the customer to review any and all products
                  produced from the Site for use in any specific manner. If you
                  are a broker uploading client information, we are not
                  responsible for ensuring that you have proper permissions and
                  approval to include their information on the Site. We will not
                  be liable for any damage to person or property stemming from
                  misuse of a product or service produced from the Site.
                  Additionally, we will not be liable for any mistakes made by
                  the customer in the ordering or designing process. All
                  contracts for product or services signed on the Site are
                  subject to the terms and conditions specifically enumerated in
                  the contract.
                </p>

                <h5>Minors</h5>

                <p>
                  We do not specifically market to children under the age of 13
                  years old, or minors under the age of 18 years old.
                  Additionally, the minimum age to engage with this Site, and
                  order product from this Site is 18 years old.{" "}
                </p>

                <h5>Indemnification</h5>

                <p>
                  User agrees to defend, indemnify, and hold harmless the Site
                  and Homematchx, LLC, along with its partners, owners, members,
                  principals, employees, contractors, attorneys, agents, and
                  representatives (individually, an “Indemnified Party” and
                  collectively, the “Indemnified Parties”), for, against, and
                  from (a) all third party claims arising out of or related to
                  User’s use, actions, and statements made during the course of
                  using the Site, including any losses, costs, damages, expenses
                  (including, without limitation, attorneys’ fees) or other
                  liabilities incurred by the Site and Homematchx, LLC in
                  responding to such third party claims, and (b) liabilities,
                  claims (including, without limitation, claims and liabilities
                  relating to remediation, bodily injury and property damage),
                  causes of action, suits, judgments, damages, losses, costs,
                  and expenses (including, without limitation, court costs,
                  reasonable attorneys’ fees, and costs of work) of any nature
                  (individually and collectively, “Losses”), to the extent
                  arising out of, resulting from or incurred as a result of
                  Contractor’s work, work product, actions, or statements made
                  during the term of this Agreement
                </p>

                <h5>User Comments, Posts, and Feedback</h5>

                <p>
                  Any information you transmit by text or post to the Site shall
                  be considered non-confidential and non-proprietary, except for
                  your Personal Information which is covered under the Privacy
                  Policy. We do not claim ownership of any information or
                  material you transmit, distribute, post, communicate, or send.
                  We are not responsible for any transactions or interactions
                  that take place off the Site, even if between individuals who
                  were connected through use of the Site. All transactions
                  and/or interactions that take place off the Site happen at
                  your own risk.{" "}
                </p>

                <p>
                  Additionally, you agree not to transmit, distribute, post,
                  communicate, or store information on this Site that: (a) is
                  copyrighted, unless you are the copyright owner; (b) violates
                  or infringes on any other intellectual property rights of
                  others or the privacy or publicity rights of others; (c)
                  reveals a trade secret, unless you own it; (d) is obscene,
                  libelous, defamatory, threatening, harassing, abusive,
                  malicious, hateful, sexually explicit, or embarrassing to any
                  other person or entity, advocates, promotes, incites,
                  instructs, informs, assists or otherwise encourages violence
                  or other illegal activities, or involves fraud, stalking, or
                  otherwise violating the legal rights of others, is harmful to
                  minors, attempts to mislead others about your identity or the
                  origin of a message or other communication, or impersonates or
                  otherwise misrepresents your affiliation with any other person
                  or entity, or is otherwise materially false, misleading, or
                  inaccurate or otherwise objectionable, all as determined by
                  RSL in its sole discretion; (e) violates any applicable law or
                  regulation; (f) constitutes or contains junk mail, spam,
                  advertisements or solicitations of a business, surveys,
                  contests, chain letters, or pyramid schemes; (g) contains
                  viruses, Trojan horses, worms, time bombs, or other computer
                  programming routines, engines, or other software, data, or
                  programs that are intended to or may damage, detrimentally
                  interfere with, surreptitiously intercept or expropriate any
                  system, data information, or property of another including,
                  but not limited to, the Site; (h) is intended to circumvent,
                  or otherwise coordinate transactions around or without, the
                  functionality provided on the Site.
                </p>
                <h5>Third Party Sites and Operators</h5>

                <p>
                  We do not include third party products or services on our
                  websites.{" "}
                </p>

                <p>
                  Our Site uses a third party payment processing service to
                  process all payments made through the Site. We are not
                  responsible for storing or collection of the payment
                  information provided during the payment process. Additionally,
                  you may be subject to the Square Terms and Conditions and
                  Account Agreement by utilizing the payment feature on our
                  Sites. Any professionals or other service providers that a
                  user may connect with on the Site have not been vetted or
                  otherwise screened by the Site, and users are encouraged to
                  proceed with interactions with such professionals or service
                  providers at their own risk.{" "}
                </p>

                <h5>Fees</h5>
                <p>
                  You must pay all subscription fees when they become due.
                  Homematchx transaction fees as well as processing fees will be
                  deducted automatically from the proceeds of any transactions
                  you make on your profile.
                </p>
                <p className="pl-5">
                  • Subscription fees — If you’ve chosen a paid plan, you must
                  pay your subscription fees when they fall due. Unless
                  otherwise stated, fees are in USD and are inclusive of
                  transactional taxes (e.g. GST/VAT).
                </p>
                <p>
                  All subscription fees will be billed in advance on a recurring
                  basis, based on your chosen billing cycle (either monthly or
                  annual). Your paid plan will automatically renew under these
                  Terms (as updated at the time of the renewal) at the end of
                  your billing cycle unless you’ve cancelled your plan.
                </p>
                <p>
                  You might be asked to provide a valid payment method (i.e.
                  credit card) and accurate billing information when you join.
                  In doing so, you authorise us to charge all fees to such
                  payment method. If automatic billing fails, we’ll issue you an
                  online invoice, which you must pay within the time indicated.
                </p>
                <p className="pl-5">
                  • Homematchx transaction fees — We may charge a percentage of
                  all transactions made on your profile (e.g. through our “Thank
                  Me” features).{" "}
                </p>
                <p>
                  You agree that if we charge transaction fees, they will be
                  deducted from the proceeds of your transaction prior to
                  receipt. You’re responsible for paying any external fees and
                  taxes (including withholding taxes) if they apply, and income
                  taxes, associated with payments you receive through the
                  Platform.
                </p>
                <p className="pl-5">
                  • Processing fees — We use third party payment processing
                  services (including Stripe) to power your transactions on
                  Homematchx. These services are subject to a processing fee.
                  Each payment processor charges its own processing fees in
                  accordance with its terms and conditions, which you are
                  responsible for reviewing, accepting and complying with.
                </p>
                <p>
                  You agree that processing fees will be deducted from the
                  proceeds of your transaction prior to receipt and that
                  Homematchx is not responsible for these fees.
                </p>
                <p>
                  We may change our subscription or transaction fees from time
                  to time, but we’ll always give you prior notice (and we will
                  aim to do so at least 1 month in advance). If you don’t agree
                  to the revised fees, you can cancel your plan before your next
                  billing cycle starts.
                </p>
                <h5>Listing and Bidding Process</h5>

                <p>
                  The Homematchx platform allows for the listing agent to
                  provide a summary of the offers made provided that the listing
                  broker has received written permission from the owner to have
                  the bids disclosed after the CLOSE. It is the responsibility
                  of the listing agent to obtain the approval of the owner in
                  writing.
                </p>
                <h5>International Use </h5>

                <p>
                  This site is intended for use in the United States, and United
                  States territories.{" "}
                </p>
                <h4>Privacy Policy</h4>
                <h5>Information Collection and Use</h5>

                <p>
                  While using our Site, we may ask you to provide us with
                  certain personally identifiable information that can be used
                  to contact or identify you. Personally identifiable
                  information may include, but is not limited to, your name,
                  email address, home address, mortgage and property
                  information, broker information, pictures, and phone number
                  (“Personal Information”). This information will be used only
                  to improve user experience on the Site, send out a periodic
                  newsletter, or to respond to any communications we receive
                  from you via the Site. We partner with third parties to
                  provide services on the Site, specifically shipping and
                  payment services. When the User engages with these services,
                  the information necessary to provide those services will be
                  given to the third party. These parties are not allowed to use
                  the information except for the purpose of providing the
                  engaged service. Additionally, where permitted or required by
                  law, we will provide personally identifying information to
                  third parties, without your consent, to comply with court
                  orders, subpoenas, or other legal or regulatory requirements.
                  This information will not be sold to a third party. In the
                  event this policy changes you will be notified via updates
                  posted on the Terms and Conditions/ Privacy Policy section of
                  the website.
                </p>

                <h5>Log Data</h5>

                <p>
                  Like many site operators, we collect information that your
                  browser sends whenever you visit our Site (”Log Data”). This
                  Log Data may include information such as your computer’s
                  Internet Protocol (“IP”) address, browser type, browser
                  version, the pages of our Site that you visit, the time and
                  date of your visit, the time spent on those pages, and other
                  similar statistics.
                </p>

                <p>
                  In addition, we may use third party services such as Jetpack
                  or Google Analytics that collect, monitor, and analyze the Log
                  Data.{" "}
                </p>

                <h5>Cookies</h5>

                <p>
                  Cookies are files with small amount of data, which may include
                  an anonymous unique identifier. Cookies are sent to your
                  browser from a web site and stored on your computer’s hard
                  drive.{" "}
                </p>

                <p>
                  Like many sites, we use “cookies” to collect information. You
                  can instruct your browser to refuse all cookies or to indicate
                  when a cookie is being sent. However, if you do not accept
                  cookies, you may not be able to use some portions of our Site.
                </p>

                <h5>Do Not Track</h5>

                <p>
                  Our Site does not track its customers over time and across
                  third party websites to provide targeted advertising and
                  therefore does not respond to Do Not Track (“DNT”) signals.
                  However, some third party sites do keep track of your browsing
                  activities when they serve you content, which enables them to
                  tailor what they present to you. If you are visiting such
                  sites, Safari allows you to set the DNT signal on your browser
                  so that third parties (particularly advertisers) know you do
                  not want to be tracked.{" "}
                </p>

                <h5>Security</h5>

                <p>
                  The security of your Personal Information is important to us,
                  but remember that no method of transmission over the Internet,
                  or method of electronic storage, is 100% secure. While we
                  strive to use commercially acceptable means to protect your
                  Personal Information, we cannot guarantee its absolute
                  security against viruses, hackers, etc. Additionally, all
                  payment information is currently processed through a third
                  party payment service. We do not maintain any financial or
                  payment information on our servers and are not responsible for
                  the use, disclosure, or misuse of any such information.
                  Additionally, we are not responsible for transmission of any
                  Personal Information you choose to make public on the Site.
                </p>

                <h5>Opt Out</h5>

                <p>
                  If you wish for your information to be removed from our
                  records, you may opt out by contacting us at
                  info@homematchx.com Please note that removal may take time to
                  process, and will be reflected in our records as soon as
                  possible. You may also request to review or make changes to
                  the personally identifiable information as collected directly
                  from our Site, please contact us as info@homematchx.com to do
                  so.
                </p>

                <h5>Changes to this Privacy Policy</h5>

                <p>
                  This Privacy Policy is effective as of April 30th, 2019 and
                  will remain in effect except with respect to any changes in
                  its provisions in the future, which will be in effect
                  immediately after being posted on this page.
                </p>

                <p>
                  We reserve the right to update or change our Privacy Policy at
                  any time and you should check this Privacy Policy
                  periodically. Your continued use of the Site after we post any
                  modifications to the Privacy Policy on this page will
                  constitute your acknowledgment of the modifications and your
                  consent to abide and be bound by the modified Privacy Policy.
                </p>

                <h5>Contact Us</h5>

                <p>
                  If you have any questions regarding our Privacy Policy, please
                  contact us at info@homematchx.com.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TermsOfUse;
