import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import queryString from "query-string";
import OfferListItem from "components/Offers/OfferListItem";
import _ from "underscore";
import { TabContent, TabPane, Nav, Modal, ModalHeader, ModalBody, Container, Row, Col, Label, Input, FormGroup } from 'reactstrap';
import XLSX from 'xlsx';
// import Graph from '../assets/icons/graph.svg';
import Export from '../assets/icons/excel.svg';
import SMS from '../assets/icons/sms.svg';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import ArrowDown from '../assets/icons/list-arrow-down.svg';
import { getOffers, getUsersSellers, bestMatchOffers } from './../Services/SellerService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k + offset}`,
    content: `item ${k + offset}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: '8px 16px',
  margin: `0 0 ${grid}px 0`,
  background: isDragging ? '#ffa5b8' : 'white',
  ...draggableStyle
});

const getList1Style = () => ({
  background: '#EBECF0',
  // background: isDraggingOver ? '#EBECF0' : '#ffeaee',
  padding: grid * 2,
  width: '50%',
});

const getList2Style = isDraggingOver => ({
  background: isDraggingOver ? '#ffeaee' : '#EBECF0',
  padding: grid * 2,
  width: '50%',
  border: isDraggingOver ? '1px dashed #ff214f' : 0,
});

class Offers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      smsBlast: false,
      sellers: [],
      smsBlastListings: [],
      error: "",
      isBuyer: false,
      receivedOffers: [],
      sendOffers: [],
      loading: true,
      smsTxt: "You made an offer on one or more of %name% with Ink Realty Group listings. They have a new listing you may be interested in at %sellerAddress%: https://homematchx.com/sellers/%listingKey%",
      activeTab: '1',
      offerStatusFilter: '',
      offerTypeFilter: '',
      offerBuyerFilter: '',
      offerAddressFilter: '',
      items: getItems(10),
      selected: getItems(5, 10),
      spreadsheetSelected: [],
      brokerageNames: [],
      shareCSVModal: false,
      spreadsheetOptions: [
        {
          key: 'address',
          name: 'Address'
        },
        {
          key: 'agentName',
          name: 'Agent Name'
        },
        {
          key: 'bathrooms',
          name: 'Bathrooms'
        },
        {
          key: 'bedrooms',
          name: 'Bedrooms'
        },
        {
          key: 'brokerageName',
          name: 'Brokerage Name'
        },
        {
          key: 'buyerEmail',
          name: 'Buyer Email'
        },
        {
          key: 'buyerName',
          name: 'Buyer Name'
        },
        {
          key: 'closingDate',
          name: 'Closing Date'
        },
        {
          key: 'comments',
          name: 'Comments'
        },
        {
          key: 'createdAt',
          name: 'Created At'
        },
        {
          key: 'earnestMoney',
          name: 'Earnest Money'
        },
        {
          key: 'email',
          name: 'Email'
        },
        {
          key: 'featured_image',
          name: 'Featured Image'
        },
        {
          key: 'financeAmount',
          name: 'Finance Amount'
        },
        {
          key: 'financingType',
          name: 'Financing Type'
        },
        {
          key: 'leasebackDays',
          name: 'Leaseback Number of Days'
        },
        {
          key: 'licenseNo',
          name: 'License No'
        },
        {
          key: 'nonRealtyItem',
          name: 'Non Realty Item'
        },
        {
          key: 'purchasePrice',
          name: 'Purchase Price'
        },
        {
          key: 'phoneNumber',
          name: 'Phone Number'
        },
        {
          key: 'possession',
          name: 'Possession'
        },
        {
          key: 'actual_price',
          name: 'Listing Price'
        },
        {
          key: 'saleOfOtherProperty',
          name: 'Sale Of Other Property'
        },
        {
          key: 'search',
          name: 'Search'
        },
        {
          key: 'sellerEmail',
          name: 'Seller Email'
        },
        {
          key: 'seller_id',
          name: 'Seller Id'
        },
        {
          key: 'sqft',
          name: 'Sqft'
        },
        {
          key: 'status',
          name: 'Status'
        },
        {
          key: 'statusChangedAt',
          name: 'Status Changed At'
        },
        {
          key: 'titlePolicyExpense',
          name: 'Title Policy Expense'
        },
        {
          key: 'updateHistory',
          name: 'Update History'
        },
        {
          key: 'viewed',
          name: 'Viewed'
        },
        {
          key: 'viewedAt',
          name: 'Viewed At'
        },
        {
          key: 'viewedBy',
          name: 'Viewed By'
        },
        {
          key: 'optionFee',
          name: 'Option Fee'
        },
        {
          key: 'optionDays',
          name: 'Option Days'
        },
        {
          key: 'rightToTerminate',
          name: 'Right to Terminate'
        },
        {
          key: 'residentialServiceContract',
          name: 'Residential Service Contract'
        },
        {
          key: 'downPayment',
          name: 'Down Payment'
        }
      ],
      statusFilterOptions: [
        'In Review',
        'Accepted',
        'Not Accepted',
        'Withdraw',
        'Terminated',
        'Executed',
      ],
      offerFinanceTypeFilter: [
        'Select type',
        'Cash',
        'Lease',
        'Conventional',
        'FHA',
        'VA',
        'Seller Finance'
      ],
      offerTypeFilterOptions: [
        'Select type',
        'Sent',
        'Received',
      ],
      filterBrokerageName: null,
      buyerFilterOptions: [],
      addressFilterOptions: [],
      chartModal: false,
      spreadSheetModal: false,
      selectedFinanceType: null,
      options: {
        chart: {
          type: 'line',
          renderTo: 'container',
        },
        xAxis: {
          title: {
            text: 'Offer'
          },
          type: 'datetime',
          min: 0,
          max: 15,
          categories: [],
          labels: {
            overflow: 'justify',
            step: 1,
            rotation: -90
          },
        },
        yAxis: {
          min: 0,
          max: 0,
          title: {
            text: 'Cost ($)'
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>${point.y:.1f}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true,
          style: {
            pointerEvents: 'auto',
            whiteSpace: 'normal'
          },
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [{
          name: 'Purchase',
          data: [],
          color: '#90A69B'
        }, {
          name: 'Down payment',
          data: [],
          color: '#E5B536'
        }, {
          name: 'Earnest Money',
          data: [],
          color: '#3745C8'
        }, {
          name: 'Option Fee',
          data: [],
          color: '#038A4B'
        }],
        scrollbar: {
          enabled: true,
          barBackgroundColor: 'gray',
          barBorderRadius: 7,
          barBorderWidth: 0,
          buttonBackgroundColor: 'gray',
          buttonBorderWidth: 0,
          buttonArrowColor: 'yellow',
          buttonBorderRadius: 7,
          rifleColor: 'yellow',
          trackBackgroundColor: 'white',
          trackBorderWidth: 1,
          trackBorderColor: 'silver',
          trackBorderRadius: 7
        },
      }
    };
  }

  id2List = {
    droppable: 'spreadsheetOptions',
    droppable2: 'spreadsheetSelected'
  };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) return;
    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { items };

      if (source.droppableId === 'droppable2') {
        state = { spreadsheetSelected: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        spreadsheetOptions: result.droppable.filter(el => JSON.stringify(result.droppable2).indexOf(el.key) < 0),
        spreadsheetSelected: result.droppable2
      });
    }
  };

  toggle = activeTab => {
    this.setState({ activeTab });
  }

  chartToggle = () => {
    this.setState({
      chartModal: !this.state.chartModal
    })
  };

  spreadSheetToggle = () => {
    this.setState({
      spreadSheetModal: !this.state.spreadSheetModal
    })
  }

  async componentDidMount() {
    const { user, location } = this.props;
    const { uid, email } = user;
    const { options } = this.state
    const db = firebase.firestore();
    let query = db.collection("offers");

    const params = queryString.parse(window.location?.search);
    this.setState({
      ...params
    }, () => this.filterData());

    this.getUserSpreadSheetAttributes();
    if (location.search.indexOf('status') > 0)
      query = db.collection("offers").where("status", "==", location.search.split('=')[1]);
    const isBuyer = user.uid === this.props.location.state?.offer?.sender
    this.setState({ isBuyer });
    const promises = [
      query.where("search", "array-contains", email).orderBy('createdAt', 'desc').get(),
      query.where("search", "array-contains", uid).orderBy('createdAt', 'desc').get(),
      query.where("sender", "==", uid).orderBy('createdAt', 'desc').get()
    ];

    try {
      let sellers = await getUsersSellers(user);
      sellers = sellers.map(seller => {
        return {
          id: seller?.id,
          ...seller.data(),
        }
      });

      const snaps = await Promise.all(promises);
      const docs = {};
      [snaps[0], snaps[1]].forEach(s => {
        s.docs.forEach(d => {
          docs[d?.id] = {
            id: d?.id,
            ...d.data()
          };
        });
      });

      const snap = snaps[2];
      const sentOffers = snap.docs.map(d => ({ id: d?.id, ...d.data() }));
      const buyerFilterOptions = [...new Set(Object.values(docs).map(m => m.agentName))];
      let brokerageNames = [...new Set(Object.values(docs).map(m => m.brokerageName))];
      let addressFilterOptions = [...new Set(Object.values(docs).map(m => m.address))];
      brokerageNames = _.sortBy(brokerageNames);

      this.setState({
        sellers,
        buyerFilterOptions,
        addressFilterOptions,
        sendOffers: sentOffers,
        loading: false,
        receivedOffers: docs,
        brokerageNames,
        ...params
      });

      const receivedOffers = Object.keys(docs).map((key) => docs[key])
      const offers = [...new Set([...sentOffers, ...receivedOffers])]
      let graphSeries = options.series
      this.setState({
        options: {
          ...options,
          series: options.series.map(s => {
            s.data = []
            return s
          })
        }
      })

      const addresses = []
      for (const i in offers) {
        let o = offers[i]
        graphSeries = options.series.map(s => {
          if (i === 0) s.data = []

          addresses.push(o.address)
          if (s.name === 'Purchase') s.data.push({
            y: parseInt(o.purchasePrice),
            link: o?.id
          })
          if (s.name === 'Down payment') s.data.push({
            y: parseInt(o.downPayment),
            link: o?.id
          })
          if (s.name === 'Earnest Money') s.data.push({
            y: parseInt(o.earnestMoney),
            link: o?.id
          })
          if (s.name === 'Option Fee') s.data.push({
            y: parseInt(o.optionFee),
            link: o?.id
          })

          if (options.yAxis.max < parseInt(o.purchasePrice))
            options.yAxis.max = parseInt(o.purchasePrice) + 1000
          return s
        })
      }
      this.setState({
        options: {
          ...options,
          series: graphSeries,
          xAxis: {
            ...options.xAxis,
            categories: addresses,
          }
        }
      })
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message || 'Something went wrong, please try again!',
      });
    }
  }

  getUserSpreadSheetAttributes = async () => {
    const db = firebase.firestore();
    db.collection("users").doc(this.props.user?.uid).get()
      .then(snapshot => {
        const selectedOptions = snapshot.data()?.spreadsheet_attributes ? snapshot.data()?.spreadsheet_attributes : []

        this.setState({
          spreadsheetSelected: selectedOptions,
          spreadsheetOptions: this.state.spreadsheetOptions.filter(el => JSON.stringify(selectedOptions).indexOf(el.key) < 0)
        })
      });
  }

  renderOffers = (key) => {
    const { sellers, loading, error } = this.state;
    const offers = this.state[key];
    const { user } = this.props;
    let errorCase = "Loading...";
    errorCase = error || errorCase;


    if ((loading || error) && errorCase) {
      return (
        <table className="manage-table">
          <tbody>
            <tr className="responsive-table">
              <td>
                <p>{errorCase}</p>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }

    const bestMatches = bestMatchOffers(offers, sellers);
    if (offers) {
      return Object.keys(offers).filter(key => (offers[key].status !== 'Deleted')).map((k, index) => <OfferListItem
        sellers={sellers}
        bestMatch={bestMatches}
        key={index}
        user={user}
        curItem={index + 1}
        totalItems={Object.keys(offers).length || 0}
        offer={offers[k]}
      />);
    }
  };

  updateGraph = (type) => {
    getOffers(this.props.user, null, {
      financeType: type
    }).then(offers => {
      let graphSeries = this.state.options.series
      this.setState({
        ...this.state.options,
        series: this.state.options.series.map(s => {
          s.data = []
          return s
        })
      })

      const addresses = [];
      for (const i in offers) {
        let o = offers[i];
        graphSeries = this.state.options.series.map(s => {
          if (i === 0) s.data = [];
          addresses.push(o.address)

          if (s.name === 'Purchase') s.data.push(parseInt(o.purchasePrice));
          if (s.name === 'Down payment') s.data.push(parseInt(o.downPayment));
          if (s.name === 'Earnest Money') s.data.push(parseInt(o.earnestMoney));
          if (s.name === 'Option Fee') s.data.push(parseInt(o.optionFee));

          if (this.state.options.yAxis.max < parseInt(o.purchasePrice));
          this.state.options.yAxis.max = parseInt(o.purchasePrice) + 1000;
          return s;
        })
      }

      this.setState({
        options: {
          ...this.state.options,
          series: graphSeries,
          xAxis: {
            ...this.state.options.xAxis,
            categories: addresses,
          }
        }
      })
    }).catch(err => {
      console.log('Get All Offers Error: ', err)
    })
  }

  exportDataToXLSX = async () => {
    const { spreadsheetSelected } = this.state;
    const filteredXlsxAttributes = spreadsheetSelected.map(e => e);
    const data = Object.keys(this.state.receivedOffers).map((key) => {
      const offer = this.state.receivedOffers[key]

      delete offer.seller
      delete offer.sender
      delete offer?.id
      delete offer.buyer
      delete offer.documents
      delete offer.notifiedBy
      delete offer.notifiedBy

      const updatedOffer = {};
      filteredXlsxAttributes.forEach(e => {
        updatedOffer[e.name] = offer[e.key]
      });

      return updatedOffer;
    });
    const db = firebase.firestore();
    await db.collection('users').doc(this.props.user.uid).update({
      spreadsheet_attributes: spreadsheetSelected
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'users_sheet');

    const filename = "offers.xlsx";
    const wb_opts = { bookType: 'xlsx', type: 'binary' };
    XLSX.writeFile(wb, filename, wb_opts);
    this.spreadSheetToggle();
  }

  shareXLSXViaEMail = async () => {

    if (!this.state.emailToShare) return alert('Please enter correct email address.');

    this.setState({
      sharingCSV: true
    });

    const { spreadsheetSelected } = this.state;
    const filteredXlsxAttributes = spreadsheetSelected.map(e => e);
    const data = Object.keys(this.state.receivedOffers).map((key) => {
      const offer = this.state.receivedOffers[key]

      delete offer.seller
      delete offer.sender
      delete offer?.id
      delete offer.buyer
      delete offer.documents
      delete offer.notifiedBy
      delete offer.notifiedBy

      const updatedOffer = {};
      filteredXlsxAttributes.forEach(e => {
        updatedOffer[e.name] = offer[e.key]
      });

      return updatedOffer;
    });
    const db = firebase.firestore();
    await db.collection('users').doc(this.props.user.uid).update({
      spreadsheet_attributes: spreadsheetSelected
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'users_sheet');
    const csvData = XLSX.utils.sheet_to_csv(ws);

    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");

    try {
      await sendEmail({
        subject: 'Offers CSV shared - Homematchx',
        email: this.state.emailToShare,
        body: `Hello Dear,
        <br /><br />
        Please download attached CSV.<br /><br /><br />
        Regards,<br />Homematchx Team`,
        attachments: [{
          filename: 'offers.csv',
          content: csvData
        }]
      });
    } catch (err) {
      console.log(err)
    }

    this.setState({
      shareCSVModal: false,
      sharingCSV: false
    });
  }

  sendSMSToAll = async () => {
    const { smsBlastListings, receivedOffers: offers, sellers, smsTxt } = this.state;
    const { user } = this.props;

    if (!smsBlastListings.length) return alert('Please select atleast 1 listing to send sms blast.')


    smsBlastListings.forEach(listing => {
      const seller = sellers.find(seller => seller.id === listing);

      let numbers = Object.keys(offers).map(key => offers[key].phoneNumber.match(/\d/g)?.join(''));

      numbers = [...new Set(numbers)];
      const sendSMS = firebase.functions().httpsCallable("sendSMS");

      numbers.forEach(async num => {
        try {
          await sendSMS({
            to: `+1${num.replace('+1', '')}`,
            body: smsTxt.replace('%name', user.realtor_name).replace('%sellerAddress', seller.UnparsedAddress).replace('%listingKey', seller.ListingKey)
          })
        } catch (err) {
          console.log(err)
        }
      })
    });



    this.setState({
      smsBlast: false
    });
  }

  filterData = async () => {
    const { offerStatusFilter, offerBuyerFilter, offerAddressFilter, offerTypeFilter, offerBrokerageNamesFilter } = this.state;
    const { user } = this.props;
    const { uid, email } = user;
    const db = firebase.firestore();
    let query = db.collection("offers");

    if (!!offerStatusFilter) query = query.where("status", "==", offerStatusFilter);
    if (!!offerBuyerFilter) query = query.where("agentName", "==", offerBuyerFilter);
    if (!!offerAddressFilter) query = query.where("address", "==", offerAddressFilter);
    if (!!offerBrokerageNamesFilter) query = query.where("brokerageName", "==", offerBrokerageNamesFilter);

    const promises = [
      query.where("search", "array-contains", email).orderBy('createdAt', 'desc').get(),
      query.where("search", "array-contains", uid).orderBy('createdAt', 'desc').get(),
      query.where("sender", "==", uid).orderBy('createdAt', 'desc').get()
    ];

    try {
      const snaps = await Promise.all(promises);
      const docs = {};
      const agentNames = [];
      [snaps[0], snaps[1]].forEach(s => {
        s.docs.forEach(d => {
          const id = d?.id;
          const offerData = d.data();
          agentNames.push(offerData.agentName);
          if (offerTypeFilter === 'Received' && offerData.sellerEmail === user.email) {
            docs[d?.id] = {
              id,
              ...offerData
            };
          } else if (offerTypeFilter === 'Sent' && offerData.sellerEmail !== user.email) {
            docs[d?.id] = {
              id,
              ...offerData
            };
          } else if (offerTypeFilter !== 'Received' && offerTypeFilter !== 'Sent') {
            docs[d?.id] = {
              id,
              ...offerData
            };
          }
        });
      });

      const snap = snaps[2];
      const sendOffers = snap.docs.map(d => ({ id: d?.id, ...d.data() }));
      this.setState({
        sendOffers,
        loading: false,
        receivedOffers: docs,
        buyerFilterOptions: agentNames
      });
    } catch (error) {
      this.setState({
        loading: false,
        error: error.message || 'Something went wrong, please try again !',
      });
    }
  }

  resetFilters = () => {
    this.setState({
      offerStatusFilter: '',
      offerBuyerFilter: '',
      offerAddressFilter: '',
      offerBrokerageNamesFilter: '',
    }, () => this.filterData())
  }

  onChange = (e) => {
    const { search } = window.location;
    const params = queryString.parse(search);

    params[e.target.name] = e.target.value;
    window.history.pushState(null, '', window.location.pathname + '?' + queryString.stringify(params));
    this.setState({ [e.target.name]: e.target.value }, () => this.filterData());
  }

  clearFilter() {
    window.history.pushState(null, '', window.location.pathname + '?' + queryString.stringify(""));
    this.resetFilters()
  }

  render() {
    const {
      offerStatusFilter,
      offerTypeFilter,
      offerBuyerFilter,
      offerAddressFilter,
      statusFilterOptions,
      offerTypeFilterOptions,
      buyerFilterOptions,
      addressFilterOptions,
      activeTab,
      options,
      sellers,
      smsBlastListings,
      offerFinanceTypeFilter,
      selectedFinanceType,
      offerBrokerageNamesFilter,
      brokerageNames,
      receivedOffers
    } = this.state;
    const { user } = this.props;


    let numbers = Object.keys(receivedOffers) && Object.keys(receivedOffers).map(key => receivedOffers[key]?.phoneNumber?.match(/\d/g)?.join(''));
    const smsBlastAudience = [...new Set(numbers)].length;

    return (
      <div className="content-area5">
        <div className="dashboard-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-12 centerly">

                <div className="d-inline-block mt-2">
                  <span className="period-filter-label">Status:</span>
                  <div className="d-inline-block">
                    <select
                      name="offerStatusFilter"
                      value={offerStatusFilter}
                      onChange={this.onChange}
                      className="search-fields offers-search-fields mr-0 mr-md-4 ">
                      <option value="">Select status</option>
                      {
                        !!statusFilterOptions.length && statusFilterOptions.map((m, i) => (
                          <option key={i} value={m}>{m}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <Modal isOpen={this.state.chartModal} size="lg" fade={false} toggle={this.chartToggle} className="offers-modal">
                  <ModalHeader className="modal-header" toggle={this.chartToggle}></ModalHeader>
                  <ModalBody className="modal-body">
                    <Container>
                      <Row>
                        <Col>
                          <div className="row-space-between">
                            {/* <h5 className="modal-title">Chart Filters</h5> */}
                            {/* <button className="modal-chart-btn">
                              <img src={Bars} className="pr-10" />List view
                            </button> */}
                          </div>
                        </Col>
                      </Row>
                      <Row className="mb-30">
                        <Col>
                          <br />
                          <div className="d-inline-block mb-2">
                            <span className="period-filter-label">Finance Type:</span>
                            <div className="d-inline-block">
                              <select
                                name="offerStatusFilter"
                                value={selectedFinanceType}
                                onChange={e => {
                                  this.updateGraph(e.target.value)
                                  this.setState({
                                    selectedFinanceType: e.target.value
                                  })
                                }}
                                className="search-fields offers-search-fields mr-0 mr-md-4">
                                {
                                  offerFinanceTypeFilter.map((m, i) => (
                                    <option key={i} value={m}>{m}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="highchart-container">
                            <HighchartsReact
                              highcharts={Highcharts}
                              options={options}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ModalBody>
                </Modal>

                <Modal isOpen={this.state.spreadSheetModal} size="lg" fade={false} toggle={this.spreadSheetToggle} className="offers-modal">
                  <ModalHeader className="modal-header" toggle={this.spreadSheetToggle}></ModalHeader>
                  <ModalBody className="modal-body">
                    <Container className="mb-40">
                      <Row>
                        <Col>
                          <h5 className="modal-title text-center">Drag Spreadsheet Attributes To Right</h5>
                        </Col>
                      </Row>
                      <Row className="mt-20">
                        <Col>
                          <h6 className="text-center">Fields</h6>
                        </Col>
                        <Col>
                          <h6 className="text-center">Export</h6>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="drag-and-drop-container">
                            <DragDropContext onDragEnd={this.onDragEnd}>
                              <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    style={getList1Style(snapshot.isDraggingOver)}>
                                    {this.state.spreadsheetOptions.map((item, index) => (
                                      <Draggable
                                        key={item.key}
                                        draggableId={item.key}
                                        index={index}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}>
                                            {item.name}
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                              <Droppable droppableId="droppable2">
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    style={getList2Style(snapshot.isDraggingOver)}>
                                    {this.state.spreadsheetSelected.map((item, index) => (
                                      <Draggable
                                        key={item.key}
                                        draggableId={item.key}
                                        index={index}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}>
                                            {item.name}
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </DragDropContext>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="flex-center">
                            <button className="cancel-btn" onClick={this.spreadSheetToggle}>Cancel</button>
                            <button className="gnerate-file" onClick={this.exportDataToXLSX}>Generate XLSX</button>
                            <button className="gnerate-file" onClick={() => this.setState({
                              shareCSVModal: true
                            })}>Share XLSX</button>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ModalBody>
                </Modal>
                <div className="d-inline-block mt-2">
                  <span className="period-filter-label">Buyer’s Agent:</span>
                  <div className="d-inline-block">
                    <select
                      name="offerBuyerFilter"
                      value={offerBuyerFilter}
                      onChange={this.onChange}
                      className="search-fields offers-search-fields mr-0 mr-md-4 ">
                      <option value="">Select buyer's agent</option>
                      {
                        !!buyerFilterOptions.length && buyerFilterOptions.sort().map((m, i) => (
                          <option key={i} value={m}>{m}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>

                <div className="d-inline-block mt-2 mb-2">
                  <span className="period-filter-label">Property Address:</span>
                  <div className="d-inline-block">
                    <select
                      name="offerAddressFilter"
                      value={offerAddressFilter}
                      onChange={this.onChange}
                      className="search-fields offers-search-fields mr-0 mr-md-4"
                      style={{
                        maxWidth: 200
                      }}>
                      <option value="">Select address</option>
                      {
                        !!addressFilterOptions.length && addressFilterOptions.map((m, i) => (
                          <option key={i} value={m}>{m}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                {/* <div className="d-inline-block mt-2 mb-2">
                  <span className="period-filter-label">Brokerage Name:</span>
                  <div className="d-inline-block">
                    <select
                      name="offerBrokerageNamesFilter"
                      value={offerBrokerageNamesFilter}
                      onChange={this.onChange}
                      className="search-fields offers-search-fields mr-0 mr-md-4"
                      style={{
                        maxWidth: 200
                      }}>
                      <option value="">Select Brokerage Name</option>
                      {
                        !!brokerageNames.length && brokerageNames.map((m, i) => (
                          <option key={i} value={m}>{m}</option>
                        ))
                      }
                    </select>
                  </div>
                </div> */}
                <div className="d-inline-block">
                  <div>
                    <button className="analytics-price-dropdown-btn" type="button" id="offerStatusFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div className="analytics-row center-me dropdown-main-view">
                        <p className="analytics-input-label font-15">Select Brokerage Name</p>
                        <img alt="ArrowDown" src={ArrowDown} className="down-arrow" />
                      </div>
                    </button>
                    <div className="custom-users-filter-list dropdown-menu" aria-labelledby="listingPriceFilter">
                      <div className="analtics-space-between padd-6">
                        <input type="text" className="analytics-search-input" onChange={(e) => this.setState({ filterBrokerageName: e.target.value })} placeholder="Search user" />
                      </div>
                      {!!brokerageNames.length && brokerageNames.filter(m => {
                        if (this.state.filterBrokerageName)
                          return m.toLowerCase().indexOf(this.state.filterBrokerageName.toLowerCase()) < 0 ? false : true
                        return true
                      }).map(m => {
                        return <a className="analytics-price-dropdown-inner-btn">
                          <p onClick={() => {
                            const { search } = window.location;
                            const params = queryString.parse(search);

                            params['filterBrokerageName'] = m;
                            window.history.pushState(null, '', window.location.pathname + '?' + queryString.stringify(params));
                            this.setState({ offerBrokerageNamesFilter: m }, () => this.filterData());
                          }}>{m}</p>
                        </a>
                      })}
                    </div>
                  </div>
                </div>
                <div className="d-inline-block mt-2">
                  <span className="period-filter-label">Offer Type:</span>
                  <div className="d-inline-block">
                    <select
                      name="offerTypeFilter"
                      value={offerTypeFilter}
                      onChange={this.onChange}
                      className="search-fields offers-search-fields mr-0 mr-md-4 ">
                      {/* <option value="">Select status</option> */}
                      {
                        !!offerTypeFilterOptions.length && offerTypeFilterOptions.map((m, i) => (
                          <option key={i} value={m}>{m}</option>
                        ))
                      }
                    </select>
                  </div>
                </div>
                <br />
                <div className="d-inline-block mt-2">
                  <button className="btn" onClick={() => this.clearFilter()}>Clear Filters</button>
                </div>
              </div>
              <div className="col-lg-4 text-right col-12 centerly">
                <button className="borderless-btn" onClick={this.spreadSheetToggle}>
                  <img src={Export} className="pr-10" /> Create spreadsheet
                </button>
                <button className="borderless-btn" onClick={() => this.setState({
                  smsBlast: true
                })}>
                  <img src={SMS} className="pr-10 follow-my-listing-icon" /> Follow My Listing
                </button>
              </div>
            </div>
          </div>

          <div className="offers-list">
            {/* <h3>Your</h3> */}
            <div>
              <Nav tabs>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => { this.toggle('1'); }}
                  >Received Offers</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => { this.toggle('2'); }}
                  >Send Offers</NavLink>
                </NavItem> */}
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  {this.renderOffers('receivedOffers')}
                </TabPane>
                {/* <TabPane tabId="2">
                  {this.renderOffers('sendOffers')}
                </TabPane> */}
              </TabContent>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.shareCSVModal} size="md" fade={false} toggle={() => this.setState({
          shareCSVModal: !this.state.shareCSVModal
        })}>
          <ModalHeader className="modal-header" toggle={() => this.setState({
            shareCSVModal: !this.state.shareCSVModal
          })}></ModalHeader>
          <ModalBody className="modal-body">
            <Container>
              <Row>
                <Col>
                  <div className="form-content-box content-box-signupCheck">
                    <div className="details">
                      <form>
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="input-text"
                            onChange={e => this.setState({
                              emailToShare: e.target.value
                            })}
                            value={this.state.emailToShare}
                          />
                        </div>
                        <p className="text-danger">{this.state.registerError}</p>
                        <div className="form-group mb-0">
                          <button
                            type="button"
                            className="btn-md button-theme btn-block mb-20"
                            onClick={this.shareXLSXViaEMail}
                          >
                            {this.state.sharingCSV ? 'Sending...' : 'Send'}
                          </button>
                          <a onClick={() => this.setState({
                            shareCSVModal: false
                          })}>No</a>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.smsBlast} size="md" fade={false} toggle={() => this.setState({
          smsBlast: !this.state.smsBlast
        })}>
          <ModalHeader className="modal-header" toggle={() => this.setState({
            smsBlast: !this.state.smsBlast
          })}></ModalHeader>
          <ModalBody className="modal-body">
            <Container>
              <Row>
                <Col>
                  <div className="form-content-box content-box-signupCheck">
                    <div className="details">
                      <h3>Select listings to send</h3>
                      <p>Audience: <b>{smsBlastAudience}</b></p>
                      {smsBlastListings.map(listing => {
                        const seller = sellers.find(seller => seller.id === listing);
                        let numbers = Object.keys(receivedOffers)?.map(key => receivedOffers[key]?.phoneNumber?.match(/\d/g)?.join(''));
                        numbers = [...new Set(numbers)];

                        return
                        {/* <p className="text-left"><b>SMS Text: </b>{`You made an offer on one or more of ${user.realtor_name} with Ink Realty Group listings. They have a new listing you may be interested in at ${seller.UnparsedAddress}.`}<br />{`Details: `}<a href={`https://homematchx.com/sellers/${seller.ListingKey}`}>https://homematchx.com/sellers/{seller.ListingKey}</a></p> */ }
                      })}

                      <form>

                        {sellers.map((seller, i) => {

                          if (seller.MlsStatus.toLowerCase() !== 'active') return null;

                          return <div key={i} className="label-sms-blast">
                            <label>
                              <input type="checkbox" onChange={() => {
                                let updatesSellers = [];
                                if (smsBlastListings.includes(seller.id))
                                  updatesSellers = smsBlastListings.filter(id => id !== seller.id)
                                else
                                  updatesSellers = [...smsBlastListings, seller.id]

                                this.setState(({
                                  smsBlastListings: updatesSellers
                                }))

                              }} />

                              <span>{seller.UnparsedAddress || ` ${seller.street_number} ${seller.street_name},  ${seller.state}, ${seller.zip_code}`} </span>
                            </label>
                          </div>
                        })}

                        <FormGroup className="form-group pt-14">
                          <Label className="label-font">Type Mesaage</Label>
                          <Input
                            style={{ height: 120 }}
                            type="textarea"
                            name="comments"
                            value={this.state.smsTxt}
                            onChange={e => this.setState({ smsTxt: e.target.value })}
                            placeholder="Message"
                          />
                        </FormGroup>

                        <p className="text-danger">{this.state.smsBlastError}</p>
                        <div className="form-group mb-0">
                          <button
                            type="button"
                            className="btn-md button-theme btn-block mb-20"
                            onClick={this.sendSMSToAll}
                          >
                            {this.state.sendingSMS ? 'Sending...' : 'Send'}
                          </button>
                          <a onClick={() => this.setState({
                            smsBlast: false
                          })}>No</a>
                        </div>
                      </form>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default Offers;
