import React, { Component } from "react";
import { connect } from 'react-redux';

import {
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
  Form
} from 'reactstrap';

import { toast } from 'react-toastify';
import BlogPostForm from './BlogPostForm';
import {edit} from './../../Store/Actions/BlogActions';

class EditBlogPost extends Component {

  state = {
    blogPost: this.props.blogPost,
    title: null,
    body: null,
    isOpen: false,
    isProcessing: false,
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    })
  };

  onChange = (blogPost) => {
    console.log({
      blogPost
    })
    this.setState({
      title: blogPost.title,
      body: blogPost.body,
    });
  }

  onUpdate = (e) => {
    if (e) e.preventDefault();
    const { blogPost, title, body } = this.state;

    const { edit } = this.props;
    this.setState({
      isProcessing: true,
    })
    edit({
      blogPost: {
        ...blogPost,
        title: title ?? blogPost.title,
        body: body ?? blogPost.body,
        updatedOn: new Date().getTime()/1000,
      },
      onSuccess: () => {
        toast.success('Post Updated Successfully');
        this.setState({isProcessing: false,});
        this.toggle();
      },
      onError: (error) => {
        toast.error(error);
        this.setState({isProcessing: false,});
      },
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (JSON.stringify(nextProps.blogPost) !== JSON.stringify(prevState.blogPost)) {
      return { blogPost: { ...nextProps.blogPost } };
    }
    return null;
  }

  render() {
    const { isOpen, blogPost,isProcessing } = this.state;
    return (
      <React.Fragment>
        <Button color="primary" onClick={this.toggle} className="mr-1">
          <i className="fa fa-pencil" />
        </Button>
        <Modal size='xl' isOpen={isOpen} toggle={this.toggle} className="modal-primary">
          <Form onSubmit={this.onUpdate}>
            <ModalHeader toggle={this.toggle}><i className="fa fa-pencil" /> Update Post</ModalHeader>
            <ModalBody>
              <BlogPostForm {...this.props}  blogPost={blogPost} onStateChange={this.onChange} />
            </ModalBody>
            <ModalFooter>
               <Button type="subimt" color="success">{isProcessing ? 'Updating': 'Update'}</Button>{' '}
              <Button type="button" color="secondary" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}

export default connect(null, {
  edit,
})(EditBlogPost);