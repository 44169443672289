import React, { Component } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import firebase from "firebase/app"
import { getOffers, getUsersSellers } from 'Services/SellerService';
import _ from 'lodash';
import moment from 'moment';
import ArrowDown from 'assets/icons/arrowDownBlack.svg';
import {Line} from "react-chartjs-2";

const current = moment().endOf('day').unix();

const totalListingDaysFilterOptions = [
  {
    label: 'Last 7 days',
    startDate: moment().subtract(7, 'days').startOf('day').unix(),
    endDate: current,
  },
  {
    label: 'Last 30 days',
    startDate: moment().subtract(30, 'days').startOf('day').unix(),
    endDate: current,
  },
  {
    label: 'Last 60 days',
    startDate: moment().subtract(60, 'days').startOf('day').unix(),
    endDate: current,
  },
  {
    label: 'Last 90 days',
    startDate: moment().subtract(90, 'days').startOf('day').unix(),
    endDate: current,
  },
];

class PropertyListingViewsChart extends Component {

  state = {
    offerPropertyFilter: 'Select property',
    filteredSellers: [],
    views: [],
    totalListingDaysFilterOptions,
    totalListingDaysFilter: 0,
    offers: []
  }


  componentDidMount() {
    const { user, allData } = this.props;
    getOffers(user, null, { allData }).then(offers => {
      this.setState({ offers });
    });
    getUsersSellers(user, { allData }).then(localSellers => {
      this.setState({
        filteredSellers: localSellers
      })
    });
  }

  onPropertyFilterChange = async (seller_id) => {
    const db = firebase.firestore();
    const doc = await db.collection('meta').doc(seller_id).get();
    if (doc.exists) {
      const { views } = doc.data();
      return this.setState({ views });
    }

    this.setState({ views: [] });
  }

  handleAddressChangeFilter = async (e) => {
    const { sellers } = this.props;

    const filteredSellers = sellers.filter((seller, key) => {
      const address = (`${seller.data().street_number} ${seller.data().street_name}, ${seller.data().city}, ${seller.data().state}, ${seller.data().zip_code}`)
      if (address.indexOf(e.target.value) >= 0) return true
      return false
    })
    this.setState({
      filteredSellers
    })
  }

  getFilteredViews = (start, end) => {
    const { views } = this.state;
    return views.filter(view => view >= start && view <= end);
  }


  render() {
    const { offerPropertyFilter, offers, totalListingDaysFilter, totalListingDaysFilterOptions, filteredSellers } = this.state;
    const { sellers } = this.props
    Highcharts.seriesTypes.line.prototype.getPointSpline = Highcharts.seriesTypes.spline.prototype.getPointSpline;
    const currentDaysFilter = totalListingDaysFilterOptions[totalListingDaysFilter];
    const offerSellers = {};

    offers.forEach(offer => {
      offerSellers[offer.seller] = offer;
    });

    const views = this.getFilteredViews(currentDaysFilter.startDate, currentDaysFilter.endDate);
    const processedViews = {};
    views.forEach(view => {
      const date = moment.unix(view).format("D MMM");
      if (processedViews[date]) {
        processedViews[date] = processedViews[date] + 1;
      } else {
        processedViews[date] = 1;
      }
    });

    const propertyListingOptions = {
      chart: {
        type: 'spline',
        height: 305,
        marginLeft: 22,
        marginBottom: 50,
        reflow: true,
        scrollablePlotArea: {
          minWidth: 500,
          scrollPositionX: 0
        }
      },
      title: {
        text: 'Property Listing Engagement'
      },
      xAxis: {
        categories: Object.keys(processedViews),
        labels: {
          overflow: 'justify'
        }
      },
      plotOptions: {
        series: {
          marker: {
            enabled: false
          }
        }
      },
      yAxis: {
        title: {
          text: 'Views'
        }
      },
      series: [
        {
          name: 'Views',
          type: 'area',
          data: Object.values(processedViews),
          gapSize: 5,
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1
            },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
            ]
          },
          threshold: null
        }
      ]
    }

    return (
      <div className="charts-main-container ml-15 tab-responsive-mr-15">
        <div className="property-engagement-tools">
          <p className="chart-title">Property Listing Views</p>
        </div>
        <div className="property-engagement-tools">
          <div className="d-inline-block">
            <div>
              <button className="analytics-price-dropdown-btn" type="button" id="offerStatusFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <div className="analytics-row center-me">
                  <p className="analytics-property-address-label">{offerPropertyFilter}</p>
                  <img alt="ArrowDown" src={ArrowDown} className="down-arrow" />
                </div>
              </button>
              <div className="custom-address-options-list dropdown-menu" aria-labelledby="listingPriceFilter">
                <div className="analtics-space-between padd-6">
                  <input type="text" className="analytics-search-input" placeholder="Search property" onChange={(e) => this.handleAddressChangeFilter(e)} />
                </div>
                {filteredSellers.map((seller, i) => {
                  const seller_id = seller.id
                  seller = seller.data()
                  return <button className="analytics-price-dropdown-inner-btn" key={i} value={seller_id} onClick={() => {
                    this.setState({ offerPropertyFilter: seller.street_number + "," + seller.street_name + "," + seller.city + "," + seller.state + "," + seller.zip_code })
                    this.onPropertyFilterChange(seller_id)
                  }}>
                    <p>{`${seller.street_number} ${seller.street_name}, ${seller.city}, ${seller.state}, ${seller.zip_code}`}</p>
                  </button>

                })}
              </div>
            </div>

          </div>

          <select
            name="totalListingDaysFilter"
            value={totalListingDaysFilter}
            onChange={({ target: { value: totalListingDaysFilter } }) => this.setState({ totalListingDaysFilter })}
            className="analytics-offer-days"
          >
            {
              totalListingDaysFilterOptions.map((m, i) => (
                <option key={i} value={i}>{m.label}</option>
              ))
            }
          </select>

        </div>
        <p className="small-font-text">Views</p>
        <div className="property-engagement-chart scroll-chart">
          {/* <HighchartsReact
            highcharts={Highcharts}
            options={propertyListingOptions}
          /> */}
          <Line options={propertyListingOptions}></Line>
        </div>
      </div>
    )
  }
}

export default PropertyListingViewsChart;
