import React, { Component } from "react";

import {
  Label,
  Button,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";

class ConfirmationModal extends Component {
  state = {
    isOpen: false,
    declineOthers: false,
    comment: "",
    notAcceptedComment: "",
    terminationComment: "",
    withdrawnComment: ""
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      declineOthers: false,
    });
  };

  toggleCheckbox = () => {
    this.setState({ declineOthers: !this.state.declineOthers });
  };

  // confirm = () => {
  //   this.props.action(this.state.declineOthers, this.state.comment, this.state.terminationComment, this.setState.notAcceptedComment);
  //   this.toggle();
  // };

  confirm = async () => {
    const { declineOthers, notAcceptedComment, unDeclineComment, comment, terminationComment, withdrawnComment } = this.state;
    const { status } = this.props;

    const commentType = (status === "In Review" || status === "Seller Review" || status === 'Decline') ? notAcceptedComment : unDeclineComment;

    try {
      this.toggle();
      await this.props.action(declineOthers, comment, terminationComment, commentType, withdrawnComment);
      this.setState({
        comment: "",
        notAcceptedComment: "",
        unDeclineComment: "",
        withdrawnComment: "",
        terminationComment: "",
        declineOthers: false,
      });
  
    } catch (error) {
      console.error("Failed to update offer status:", error);
    }
  };
  
  renderBtnText = () => {
    const { children } = this.props;
    if (children) {
      return children;
    }
    return (
      <React.Fragment>
        <i className="fa fa-notes" />
      </React.Fragment>
    );
  };

  render() {
    let {
      title,
      message,
      modalColor,
      showCheckbox,
      checkLabel,
      showTerminationCommentBox,
      showCommentBox,
      showWithdrawCommentBox
    } = this.props;

    let { comment, terminationComment } = this.state;
    return (
      <React.Fragment>
        <span onClick={this.toggle}>{this.renderBtnText()}</span>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className={`modal-${modalColor || "primary"}`}
        >
          <ModalHeader toggle={this.toggle}> {title || ""}</ModalHeader>
          <ModalBody>
            <Label>{message || ""}</Label>
            {!!showCheckbox && (
              <Label>
                <input
                  type="checkbox"
                  name="declineOthers"
                  className="mr-2"
                  checked={this.state.declineOthers}
                  onChange={this.toggleCheckbox}
                />
                {checkLabel}
              </Label>
            )}
            {!!showTerminationCommentBox && (
              <div className="accept-comment-container">
                <Label className="mt-0">Reason for terminating the Offer</Label>
                <textarea
                  style={{padding: 8}}
                  className="accept-comment"
                  name="terminationComment"
                  rows="3"
                  placeholder="Enter comment here..."
                  value={terminationComment}
                  onChange={(e) => this.setState({ terminationComment: e.target.value })}
                />
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ terminationComment: "Financing Not Obtained" });
                  }}
                >
                  Financing Not Obtained
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ terminationComment: "Inspection Problems" });
                  }}
                >
                  Inspection Problems
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ terminationComment: "Appraisal Below Price" });
                  }}
                >
                  Appraisal Below Price
                </button>
              </div>
            )}
            {this.props.declineOffer && this.props.status === "Decline" && <>
              <Label className="mt-0">Reason for declining the offer</Label>
              <textarea
                className="accept-comment"
                name="declineComment"
                rows="3"
                placeholder="Enter comment here..."
                value={this.state.notAcceptedComment}
                onChange={(e) => this.setState({ notAcceptedComment: e.target.value })}
              />
              <button
                className="comment-suggestions-btn mr-2 mt-1"
                onClick={() => {
                  this.setState({ notAcceptedComment: "Insufficient financial proof" });
                }}
              >
                Insufficient financial proof
              </button>
              <button
                className="comment-suggestions-btn mr-2 mt-1"
                onClick={() => {
                  this.setState({ notAcceptedComment: "Low offer price" });
                }}
              >
                Low offer price
              </button>
              <button
                className="comment-suggestions-btn mr-2 mt-1"
                onClick={() => {
                  this.setState({ notAcceptedComment: "Unfavorable Terms Included" });
                }}
              >
                Unfavorable Terms Included
              </button>
            </> }

            {!!this.state.declineOthers && (
              <div className="accept-comment-container">
                {this.props.status === "In Review" || this.props.status === "Seller Review" ? (
                  <>
                    <Label className="mt-0">Reason for declining other offers</Label>
                    <textarea
                      className="accept-comment"
                      name="declineComment"
                      rows="3"
                      placeholder="Enter comment here..."
                      value={this.state.notAcceptedComment}
                      onChange={(e) => this.setState({ notAcceptedComment: e.target.value })}
                    />
                    <button
                      className="comment-suggestions-btn mr-2 mt-1"
                      onClick={() => {
                        this.setState({ notAcceptedComment: "Insufficient financial proof" });
                      }}
                    >
                      Insufficient financial proof
                    </button>
                    <button
                      className="comment-suggestions-btn mr-2 mt-1"
                      onClick={() => {
                        this.setState({ notAcceptedComment: "Low offer price" });
                      }}
                    >
                      Low offer price
                    </button>
                    <button
                      className="comment-suggestions-btn mr-2 mt-1"
                      onClick={() => {
                        this.setState({ notAcceptedComment: "Unfavorable Terms Included" });
                      }}
                    >
                      Unfavorable Terms Included
                    </button>
                  </>
                ) : (
                  <>
                    <Label className="mt-0">Reason for un-declining other offers</Label>
                    <textarea
                      className="accept-comment"
                      name="unDeclineComment"
                      rows="3"
                      placeholder="Enter comment here..."
                      value={this.state.unDeclineComment || ""}
                      onChange={(e) => this.setState({ unDeclineComment: e.target.value })}
                    />
                    <button
                      className="comment-suggestions-btn mr-2 mt-1"
                      onClick={() => {
                        this.setState({ unDeclineComment: "Offers reconsidered" });
                      }}
                    >
                      Offers reconsidered
                    </button>
                    <button
                      className="comment-suggestions-btn mr-2 mt-1"
                      onClick={() => {
                        this.setState({ unDeclineComment: "New seller terms" });
                      }}
                    >
                      New seller terms
                    </button>
                    <button
                      className="comment-suggestions-btn mr-2 mt-1"
                      onClick={() => {
                        this.setState({ unDeclineComment: "Other reasons" });
                      }}
                    >
                      Other reasons
                    </button>
                  </>
                )}
              </div>
            )}
            {!!showCommentBox && (
              <div className="accept-comment-container">
                <Label className="mt-0">Reason for accepting the Offer</Label>
                <textarea
                  className="accept-comment"
                  name="acceptComment"
                  rows="3"
                  // placeholder="Reason for accepting the Offer"
                  value={comment}
                  onChange={(e) => this.setState({ comment: e.target.value })}
                />
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ comment: "Only one offer received" });
                  }}
                >
                  Only one offer received
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ comment: "Highest and best offer" });
                  }}
                >
                  Highest and best offer
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ comment: "Better closing date" });
                  }}
                >
                  Better closing date
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ comment: "Better contract terms" });
                  }}
                >
                  Better contract terms
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ comment: "No contingencies" });
                  }}
                >
                  No contingencies
                </button>
              </div>
            )}
            {!!showWithdrawCommentBox && (
              <div className="accept-comment-container">
                <Label className="mt-0">Reason for withdrawing the Offer</Label>
                <textarea
                  className="accept-comment"
                  name="withdrawComment"
                  rows="3"
                  placeholder="Enter comment here..."
                  value={this.state.withdrawnComment}
                  onChange={(e) => this.setState({ withdrawnComment: e.target.value })}
                />
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ withdrawnComment: "Change of plans" });
                  }}
                >
                  Change of plans
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ withdrawnComment: "Financial reasons" });
                  }}
                >
                  Financial reasons
                </button>
                <button
                  className="comment-suggestions-btn mr-2 mt-1"
                  onClick={() => {
                    this.setState({ withdrawnComment: "Other reasons" });
                  }}
                >
                  Other reasons
                </button>
              </div>
            )}

          </ModalBody>
          <ModalFooter>
            <Button color={modalColor || "primary"} onClick={this.confirm}>
              Yes
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default ConfirmationModal;
