import React from "react";
import moment from "moment";
import { firebaseConfig } from "../../components/Firebase/firebase";

function shareOffers(offers, name, sellerDetails) {
  // const homePlaceholder = `${host}/images/house-placeholder.jpg`;
  // const profilePlaceholder = `${host}/images/face-placeholder.gif`;

  // const { buyer } = requestInfo;

  // const propertyImage =
  // 	sellerDetails.featured_image && sellerDetails.featured_image.url
  // 		? sellerDetails.featured_image.url
  // 		: homePlaceholder;
  // const userImage = requestInfo.buyer.photo || profilePlaceholder;

  let listOffers = "";
  offers.forEach((offer) => {
    listOffers += `<div key={offer.id} class="listing" style="border:1px solid #f5f5f5;display:inline-block;padding:10px;width:150px;margin:0 3.5px 20px;text-align:left;">
    <img src="${
      offer.featured_image.url
    }" style="max-height: 89px; height: 89px" width="100%" class="featured-listing-image" />
    <h5 class="property-address" style="color:#222222;font-size:9px;font-weight:500;margin:2px 0;">${
      offer.address
    }</h5>
    <p class="purchase-price-heading" style="font-size:8px;font-weight:500;color:#999999;line-height:8px;margin:5px 0 1px;">Listing Price</p>
    <p class="purchase-price" style="font-weight:bold;line-height:11px;color:#222222;font-size:10.75px;margin:0 0 5px;">$${offer.actual_price.toLocaleString()}</p>
    <p class="offer-price-heading" style="font-size:8px;font-weight:500;color:#999999;line-height:8px;margin:5px 0 1px;">Purchase Price</p>
    <p class="offer-price" style="font-weight:bold;line-height:11px;color:#DC3545;font-size:10.75px;margin:0 0 5px;">$${offer.purchasePrice.toLocaleString()}</p>
    <div class="closing-date-container" style="display:flex;flex-direction:row;margin:8px 0;">
      <img src="https://i.ibb.co/v4ZXsP2/calendar.png" class="calendar-icon" style="margin-right:4px;margin-top:1px;height:6.45px;width:5.91px;" />
      <div>
        <p class="closing-date-heading" style="font-size:8px;margin:0;color:#999999;">Closing Date</p>
        <p class="closing-date" style="font-size:9px;margin:0;color:#222222;font-weight:500;">${moment(
          offer.closingDate
        ).format("dddd MMMM Do, YYYY")}</p>
      </div>
    </div>
    <div class="closing-date-container" style="display:flex;flex-direction:row;margin:8px 0;">
      <img src="https://i.ibb.co/WpxQXZn/flag.png" class="calendar-icon" style="margin-right:4px;margin-top:1px;height:6.45px;width:5.91px;" />
      <div>
        <p class="closing-date-heading" style="font-size:8px;margin:0;color:#999999;">Finance Type</p>
        <p class="closing-date" style="font-size:9px;margin:0;color:#222222;font-weight:500;">${
          offer.financingType
        }</p>
      </div>
    </div>
    <a href="https://homematchx.com/share/offer/${
      offer.id
    }" class="view-details-btn" style="padding:5px;font-weight:500;font-size:10px;color:#ffffff;background-color:#28A745;border-radius:4px;border:1px solid #28A745;cursor:pointer;text-decoration:none;display:block;text-align:center;margin:2px 0 0;">View Details</a>
  </div>`;
  });

  return `
    <!DOCTYPE html>
    <html lang="en" style="font-family:'Raleway', sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;-webkit-tap-highlight-color:transparent;">
      <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <meta content="width=device-width,initial-scale=1" name="viewport">
        <title>
          Homematchx
      </title>
        <meta content="width=device-width, initial-scale=1.0, shrink-to-fit=no" name="viewport">
        <link href="./assets/css/custom.css" rel="stylesheet">
        <style type="text/css">
          @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,700;1,900&display=swap');
              @media (max-width: 550px) {
                .home-with-logo {
                  height: 360px !important;
                }
              }
              @media (max-width: 500px) {
                .home-with-logo {
                  height: 326px !important;
                }
                .logo {
                  position: relative !important;
                  z-index: 1 !important;
                  width: 80px !important;
                  height: 33px !important;
                  margin-top: 29px !important;
                  margin-left: 4% !important;
                }
                .featured-listing-image {
                  max-height: 189px !important;
                  height: 189px !important;
                }
              }
              @media (max-width: 450px) {
                .home-with-logo {
                  height: 294px !important;
                }
                .listing {
                  width: 85% !important;
                }
                .property-address {
                  font-size: 14px !important;
                  margin: 4px 0 !important;
                }
                .purchase-price-heading {
                  margin: 15px 0 8px !important;
                  font-size: 11px !important;
                }
                .purchase-price {
                  font-size: 15.75px !important;
                }
                .offer-price-heading {
                  font-size: 11px !important;
                  margin: 15px 0 8px !important;
                }
                .offer-price {
                  font-size: 15.75px !important;
                }
                .closing-date {
                  margin: 5px 0 0 !important;
                  font-size: 14px !important;
                }
                .closing-date-heading {
                  font-size: 11px !important;
                }
                .closing-date-container {
                  margin: 15px 0 !important;
                }
                .calendar-icon {
                  height: 11.45px !important;
                  width: 12px !important;
                }
                .view-details-btn {
                  padding: 10px !important;
                  font-weight: 600 !important;
                  font-size: 14px !important;
                  margin: 15px 0 0 !important;
                }
              }
              @media (max-width: 460px) {
                .home-img {
                  margin: 16px 0 0 !important;
                  height: auto !important;
                  width: 100% !important;
                }
                .agent-details {
                  display: inline-table !important;
                  height: auto !important;
                  max-height: none !important;
                  text-align: center !important;
                }
                .property-img {
                  width: 100% !important;
                  height: auto !important;
                }
                .agent-profile {
                  width: 100% !important;
                  border-right: 0 !important;
                }
                .logo {
                  position: relative !important;
                  z-index: 1 !important;
                  width: 60px !important;
                  height: 26px !important;
                  margin-top: 21px !important;
                  margin-left: 5% !important;
                }
              }
              @media (max-width: 415px) {
                .home-with-logo {
                  height: 272px !important;
                }
              }
              @media (max-width: 376px) {
                .home-with-logo {
                  height: 248px !important;
                }
              }
              @media (max-width: 320px) {
                .home-with-logo {
                  height: 210px !important;
                }
                .logo {
                  position: relative !important;
                  z-index: 1 !important;
                  width: 51px !important;
                  height: 22px !important;
                  margin-top: 18px !important;
                  margin-left: 5% !important;
                }
              }
        </style>
      </head>
      <body style="margin:0;font-family:'Raleway', sans-serif;height:100%;width:100%;background:#f8f8f8;">
        <div class="container" style="width:100%;max-width:600px;padding:0;background:white;padding-bottom:0;overflow:hidden;margin:30px auto;margin-top:30px;margin-bottom:30px;">
          <div class="home-with-logo" style="background-image:url(https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Foffers%2Fhomematchx-header.png?alt=media&token=dec3d740-1bd0-49ac-86e1-72ab58ae37ce);height:391px;width:100%;background-repeat:no-repeat;background-size:contain;">
          </div>
          <div class="inner-container" style="margin:0 4.5%;">
            <p style="font-weight: 700; font-size: 16px; line-height: 30px; margin-bottom: 0;">Hello ${
              name || "Dear"
            },<br>
              You have received an offer
          </p>
            <br>
            <p style="font-size: 16px; font-weight: 500; line-height: 27px;">
              Congratulations, there is someone interested in purchasing your home. In order to assist you in making the desicion to sell your home, we have outlined the purchase details for your review in the Offer Summary.
          </p>
            <div class="listings" style="margin-bottom:5px;text-align:center;">
              ${listOffers}
            </div>
            <div class="agent-details" style="background-color:#F4F4F4;padding:25px 0;display:flex;flex-direction:row;max-height:250px;">
              <div class="agent-profile" style="text-align:center;border-right:1px solid #DFDFDF;min-width:240px;">
                <img src="${
                  sellerDetails.profilePhoto
                }" class="agent" style="width:110px;height:110px;border-radius:110px;">
                <p class="agent-name" style="color:#000000;font-size:16px;line-height:22px;font-weight:700;margin-bottom:8px;">${
                  sellerDetails.realtor_name
                }</p>
                ${
                  sellerDetails.brokerage_name
                    ? `<p class="agent-company" style="font-weight:400;font-size:14px;color:#000000;margin:0;">Agent, ${sellerDetails.brokerage_name}</p>`
                    : ""
                }
              </div>
              <div class="agent-contact-info" style="padding:0 26px;">
                <p style="font-size: 14px; font-weight: 400; color: #000000;">Should you have any questions or would like to speak about the offer, please contact me below.</p>
                <div style="margin: 25px 0;">
                 ${
                   sellerDetails.realtor_phone
                     ? ` <p style="font-size: 16px; font-weight: 400; color: #000000; margin: 8px 0;">Direct:  <b>${sellerDetails.realtor_phone}</b></p>`
                     : ""
                 }
                  ${
                    sellerDetails.broker_phone
                      ? `<p style="font-size: 16px; font-weight: 400; color: #000000; margin: 8px 0;">Office:  <b>${sellerDetails.broker_phone}</b></p>`
                      : ""
                  }
                </div>
                <a href="mailto:${sellerDetails.email}" style="color: #092267; font-size: 16px; text-decoration: underline; cursor: pointer !important;">${
                  sellerDetails.email
                }</a>
              </div>
            </div>
            <div class="about-us" style="padding:26px 30px;background-color:#092267;text-align:center;">
              <img src="https://i.ibb.co/0sw79Yj/homematch.png" width="247" height="43">
              <p style="color: #ffff; font-size: 14px; font-weight: 500; line-height: 27px;">Homematchx is a market intelligence tool to enhances transparency when selling real estate by analyzing and aggregating purchase offers. It's designed to save agents time in managing offers terms and conditions when presenting to sellers. HMX collects and analyzes offer data to help sellers make better decisions when selling their home. </p>
            </div>
          </div>
          <footer>
          </footer>
        </div>
      </body>
    </html>
  `;
}

export default shareOffers;
