import React from "react";
import { HorizontalBar } from "react-chartjs-2";
import { drawCustomBorderRadiusHorizontal } from "utils/Functions";

const AverageSummaryChart = ({
  totalValues,
  isShadow = true,
  isData = false,
}) => {
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader(false);
    setTimeout(() => {
      setLoader(true);
    }, 200);
  }, [totalValues]);

  const data = {
    labels: [""],
    datasets: [
      {
        label: "You",
        data: [isData ? Math.round(totalValues?.yourPurchasePrices).toFixed(0) : 0],
        borderColor: "#109CF1",
        backgroundColor: "#109CF1",
        barThickness: 35,
      },
      {
        label: "Benchmark (other brokerages)",
        data: [isData ? Math.round(totalValues?.otherPurchasePrices).toFixed(0) : 0],
        borderColor: "#EBEBEB",
        backgroundColor: "#EBEBEB",
        barThickness: 35,
      },
    ],
  };

  const plugin = {
    id: "increase-legend-spacing",
    beforeInit(chart) {
      const originalFit = chart.legend.fit;
      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 25;
      };
    },
  };

  const drawCustomLabels = (chart) => {
    const ctx = chart.ctx;
    ctx.beginPath();
    ctx.moveTo(0, chart.height - 25);
    ctx.lineTo(chart.width, chart.height - 25);
    ctx.strokeStyle = '#fff';
    ctx.lineWidth = 10;
    ctx.lineCap = 'round';
    ctx.stroke();

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;
      if (!meta.hidden)
        elements.forEach((element, elementIndex) => {
          const startX1 = element._view.x - element._yScale.width / 10;
          const startY1 = element._view.y - element._yScale.width / 0.8;
          const startY2 = element._view.y + element._yScale.width / 1.1;
          const startX2 = element._view.x + element._yScale.width / 6.;
          drawCustomBorderRadiusHorizontal(
            ctx,
            startX1,
            startY1,
            startY2,
            startX2
          );

          const value = dataset.data[elementIndex];
          ctx.save();
          ctx.textBaseline = "middle";
          ctx.fillStyle = "black";
          ctx.font = "bold 14px Arial";
          const labelX = element._view.x;
          const labelY = element._view.y;
          if (value >= 0 && value < 1000) {
            ctx.fillText(value, labelX + 25, labelY);
          } else if (value >= 1000 && value < 1000000) {
            ctx.fillText((value / 1000).toFixed(0) + "K", labelX + 25, labelY);
          } else if (value >= 1000000 && value < 1000000000) {
            ctx.fillText(
              (value / 1000000).toFixed(0) + "M",
              labelX + 25,
              labelY
            );
          } else if (value >= 1000000000 && value < 1000000000000) {
            ctx.fillText(
              (value / 1000000000).toFixed(0) + "B",
              labelX + 25,
              labelY
            );
          } else if (value >= 1000000000000) {
            ctx.fillText(
              (value / 1000000000000).toFixed(0) + "T",
              labelX + 25,
              labelY
            );
          }
          ctx.restore();
        });
    });
  };

  const options = {
    layout: {
      padding: {
        // right: 20,
        // left: 20
      },
    },
    tooltips: {
      callbacks: {
        label: (e) => {
          let value;
          value = Number(e.value);

          if (value > 0 && value < 1000) {
            return value + "K";
          } else if (value >= 1000 && value < 1000000) {
            return (value / 1000).toFixed(0) + "K";
          } else if (value >= 1000000 && value < 1000000000) {
            return (value / 1000000).toFixed(0) + "M";
          } else if (value >= 1000000000 && value < 1000000000000) {
            return (value / 1000000000).toFixed(0) + "B";
          } else if (value >= 1000000000000) {
            return (value / 1000000000000).toFixed(2) + "T";
          }
        },
      },
    },
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    legend: {
      display: true,
      position: "top",
      reverse: true,
      align: "start",
      labels: {
        padding: 20,
        usePointStyle: true,
        pointStyle: "circle",
      },
    },
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: (value) => {
              if (value === 0) {
                return value;
              } else if (value < 1000) {
                return value + "K";
              } else if (value >= 1000 && value < 1000000) {
                return (value / 1000).toFixed(0) + "K";
              } else if (value >= 1000000 && value < 1000000000) {
                return (value / 1000000).toFixed(0) + "M";
              } else if (value >= 1000000000 && value < 1000000000000) {
                return (value / 1000000000).toFixed(0) + "B";
              } else if (value >= 1000000000000) {
                return (value / 1000000000000).toFixed(2) + "T";
              }
            },
            maxTicksLimit: 5,
          },
          gridLines: {
            display: isData,
            borderDash: [4, 4],
            lineWidth: 2,
            zeroLineColor: "rgba(235, 235, 235, 1)",
            zeroLineWidth: 2,
            zeroLineBorderDash: [5, 5],
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
            max: 8,
            min: -3,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    plugins: {
      customLabels: {
        labels: {
          render: "value",
          fontColor: "black",
          position: "end",
          offset: -4,
        },
      },
      legend: {
        itemSpacing: 40,
      },
    },
  };

  return (
    <div
      style={{
        width: "100%",
        height: "380px",
        margin: "0 auto",
        background: "#ffffff",
        position: 'relative'
      }}
    >
      {!isData && (
        <div style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          left: 0
        }}>

          <span>
            There is no data...
          </span>
        </div>
      )}
      {
        totalValues && !Object.values(totalValues).every((e) => e === 0) ?
          loader ? (
            <>
            <HorizontalBar
              plugins={[
                {
                  id: "customLabels",
                  afterDatasetsDraw: (chart) => drawCustomLabels(chart),
                },
                plugin,
              ]}
              data={data}
              options={options}
            />
            </ >
          ) : (
            <img
              src="/img/loader.gif"
              style={{
                position: "absolute",
                transform: "translate(1210%, 420%)",
              }}
            />
          )
          : <></>
      }
    </div>
  );
};

export default AverageSummaryChart;
