import React, { Component } from "react";
import SellerListItem from "./SellerListItem";

class SellerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: ""
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="manage-table">{this.rows()}</div>
      </React.Fragment>
    );
  }

  rows() {
    const { sellers,user,remove, showOnSharePage, showDetails } = this.props;
    if (sellers.length === 0) {
      return <div className="responsive-table">No records found</div>;
    }
    return sellers.map((seller,i) => (
      <SellerListItem
        key={seller.id} 
        user={user}
        remove={remove}
        seller={seller}
        data={seller}
        showDetails = { showDetails }
        showOnSharePage={showOnSharePage}
      />
    ));
  }
}

export default SellerList;
