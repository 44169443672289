import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import _ from "lodash";

class AveragePropertiesOfferTerms extends Component {
  state = {
    offerPropertyFilter: "",
  };

  onPropertyFilterChange = async ({
    target: { value: offerPropertyFilter },
  }) => {
    this.setState({ offerPropertyFilter });
  };

  render() {
    const { offers } = this.props;
    const { offerPropertyFilter } = this.state;

    let averageEarnestMoney = 0;
    let averageOptionFee = 0;
    let averageFinanceAmount = 0;
    let averagePurchasePrice = 0;
    let financingTypeCashCount = 0;
    let financingTypeLeaseCount = 0;

    const propertyOffers = offerPropertyFilter
      ? offers.filter((offer) => offer.seller === offerPropertyFilter)
      : offers;

    const offerSellers = {};

    offers.forEach((offer) => {
      offerSellers[offer.seller] = offer;
    });

    const averageFinanceType = {
      Cash: 0,
      Lease: 0,
      Conventional: 0,
      FHA: 0,
      VA: 0,
      ["Seller Finance"]: 0,
    };

    propertyOffers.forEach((offer) => {
      averageEarnestMoney += parseInt(offer.earnestMoney);
      averageOptionFee += parseInt(offer.optionFee);
      averageFinanceAmount += parseInt(offer.financeAmount);
      averagePurchasePrice += parseInt(offer.purchasePrice);
      financingType = offer.financingType;

      if (offer.financingType === "Cash") {
        averageFinanceType.Cash++;
      } else if (offer.financingType === "Lease") {
        averageFinanceType.Lease++;
      } else if (offer.financingType === "Conventional") {
        averageFinanceType.Conventional++;
      } else if (offer.financingType === "FHA") {
        averageFinanceType.FHA++;
      } else if (offer.financingType === "VA") {
        averageFinanceType.VA++;
      } else if (offer.financingType === "Seller Finance") {
        averageFinanceType["Seller Finance"]++;
      }
    });
    const avgFinanceType = Object.keys(averageFinanceType).reduce((a, b) =>
      averageFinanceType[a] > averageFinanceType[b] ? a : b
    );

    let financingType =
      financingTypeCashCount > financingTypeLeaseCount ? "Cash" : "Lease";

    averageEarnestMoney = Math.round(
      averageEarnestMoney / propertyOffers.length
    );
    averageOptionFee = Math.round(averageOptionFee / propertyOffers.length);
    averageFinanceAmount = Math.round(
      averageFinanceAmount / propertyOffers.length
    );
    averagePurchasePrice = Math.round(
      averagePurchasePrice / propertyOffers.length
    );

    return (
      <div className="average-offers-container chart-mr-left tab-responsive-mb-30">
        <Row
        className="mt-4 mt-lg-0 px-3 px-md-0"
        >
          <Col lg={5} md={6} sm={6} className="pr-0">
            <div className="total-received-offers-container">
              <p className="total-avg-offers">
                {propertyOffers.length ? propertyOffers.length : offers.length}
              </p>
              <p className="average-offers-text">Offers</p>
            </div>
          </Col>
          <Col lg={7} md={6} sm={6} className="pl-0 pr-0">
            <div className="average-terms-container">
              <p className="chart-title margin-avg-head">
                Average of all the terms
              </p>
              <div className="analytics-row center-me map-filters">
                <select
                  name="offerPropertyFilter"
                  value={offerPropertyFilter}
                  onChange={this.onPropertyFilterChange}
                  className="analytics-property-filter"
                  style={{
                    background: "none",
                  }}
                >
                  <option value="">All Offers</option>
                  {Object.keys(offerSellers).map((key, i) => (
                    <option key={i} value={key}>
                      {offerSellers[key].address}
                    </option>
                  ))}
                </select>
                <img
                  src="/images/icons/arrow.svg"
                  style={{
                    position: "relative",
                    right: "0px",
                    top: "1px",
                  }}
                />
              </div>
              <div className="mt-10">
                <div className="analytics-row center-me">
                  <div className="blue-circle"></div>
                  <div className="custom-spaceBetween">
                    <p className="analytics-text-normal">Purchase Price </p>
                    <p className="analytics-text-normal">
                      ${averagePurchasePrice || 0}
                    </p>
                  </div>
                </div>
                <div className="analytics-row center-me">
                  <div className="blue-circle"></div>
                  <div className="custom-spaceBetween">
                    <p className="analytics-text-normal">Finance Amount</p>
                    <p className="analytics-text-normal">
                      ${averageFinanceAmount || 0}
                    </p>
                  </div>
                </div>
                <div className="analytics-row center-me">
                  <div className="blue-circle"></div>
                  <div className="custom-spaceBetween">
                    <p className="analytics-text-normal">Finance Type</p>
                    <p className="analytics-text-normal">
                      {offerPropertyFilter ? avgFinanceType : "Cash"}
                    </p>
                  </div>
                </div>
                <div className="analytics-row center-me">
                  <div className="blue-circle"></div>
                  <div className="custom-spaceBetween">
                    <p className="analytics-text-normal">Earnest Money</p>
                    <p className="analytics-text-normal">
                      ${averageEarnestMoney || 0}
                    </p>
                  </div>
                </div>
                <div className="analytics-row center-me">
                  <div className="blue-circle"></div>
                  <div className="custom-spaceBetween">
                    <p className="analytics-text-normal">Option Fee</p>
                    <p className="analytics-text-normal">
                      ${averageOptionFee || 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AveragePropertiesOfferTerms;
