// import React, { Component } from "react";
// import { Row, Col } from "reactstrap";
// import _ from "lodash";

// class AveragePropertiesOfferTerms extends Component {
//   state = {
//     offerPropertyFilter: "",
//   };

//   onPropertyFilterChange = async ({
//     target: { value: offerPropertyFilter },
//   }) => {
//     this.setState({ offerPropertyFilter });
//   };

//   render() {
//     const { offers } = this.props;
//     const { offerPropertyFilter } = this.state;

//     const offerSellers = {};

//     let averageEarnestMoney = 0;
//     let averageOptionFee = 0;
//     let averageFinanceAmount = 0;
//     let averagePurchasePrice = 0;
//     let financingTypeCashCount = 0;
//     let financingTypeLeaseCount = 0;

//     const propertyOffers = offerPropertyFilter
//       ? offers.filter((offer) => offer.seller === offerPropertyFilter)
//       : offers;

//     offers.forEach((offer) => {
//       offerSellers[offer.seller] = offer;
//     });

//     propertyOffers.forEach((offer) => {
//       averageEarnestMoney += parseInt(offer.earnestMoney);
//       averageOptionFee += parseInt(offer.optionFee);
//       averageFinanceAmount += parseInt(offer.financeAmount);
//       averagePurchasePrice += parseInt(offer.purchasePrice);
//       financingType = offer.financingType;
//       if (offer.financingType === "Cash") {
//         financingTypeCashCount++;
//       }
//       if (offer.financingType === "Lease") {
//         financingTypeLeaseCount++;
//       }
//     });

//     let financingType =
//       financingTypeCashCount > financingTypeLeaseCount ? "Cash" : "Lease";

//     averageEarnestMoney = Math.round(
//       averageEarnestMoney / propertyOffers.length
//     );
//     averageOptionFee = Math.round(averageOptionFee / propertyOffers.length);
//     averageFinanceAmount = Math.round(
//       averageFinanceAmount / propertyOffers.length
//     );
//     averagePurchasePrice = Math.round(
//       averagePurchasePrice / propertyOffers.length
//     );

//     return (
//       <div className="average-offers-container tab-responsive-mb-30">
//         <Row>
//           <Col lg={5} md={6} sm={6}>
//             <div className="total-received-offers-container">
//               <p className="total-avg-offers">
//                 {propertyOffers.length ? propertyOffers.length : offers.length}
//               </p>
//               <p className="average-offers-text">Offers</p>
//             </div>
//           </Col>
//           <Col lg={7} md={6} sm={6} className="pl-0">
//             <div className="average-terms-container">
//               <p className="chart-title margin-avg-head">
//                 Average of all the terms
//               </p>
//               <div className="analytics-row center-me map-filters">
//                 <select
//                   name="offerPropertyFilter"
//                   value={offerPropertyFilter}
//                   onChange={this.onPropertyFilterChange}
//                   className="analytics-property-filter"
//                   style={{
//                     background: "none",
//                   }}
//                 >
//                   <option value="">All Offers</option>
//                   {Object.keys(offerSellers).map((key, i) => (
//                     <option key={i} value={key}>
//                       {offerSellers[key].address}
//                     </option>
//                   ))}
//                 </select>
//                 <img
//                   src="/images/icons/arrow.svg"
//                   style={{
//                     position: "relative",
//                     right: "0px",
//                     top: "1px",
//                   }}
//                 />
//               </div>
//               <div className="mt-10">
//                 <div className="analytics-row center-me">
//                   <div className="blue-circle"></div>
//                   <div className="custom-spaceBetween">
//                     <p className="analytics-text-normal">Purchase Price </p>
//                     <p className="analytics-text-normal">
//                       ${averagePurchasePrice || 0}
//                     </p>
//                   </div>
//                 </div>
//                 <div className="analytics-row center-me">
//                   <div className="blue-circle"></div>
//                   <div className="custom-spaceBetween">
//                     <p className="analytics-text-normal">Finance Amount</p>
//                     <p className="analytics-text-normal">
//                       ${averageFinanceAmount || 0}
//                     </p>
//                   </div>
//                 </div>
//                 <div className="analytics-row center-me">
//                   <div className="blue-circle"></div>
//                   <div className="custom-spaceBetween">
//                     <p className="analytics-text-normal">Finance Type</p>
//                     <p className="analytics-text-normal">
//                       {offerPropertyFilter ? financingType : ""}
//                     </p>
//                   </div>
//                 </div>
//                 <div className="analytics-row center-me">
//                   <div className="blue-circle"></div>
//                   <div className="custom-spaceBetween">
//                     <p className="analytics-text-normal">Earnest Money</p>
//                     <p className="analytics-text-normal">
//                       ${averageEarnestMoney || 0}
//                     </p>
//                   </div>
//                 </div>
//                 <div className="analytics-row center-me">
//                   <div className="blue-circle"></div>
//                   <div className="custom-spaceBetween">
//                     <p className="analytics-text-normal">Option Fee</p>
//                     <p className="analytics-text-normal">
//                       ${averageOptionFee || 0}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </Col>
//         </Row>
//       </div>
//     );
//   }
// }

// export default AveragePropertiesOfferTerms;
import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";

class OfferPriceListPrice extends Component {
  render() {
    const { offers, sellers } = this.props;
    let actualPrice = 0;
    let purchasePrice = 0;
    offers.forEach((offer) => {
      actualPrice += parseInt(offer.actual_price || 0);
      purchasePrice += parseInt(offer.purchasePrice);
    });
    const grouped = Object.keys(_.groupBy(offers, "seller"));
    const barOptions = {
      chart: {
        type: "bar",
        height: 242,
        reflow: true,
      },
      tooltip: {
        valuePrefix: "$",
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      xAxis: {
        categories: [
          `${(offers || []).length} Offers - ${
            (grouped || []).length
          } Listings`,
        ],
        title: {
          text: null,
        },
        labels: {
          formatter: function () {
            return "";
          },
        },
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        x: -40,
        y: 80,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
        shadow: true,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "Average list Price",
          data: [Math.round(actualPrice / sellers.length)],
          color: "#28A745",
        },
        {
          name: "Average offer Price",
          data: [Math.round(purchasePrice / offers.length)],
          color: "#129DE0",
        },
      ],
    };
    Highcharts.seriesTypes.line.prototype.getPointSpline =
      Highcharts.seriesTypes.spline.prototype.getPointSpline;
    return (
      <div
        className="charts-main-container ml-15 tab-responsive-mr-15"
        style={{
          height: "100% !important",
          padding: "21px 0px 0px 0px !important",
        }}
      >
        <div className="property-engagement-tools">
          <p className="chart-title">Offer Price to List Price</p>
        </div>
        <div className="property-engagement-chart">
          <HighchartsReact highcharts={Highcharts} options={barOptions} />
        </div>
        <div className="analytics-row">
          <div className="analytics-row center-me">
            <div className="green-box" />
            <p className="box-label">Average list price</p>
          </div>
          <div className="analytics-row center-me ml-20">
            <div className="blue-box" />
            <p className="box-label">Average offer price</p>
          </div>
        </div>
      </div>
    );
  }
}
export default OfferPriceListPrice;
