import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { drawCustomBorderRadius, drawWhiteLine } from 'utils/Functions';

const AverageDetailsChart = ({ brockerageInfo, isData }) => {

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(false)
    if (brockerageInfo && isData) {
      setTimeout(() => {
        setLoader(true)
      }, 200);
    }
  }, [brockerageInfo, isData])

  const dataValues = Object.values(brockerageInfo);
  const drawCustomLabels = (chart) => {
    drawWhiteLine(chart)

    const ctx = chart.ctx;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;

      elements.forEach((element, elementIndex) => {
        const maxValue = Math.max(...dataset.data);
        if ((maxValue / dataset.data[elementIndex] <= 40)) {
          drawCustomBorderRadius(element, ctx);
        }
        const value = dataset.data[elementIndex];
        if(value > 0) {
          ctx.save();
          ctx.textBaseline = 'middle';
          ctx.fillStyle = 'black';
          ctx.font = 'bold 10px Raleway';
          const labelX = element._view.x;
          const labelY = element._view.y;

          const checkValue = (value, num, letter) => {
            return [(value / num).toFixed(0) + letter, labelX - (value / num).toFixed(0).length * 4, labelY - 10]
          }
          if(value < 1000) {
            ctx.fillText(
              value,
              labelX - 10,
              labelY - 10
            );
          } else if(value >= 1000 && value < 1000000) {
            ctx.fillText(...checkValue(value, 1000, "K"));
          } else if(value >= 1000000 && value < 1000000000) {
            ctx.fillText(...checkValue(value, 1000000, "M"));
          } else if(value >= 1000000000 && value < 1000000000000) {
            ctx.fillText(...checkValue(value, 1000000000, "B"));
          } else if(value >= 1000000000000) {
            ctx.fillText(...checkValue(value, 1000000000000, "T"));
          }
          ctx.restore();
        }
      });
    });
  };

  return (
     <div className="chartWrapper" style={{
      position: 'relative',
    }}>
      {!isData && (
        <div style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          left: 0
        }}>

          <span>
            There is no data...
          </span>
        </div>
      )}
       <div
          className="chartWrapper-scroll" style={{
            overflowX: isData ? "auto" : "hidden",
          }}>
         <div
            style={{
              width: `${dataValues.length > 7 ? dataValues.length * 120 : 945}px`,
              height: '460px',
            }}
         >
          {
            !loader && <img
            src="/img/loader.gif"
            style={{
              position: "absolute",
              transform: "translate(1200%, 440%)",
            }}
            />
          }
          {(isData && loader) ? ( <Bar
              options={{
                tooltips: {
                  callbacks: {
                    label: (e) => {
                      const value = Number(e.value);

                      if (value === 0) {
                        return value;
                      } else if (value < 1000) {
                        return value + "K";
                      } else if (value >= 1000 && value < 1000000) {
                        return (value / 1000).toFixed(0) + "K";
                      } else if (value >= 1000000 && value < 1000000000) {
                        return (value / 1000000).toFixed(0) + "M";
                      } else if (value >= 1000000000 && value < 1000000000000) {
                        return (value / 1000000000).toFixed(0) + "B";
                      } else if (value >= 1000000000000) {
                        return (value / 1000000000000).toFixed(2) + "T";
                      }
                    }
                  }
                },
                legend: {
                  display: false
                },
                plugins: {
                  title: {
                    display: true,
                    text: 'Chart.js Bar Chart - Stacked'
                  }
                },
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                  mode: 'index',
                  intersect: false
                },
                scales: {
                  yAxes: [
                    {
                      gridLines: {
                        display: isData,
                        borderDash: [5, 5],
                        lineWidth: 2,
                        borderDashOffset: 20,
                        color: 'rgba(235, 235, 235, 1)',
                        drawBorder: false,
                        drawTicks: false,
                      },
                      ticks: {
                        callback: (value) =>  {
                          if(!isData) return "";

                          if (value === 0) {
                            return value;
                          } else if (value < 1000) {
                            return value + "K";
                          } else if (value >= 1000 && value < 1000000) {
                            return (value / 1000).toFixed(0) + "K";
                          } else if (value >= 1000000 && value < 1000000000) {
                            return (value / 1000000).toFixed(0) + "M";
                          } else if (value >= 1000000000 && value < 1000000000000) {
                            return (value / 1000000000).toFixed(0) + "B";
                          } else if (value >= 1000000000000 && value < 1000000000000000) {
                            return (value / 1000000000000).toFixed(0) + "T";
                          } 
                        },
                        fontFamily: 'Raleway',
                        fontSize: 14,
                       padding: 15,
                       maxTicksLimit: 3
                      }
                    }
                  ],
                  xAxes: [
                    {
                      gridLines: {
                        display: true,
                        color: '#fff',
                        drawBorder: false,
                        drawTicks: false,
                        zeroLineWidth: 0.5,
                        drawOnChartArea: function (context) {
                          return context.tick.index === 0;
                        }
                      },
                      ticks: {
                        maxRotation: 0,
                        minRotation: 0,
                        fontSize: 10,
                        padding: 15,
                        callback: (value) => {
                          if(!isData) return "";

                          if(value.length > 10) {
                            return value.slice(0, 10) + '...';
                          }
                          return value;
                        }
                      }
                    }
                  ]
                }
              }}
              data={{
                labels: Object.keys(brockerageInfo),
                datasets: [
                  {
                    label: '',
                    data: isData ? dataValues.map((info) => {
                      return Math.round(info.totalSum / info.offersCount)}
                    ) : [],
                    backgroundColor: dataValues.map((_, i) => i === 0 ? '#109CF1' : '#EBEBEB'),
                    stack: 1,
                    barThickness: 32,
                    borderWidth: 0
                  }
                ],
                categoryPercentage: 10
              }}
              plugins={[
                {
                  id: 'customLabels',
                  afterDatasetsDraw: (chart) => drawCustomLabels(chart)
                }
              ]}
           />) : <></>}
         </div>
       </div>
     </div>
  );
};

export default AverageDetailsChart;
