import React, { useEffect, useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { drawCustomBorderRadiusHorizontal } from "utils/Functions";

const LocationDetailsChart = ({ locations, mobile }) => {
  const [max, setMax] = useState("");

  const combinedLocations = Object.entries(locations).reduce(
    (accumulator, [key, obj]) => {
      if (typeof key === "string") {
        const city = key.split(",")[1].trim();
        const state = key.split(",")[2].split(" ")[1];
        const location = city ? city + ", " + state : state;

        if (!accumulator[location]) {
          accumulator[location] = obj;
        } else {
          accumulator[location] += obj;
        }
      }
      return accumulator;
    },
    {}
  );

  const data = {
    labels: Object.keys(combinedLocations),
    datasets: [
      {
        data: Object.values(combinedLocations),
        borderColor: "#109CF1",
        backgroundColor: "#109CF1",
        barThickness: 16,
      },
    ],
  };

  const drawCustomLabels = (chart) => {
    const ctx = chart.ctx;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;

      elements.forEach((element, elementIndex) => {
        const startX1 = element._view.x - element._model.height / 9;
        const startY1 = element._view.y - element._model.height / 1.5;

        const startY2 = element._view.y + element._model.height / 2.4;
        const startX2 = element._view.x + element._model.height / 6.3;
        drawCustomBorderRadiusHorizontal(
          ctx,
          startX1,
          startY1,
          startY2,
          startX2
        );
        const value = dataset.data[elementIndex];
        ctx.save();
        ctx.textBaseline = "middle";
        ctx.fillStyle = "black";
        ctx.font = "bold 14px Arial";
        const labelX = element._view.x;
        const labelY = element._view.y;

        ctx.fillText(
          value === 0 || value < 1000 ? value : value / 1000 + "K",
          labelX + 15,
          labelY
        );
        ctx.restore();
      });
    });
  };

  useEffect(() => {
    const macVAlue = Math.max(...Object.values(combinedLocations));
    setMax(macVAlue);
  }, [locations]);

  return (
    <HorizontalBar
      data={data}
      plugins={[
        {
          id: "customLabels",
          afterDatasetsDraw: (chart) => drawCustomLabels(chart),
        },
      ]}
      options={{
        indexAxis: "y",
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,

        scales: {
          xAxes: [
            {
              gridLines: {
                borderDash: [5, 5],
                lineWidth: 2,
                borderDashOffset: 4,
                color: "rgba(235, 235, 235, 1)",
                zeroLineColor: "rgba(235, 235, 235, 1)",
                zeroLineWidth: 2,
                zeroLineBorderDash: [5, 5],
              },
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                callback: (value) => {
                  return value;
                },
                backdropPaddingX: 0.1,
                backdropPaddingY: 0.1,
                suggestedMin: 0,
                suggestedMax: max + 4,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                backdropPaddingX: 0.1,
                backdropPaddingY: 0.1,
                padding: 10,
              },
            },
          ],
        },
      }}
    />
  );
};

export default LocationDetailsChart;
