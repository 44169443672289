import React from "react";
import moment from "moment";

function sendToTitleEmail(requestInfo, sellerDetails, offerId) {
  console.log("requestInfo", requestInfo);

  return `<!DOCTYPE html>
  <html lang="en" style="font-family:'Raleway', sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;-webkit-tap-highlight-color:transparent;">
     <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <meta content="width=device-width,initial-scale=1" name="viewport">
        <title>
           Homematchx
        </title>
        <meta content="width=device-width, initial-scale=1.0, shrink-to-fit=no" name="viewport">
        <link href="./assets/css/custom.css" rel="stylesheet">
        <style type="text/css">
           @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,700;1,900&display=swap');
           .mobile-badge-row {
           width: auto !important;
           display: -webkit-inline-box !important;
           margin: 0;
           }
           @media (max-width: 768px) {
           .mobile-badge-row {
           width: auto !important;
           display: -webkit-inline-box !important;
           margin: 0;
           }
           .mobilephone-column-margin {
           margin: 0 16px !important;
           }
           }
           @media (max-width: 550px) {
           .home-with-logo {
           height: 360px !important;
           }
           }
           @media (max-width: 500px) {
           .home-with-logo {
           height: 326px !important;
           }
           .logo {
           position: relative !important;
           z-index: 1 !important;
           width: 80px !important;
           height: 33px !important;
           margin-top: 29px !important;
           margin-left: 4% !important;
           }
           }
           @media (max-width: 450px) {
           .home-with-logo {
           height: 294px !important;
           }
           }
           @media (max-width: 460px) {
           .home-img {
           margin: 16px 0 0 !important;
           height: auto !important;
           width: 100% !important;
           }
           .row {
           display: inline-block !important;
           width: 100% !important;
           }
           .agent-details {
           display: inline-table !important;
           height: auto !important;
           max-height: none !important;
           text-align: center !important;
           }
           .flex-spacebtwn {
           display: block !important;
           }
           .property-img {
           width: 100% !important;
           height: auto !important;
           }
           .mobile-badge-row {
           width: auto !important;
           display: -webkit-inline-box !important;
           margin: 0;
           }
           .price-details {
           width: auto !important;
           }
           .agent-profile {
           width: 100% !important;
           }
           .width-50 {
           display: block !important;
           width: 100% !important;
           min-width: 100% !important;
           }
           .ist-table {
           border-right: 0 !important;
           }
           .mobile-table-margin {
           margin-top: 20px !important;
           }
           .offer-tag {
           margin-top: 5px !important;
           margin-bottom: 0 !important;
           }
           .property-details {
           margin: 20px 0 5px !important;
           }
           .logo {
           position: relative !important;
           z-index: 1 !important;
           width: 60px !important;
           height: 26px !important;
           margin-top: 21px !important;
           margin-left: 5% !important;
           }
           .bold-td {
           width: 160px !important;
           }
           }
           @media (max-width: 415px) {
           .home-with-logo {
           height: 272px !important;
           }
           }
           @media (max-width: 376px) {
           .home-with-logo {
           height: 248px !important;
           }
           }
           @media (max-width: 320px) {
           .home-with-logo {
           height: 210px !important;
           }
           .logo {
           position: relative !important;
           z-index: 1 !important;
           width: 51px !important;
           height: 22px !important;
           margin-top: 18px !important;
           margin-left: 5% !important;
           }
           }
        </style>
     </head>
     <body style="margin:0;font-family:'Raleway', sans-serif;height:100%;width:100%;background:#f8f8f8;">
        <p/>
        </p>
        <div class="container" style="width:100%;max-width:600px;padding:0;background:white;padding-bottom:0;overflow:hidden;margin:30px auto;margin-top:30px;margin-bottom:30px;">
           <div class="home-with-logo" style="background-image:url(https://i.ibb.co/zSXgz6F/home.png);height:391px;width:100%;background-repeat:no-repeat;background-size:contain;">
              ${
                sellerDetails.licenseNo === "9005664"
                  ? `<img src="https://i.ibb.co/FY0Nktf/logo.png" alt="logo" class="logo" style="position:relative;z-index:1;width:101px;height:37px;margin-top:36px;margin-left:4%;"/>`
                  : `<img src="https://i.ibb.co/CH33Z1P/black-logo.png" alt="homematchx-logo" style="position:relative;z-index:1;width:101px;height:28px;margin-top:36px;margin-left:4%;"/>`
              }
              <!-- <img src="https://i.ibb.co/zSXgz6F/home.png" alt="home" class="home-img"> -->
           </div>
           <div class="inner-container" style="margin:0 4.5%;">
              <p style="font-size: 16px; font-weight: 500; line-height: 27px;">
                 <span style="font-weight: 700; font-size: 16px; line-height: 40px; margin-bottom: 20px;">Hello ${
                   requestInfo.title_company || "Title Company"
                 },</span><br>
                 ${requestInfo.body || ""}
              </p>
              <h4 style="text-align: center; font-weight: 700; font-size: 16px; text-align: center; margin: 37px 0 19px 0;">Below are the offer highlights that were executed.</h4>
              <div class="table-container" style="border:1px solid #f5f5f5;padding:16px;">
                 <div class="flex-spacebtwn" style="display:flex;flex-direction:row;justify-content:space-between;">
                    <div class="row" style="display:flex;flex-direction:row;">
                       <img src="${
                         sellerDetails?.featured_image?.url ||
                         "https://homematchx.com/static/media/house-avatar.2d5a9ac4.jpg"
                       }" class="property-img" style="width:142px;height:90px;margin-right:16px;"/>
                       <div>
                          <p class="offer-tag" style="font-size:8.6px;font-weight:500;color:#999999;margin:0;margin-bottom:5px;">Offer</p>
                          <div class="row mobile-badge-row" style="display:flex;flex-direction:row;">
                             <p class="badge review" style="font-size:7px;font-weight:bold;border-radius:4px;padding:3px 8px;margin-right:4px;background-color:#FFC107;color:#222222;">Executed</p>
                             <p class="badge received" style="font-size:7px;font-weight:bold;border-radius:4px;padding:3px 8px;margin-right:4px;background-color:#A3A4A5;color:white;">Received</p>
                          </div>
                          <h5 class="property-address" style="color:#222222;font-size:10.75px;font-weight:bold;margin:0;">
                             ${
                               sellerDetails.street_number
                                 ? sellerDetails.street_number
                                 : ""
                             } ${
    sellerDetails.street_name ? sellerDetails.street_name : ""
  }, ${sellerDetails.city ? sellerDetails.city + ", " : ""} ${
    sellerDetails.state ? sellerDetails.state : ""
  }, ${sellerDetails.zip_code ? sellerDetails.zip_code : ""}
                          </h5>
                          <p class="buyers-agent" style="color:#999999;font-size:8.6px;font-weight:500;">
                             <img src="https://i.ibb.co/fHgDBwc/person.png" style="margin-right: 4px;" height="6.45" width="5.37">
                             buyers agent <span class="buyer-agent-name" style="text-decoration:underline;color:#222222;">
                             ${requestInfo.agentName}
                             </span>
                          </p>
                          <p class="ad-time" style="color:#222222;font-size:8.6px;font-weight:500;">
                             <img src="https://i.ibb.co/v4ZXsP2/calendar.png" style="margin-right: 4px;" height="6.45" width="5.91">
                             ${moment(requestInfo.createdAt * 1000).format(
                               "MMMM Do YYYY, [at] h:mm:ss a"
                             )} CST (${moment(
    requestInfo.createdAt * 1000
  ).fromNow()})
                          </p>
                       </div>
                    </div>
                    <div class="price-details" style="padding:16px;background-color:#E9ECEF;width:140px;">
                       <p style="font-size: 8.6px; font-weight: 500; color: #222222; line-height: 9px; margin: 0 0 6px 0;">Executed Date</p>
                       <p style="font-weight: bold; line-height: 11px; color: #222222; font-size: 10.75px; margin: 0;">${
                         requestInfo.executedDate || "N/A"
                       }
                       </p>
                       <p style="font-size: 8.6px; font-weight: 500; color: #222222; line-height: 9px; margin: 10.75px 0 6px 0;">Earnest/Option Money Due</p>
                       <p style="font-weight: bold; line-height: 11px; color: #222222; font-size: 10.75px; margin: 0;">${
                         requestInfo.earnestMoneyDue || "N/A"
                       }
                       </p>
                       <p style="font-size: 8.6px; font-weight: 500; color: #222222; line-height: 9px; margin: 10.75px 0 6px 0;">Option Period End</p>
                       <p style="font-weight: bold; line-height: 11px; color: #DC3545; font-size: 10.75px; margin: 0;">${
                         requestInfo.optionPeriodEnd || "N/A"
                       }
                       </p>
                    </div>
                 </div>
                 <div class="property-details" style="margin:52px 0;">
                    <table width="100%" style="border-collapse:collapse;">
                       <tr style="height:32.24px;vertical-align:center;">
                          <td class="width-50" style="width:50%;">
                             <table class="ist-table" style="border-collapse:collapse;border-right:13px solid white;width:100%;">
                                <tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                                   <td class="bold-td" style="color:#222222;font-size:8.6px;font-weight:bold;padding:0 16px;">Survey Due</td>
                                   <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;padding:0 16px;">${
                                     requestInfo.surveyDue || "N/A"
                                   }
                                   </td>
                                </tr>
                                <tr style="height:32.24px;vertical-align:center;">
                                   <td class="bold-td" style="color:#222222;font-size:8.6px;font-weight:bold;padding:0 16px;">Financing Deadline</td>
                                   <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;padding:0 16px;">${
                                     requestInfo.financingDeadline || "N/A"
                                   }
                                   </td>
                                </tr>
                                <tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                                   <td class="bold-td" style="color:#222222;font-size:8.6px;font-weight:bold;padding:0 16px;">HOA Due</td>
                                   <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;padding:0 16px;">${
                                     requestInfo.hoaDue || "N/A"
                                   }
                                   </td>
                                </tr>
                                <tr style="height:32.24px;vertical-align:center;">
                                   <td class="bold-td" style="color:#222222;font-size:8.6px;font-weight:bold;padding:0 16px;">Closing Date</td>
                                   <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;padding:0 16px;">${
                                     requestInfo.closingDate || "N/A"
                                   }
                                   </td>
                                </tr>
                                <tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                                   <td class="bold-td" style="color:#222222;font-size:8.6px;font-weight:bold;padding:0 16px;">Sellers Agent</td>
                                   <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;padding:0 16px;">${
                                     requestInfo.sellers_agent || "N/A"
                                   }
                                   </td>
                                </tr>
                             </table>
                          </td>
                          <td class="width-50" style="width:50%;">
                             <div class="mobile-table-margin">
                                <table width="100%" style="border-collapse:collapse;">
                                   <tr style="height:32.24px;vertical-align:center;">
                                      <td class="bold-td" style="color:#222222;font-size:8.6px;font-weight:bold;padding:0 16px;">Sellers Agent Transaction Coordinator</td>
                                      <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;padding:0 16px;">${
                                        requestInfo.transaction_coordinator ||
                                        "N/A"
                                      }
                                      </td>
                                   </tr>
                                   <tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                                      <td class="bold-td" style="color:#222222;font-size:8.6px;font-weight:bold;padding:0 16px;">Sellers</td>
                                      <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;padding:0 16px;">${
                                        requestInfo.sellers.length
                                          ? requestInfo.sellers?.map(
                                              (seller) => seller
                                            )
                                          : "N/A"
                                      }
                                      </td>
                                   </tr>
                                   <tr style="height:32.24px;vertical-align:center;">
                                      <td class="bold-td" style="color:#222222;font-size:8.6px;font-weight:bold;padding:0 16px;">Buyers Agent</td>
                                      <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;padding:0 16px;">${
                                        requestInfo.buyers_agent || "N/A"
                                      }
                                      </td>
                                   </tr>
                                   <tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                                      <td class="bold-td" style="color:#222222;font-size:8.6px;font-weight:bold;padding:0 16px;">Buyers</td>
                                      <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;padding:0 16px;">${
                                        requestInfo.buyers || "N/A"
                                      }
                                      </td>
                                   </tr>
                                   <tr style="height:32.24px;vertical-align:center;">
                                      <td class="bold-td" style="color:#222222;font-size:8.6px;font-weight:bold;padding:0 16px;">Lender</td>
                                      <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;padding:0 16px;">${
                                        requestInfo.lender || "N/A"
                                      }
                                      </td>
                                   </tr>
                                </table>
                             </div>
                          </td>
                       </tr>
                    </table>
                 </div>
                 <div style="text-align: center; display: grid; margin: 25px 0;">
                    <a href="https://homematchx.com/offers/${offerId}" class="review-offer" style="margin:9px auto;width:240px;padding:13px;font-weight:500;font-size:18px;color:#ffffff;background-color:#092267;border-radius:5px;border:1px solid #092267;cursor:pointer;text-decoration:none;">Review executed offer</a>
                 </div>
              </div>
           </div>
           <div class="agent-details" style="background-color:#F4F4F4;padding:25px 0;display:flex;flex-direction:row;
          //  max-height:250px;
           "
           >
              <div class="agent-profile" style="text-align:center;border-right:1px solid #DFDFDF;min-width:240px;margin:auto;">
                 <img src=${
                   requestInfo.profilePhoto
                     ? `${requestInfo.profilePhoto}`
                     : "https://i.ibb.co/nLjvmw9/avatar-simple.png"
                 } class="" style="width:110px;height:110px;border-radius:110px;" />
                 <p class="agent-name" style="color:#000000;font-size:16px;line-height:22px;font-weight:700;margin: 8px 0">${
                   requestInfo.sellers_agent
                 }
                 </p>
                 ${
                   requestInfo.brokerageName
                     ? `
                 <p class="agent-company" style="font-weight:400;font-size:14px;color:#000000;margin:0;">Agent, ${requestInfo.brokerageName}</p>
                 `
                     : ""
                 }
              </div>
              <div class="agent-contact-info" style="padding:0 26px;">
                 <p style="font-size: 14px; font-weight: 400; color: #000000;">${
                   requestInfo.closing
                 }
                 </p>
                 <div>
                    ${
                      requestInfo.licenseNo === "9005664"
                        ? `
                    <p style="font-size: 16px; font-weight: 400; color: #000000; margin: 8px 0;">Direct:  <b>469-912-0465</b></p>
                    `
                        : ""
                    }
                    <p style="font-size: 16px; font-weight: 400; color: #000000; margin: 8px 0;">Office:  <b>${requestInfo.phoneNumber?.replace(
                      /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
                      "$1$2$3-$4$5$6-$7$8$9$10"
                    )}</b>
                    </p>
                 </div>
                 <a href="mailto:${
                   requestInfo.sellerEmail
                 }" style="color: #092267; font-size: 16px; text-decoration: underline; cursor: pointer !important;">${
    requestInfo.sellerEmail
  }</a>
                <p style="font-size: 16px; font-weight: 400; color: #000000; margin: 8px 0;"><b>Transaction Coordinator Email:</b>  <a href="mailto:${
                  requestInfo.transaction_coordinator_email
                }" style="color: #092267; font-size: 16px; text-decoration: underline; cursor: pointer !important;">${
   requestInfo.transaction_coordinator_email
 }</a></p>
              </div>
           </div>
           <div class="about-us" style="padding:26px 30px;background-color:#092267;text-align:center;">
              <img src="https://i.ibb.co/0sw79Yj/homematch.png" width="247" height="43">
              <p style="color: #ffff; font-size: 14px; font-weight: 500; line-height: 27px;">Homematchx is a market intelligence tool to enhances transparency when selling real estate by analyzing and aggregating purchase offers. It's designed to save agents time in managing offers terms and conditions when presenting to sellers. HMX collects and analyzes offer data to help sellers make better decisions when selling their home. </p>
           </div>
        </div>
     </body>
  </html>`;
}

export default sendToTitleEmail;
