import React from 'react';

const CommonCloseButton = ({ onClick, buttonText }) =>
   <button
      style={{
        alignItems: 'center',
        background: '#FFFFFF',
        border: '1px solid #109CF1',
        color: '#109CF1',
        borderRadius: '4px',
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '14px',
        textAlign: 'center',
        padding: '5px 7px 5px 12px',
        display: 'flex',
        columnGap: '10px',
        cursor: 'pointer'
      }}
   >
     {buttonText}
     <img src="/images/icons/close.svg" onClick={() => onClick(buttonText)} />
   </button>;


export default CommonCloseButton;