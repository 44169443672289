import React, { useEffect, useRef, useState } from "react";
import { Bar } from "react-chartjs-2";
import AverageSummaryChart from "components/Charts/AverageSummaryChart";
import { drawCustomBorderRadius, drawWhiteLine } from "utils/Functions";

const GlobalAverage = ({
  mobile,
  allOffer,
  selectedBrokarage,
  setSelectedBrokarage,
}) => {
  const [lowerSliceLimit, setLowerSliceLimit] = useState(0);
  const [upperSliceLimit, setUpperSliceLimit] = useState(20);
  const [loader, setLoader] = useState(false);
  const [dataValues, setDataValues] = useState([]);
  const [barChartLabels, setBarChartLabels] = useState([]);
  const chartContainerRef = useRef(null);

  useEffect(() => {
    setDataValues(
      Object.values(allOffer).slice(lowerSliceLimit, upperSliceLimit)
    );
    setBarChartLabels(
      Object.keys(allOffer).slice(lowerSliceLimit, upperSliceLimit)
    );
  }, [lowerSliceLimit, upperSliceLimit]);

  const drawCustomLabels = (chart) => {
    drawWhiteLine(chart);
    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;
      if (!meta.hidden)
        elements.forEach((element, elementIndex) => {
          const maxValue = Math.max(...dataset.data);
          if (maxValue / dataset.data[elementIndex] <= 40) {
            drawCustomBorderRadius(element, ctx);
          }
          const value = dataset.data[elementIndex];
          if (value > 0) {
            ctx.save();
            ctx.textBaseline = "middle";
            ctx.fillStyle = "black";
            ctx.font = "bold 10px Raleway";
            const labelX = element._view.x;
            const labelY = element._view.y;

            if (value < 1000) {
              ctx.fillText(value, labelX + 25, labelY - 10);
            } else if (value >= 1000 && value < 1000000) {
              ctx.fillText(
                (value / 1000).toFixed(0) + "K",
                labelX - (value / 1000).toFixed(0).length * 3,
                labelY - 10
              );
            } else if (value >= 1000000 && value < 1000000000) {
              ctx.fillText(
                (value / 1000000).toFixed(0) + "M",
                labelX - (value / 1000000).toFixed(0).length * 3,
                labelY - 10
              );
            } else if (value >= 1000000000 && value < 1000000000000) {
              ctx.fillText(
                (value / 1000000000).toFixed(0) + "B",
                labelX - (value / 1000000000).toFixed(0).length * 3.75,
                labelY - 10
              );
            } else if (value >= 1000000000000) {
              ctx.fillText(
                (value / 1000000000000).toFixed(0) + "T",
                labelX - (value / 1000000000000).toFixed(0).length * 3,
                labelY - 10
              );
            }
            ctx.restore();
          }
        });
    });
  };

  useEffect(() => {
    setLoader(false);
    setTimeout(() => {
      setLoader(true);
    }, 200);
  }, [allOffer]);

  const option = {
    layout: {
      padding: {
        top: 20,
      },
    },
    tooltips: {
      callbacks: {
        label: (e) => {
          const value = Number(e.value);

          if (value === 0) {
            return value;
          } else if (value < 1000) {
            return value + "K";
          } else if (value >= 1000 && value < 1000000) {
            return (value / 1000).toFixed(0) + "K";
          } else if (value >= 1000000 && value < 1000000000) {
            return (value / 1000000).toFixed(0) + "M";
          } else if (value >= 1000000000 && value < 1000000000000) {
            return (value / 1000000000).toFixed(0) + "B";
          } else if (value >= 1000000000000) {
            return (value / 1000000000000).toFixed(2) + "T";
          }
        },
      },
    },
    onClick: (_, elements) => {
      if (elements?.length > 0) {
        let selectedChart = 0;
        let count = 0;
        dataValues.forEach((_, index) => {
          if (index != elements[0]._index) {
            selectedChart += Math.round(dataValues[index].totalSum);
            count += dataValues[index].offersCount;
          }
        });
        const newBrokarage = dataValues[elements[0]._index].name;
        const res = [...selectedBrokarage];
        if (!res.includes(newBrokarage)) {
          res.push(newBrokarage);
          setSelectedBrokarage(res);
        }
      }
    },
    legend: {
      display: false,
    },
    plugins: {
      title: {
        display: true,
        text: "Chart.js Bar Chart - Stacked",
      },
    },

    maintainAspectRatio: false,
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: dataValues?.length > 0,
            borderDash: [5, 5],
            lineWidth: 2,
            borderDashOffset: 20,
            color: "rgba(235, 235, 235, 1)",
            drawBorder: false,
            drawTicks: false,
          },
          ticks: {
            callback: (value) => {
              if (dataValues?.length === 0) return "";
              if (value === 0) {
                return value;
              } else if (value < 1000) {
                return value + "K";
              } else if (value >= 1000 && value < 1000000) {
                return (value / 1000).toFixed(0) + "K";
              } else if (value >= 1000000 && value < 1000000000) {
                return (value / 1000000).toFixed(0) + "M";
              } else if (value >= 1000000000 && value < 1000000000000) {
                return (value / 1000000000).toFixed(0) + "B";
              } else if (value >= 1000000000000) {
                return (value / 1000000000000).toFixed(2) + "T";
              }
            },
            fontFamily: "Raleway",
            fontSize: 14,
            padding: 20,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
            color: "#fff",
            drawBorder: false,
            drawTicks: false,
            zeroLineWidth: 0.5,
            drawOnChartArea: function (context) {
              return context.tick.index === 0;
            },
          },
          ticks: {
            maxRotation: 0,
            minRotation: 0,
            fontSize: 10,
            padding: 20,
            callback: (value) => {
              if (value.length > 10) {
                return value.slice(0, 10) + "...";
              }
              return value;
            },
          },
        },
      ],
    },
  };

  const data = {
    // labels: mobile
    //   ? Object.keys(allOffer).slice(lowerSliceLimit, upperSliceLimit)
    //   : Object.keys(allOffer),
    labels: barChartLabels,
    datasets: [
      {
        label: "",
        data: dataValues.map((info) => {
          return Math.round(info.totalSum / info.offersCount);
        }),
        backgroundColor: "#109CF1",
        stack: 1,
        barThickness: 32,
        borderWidth: 0,
      },
    ],
    categoryPercentage: 10,
  };

  return (
    <div
      style={{
        backgroundColor: "#fff",
        width: "100%",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "55px",
          height: "19px",
          background: "#fdfdfd",
          position: "absolute",
          bottom: "0",
          left: "10px",
        }}
      />
      <div
        ref={chartContainerRef}
        className="chartWrapper-scroll chartWrapper-scroll-global"
        style={{
          ...((selectedBrokarage?.length == 1 &&
            dataValues &&
            dataValues.length) ||
          !dataValues.length
            ? { marginRight: 0 }
            : { marginRight: 30 }),
        }}
        onScroll={(e) => {
          const container = chartContainerRef.current;
          const scrollEndPosition =
            container.scrollWidth - container.clientWidth;
          const center = scrollEndPosition / 2;
          if (
            Object.values(allOffer).length === upperSliceLimit &&
            Math.ceil(container.scrollLeft) >= scrollEndPosition
          ) {
            return;
          }
          if (Math.ceil(container.scrollLeft) >= scrollEndPosition) {
            container.scrollTo(center, 0);
            if (Object.values(allOffer).length - upperSliceLimit < 10) {
      
              setLowerSliceLimit(Object.values(allOffer).length - 20);
              setUpperSliceLimit(Object.values(allOffer).length);
            } else {

              setLowerSliceLimit((prev) => prev + 10);
              setUpperSliceLimit((prev) => prev + 10);
            }
          } else if (Math.ceil(container.scrollLeft) === 0) {
            if (lowerSliceLimit >= 20) {
              setLowerSliceLimit((prev) => prev - 10);
              setUpperSliceLimit((prev) => prev - 10);
              container.scrollTo(center, 0);
            } else {
              setLowerSliceLimit(0);
              setUpperSliceLimit(20);
            }
          }
        }}
      >
        <div>
          <div>
            <div>
              {selectedBrokarage?.length == 1 &&
              dataValues &&
              dataValues.length ? (
                <AverageSummaryChart
                  isData={true}
                  isShadow={false}
                  totalValues={{
                    yourPurchasePrices:
                      allOffer[selectedBrokarage[0]]?.actual_price /
                      allOffer[selectedBrokarage[0]]?.offersCount,
                    otherPurchasePrices:
                      allOffer[selectedBrokarage[0]]?.totalSum /
                      allOffer[selectedBrokarage[0]]?.offersCount,
                  }}
                />
              ) : (
                <div
                  style={{
                    width: `${
                      dataValues?.length * 100 > 950
                        ? dataValues?.length * 100
                        : "100%"
                    }px`,
                    minHeight: "400px",
                    position: !dataValues?.length ? "relative" : "inherit",
                  }}
                >
                  {!dataValues?.length && (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                      }}
                    >
                      <span>There is no data...</span>
                    </div>
                  )}
                  {loader ? (
                    <Bar
                      options={option}
                      data={data}
                      plugins={[
                        {
                          id: "customLabels",
                          afterDatasetsDraw: (chart) => drawCustomLabels(chart),
                        },
                      ]}
                      height={
                        (4500 /
                          (dataValues?.length * 100 > 950
                            ? dataValues?.length * 100
                            : 950)) *
                        30
                      }
                    />
                  ) : (
                    <img
                      src="/img/loader.gif"
                      style={{
                        position: "absolute",
                        transform: "translate(1210%, 420%)",
                      }}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalAverage;
