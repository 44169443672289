import React from "react";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ScoringModal = ({ showModal, hideModal }) => {
  return <Modal
  isOpen={showModal}
  toggle={hideModal}
  size="md"
  fade={false}
  className="learn-scoring-modal"
>
  <ModalHeader
    className="modal-header"
    toggle={hideModal}
  ></ModalHeader>
  <ModalBody className="modal-body">
    <div className="scoring-modal-container">
      <h2 className="text-center gradient-heading black-bold">Learn How We Score</h2>
      <p className="text-center">Here’s the full explanation of our scoring system:</p>
      <p className="tooltip-subheading">Weighted Categories:</p>
      <ul className="weighted-list">
        <li>
          Purchase Price (Net): <strong>Weight = 54.5 (Base) + Bonus</strong>
        </li>
        <li>Closing Date: <strong>Weight = 20</strong></li>
        <li>Earnest Money: <strong>Weight = 15</strong></li>
        <li>Option Period: <strong>Weight = 10</strong></li>
        <li>Title Policy Responsibility: <strong>Weight = 0.5</strong></li>
      </ul>
      <p className="tooltip-subheading">Detailed Breakdown by Category:</p>
      <ul className="detailed-list">
        <li>
          <strong>1. Purchase Price (Net) (54.5 Base + Bonus):</strong>
          <ul>
            <li>
              Net Purchase Price = Purchase Price - Seller Concessions - Residential Service Contract
            </li>
            <li>
              Base Score: <code>min((Net Purchase Price / Listing Price) × 54.5, 54.5)</code>
            </li>
            <li>
              Bonus Points: +2 points for every $5,000 above the listing price.
            </li>
            <li>
              Formula: <code>Bonus = ((Net Purchase Price - Listing Price) / 5,000) × 2</code>
            </li>
          </ul>
        </li>
        <li>
          <strong>2. Closing Date (Weight: 20):</strong>
          <ul>
            <li>15 days or fewer: <strong>20 points</strong></li>
            <li>16–30 days: <strong>15 points</strong></li>
            <li>31–45 days: <strong>10 points</strong></li>
            <li>46 days or more: <strong>5 points</strong></li>
          </ul>
        </li>
        <li>
          <strong>3. Earnest Money (Weight: 15):</strong>
          <ul>
            <li>5% or more of listing price: <strong>15 points</strong></li>
            <li>3.5%–4.99%: <strong>12 points</strong></li>
            <li>1.5%–3.49%: <strong>10 points</strong></li>
            <li>Below 1.49%: <strong>5 points</strong></li>
          </ul>
        </li>
        <li>
          <strong>4. Option Period (Weight: 10):</strong>
          <ul>
            <li>0 days: <strong>10 points</strong></li>
            <li>1–3 days: <strong>8 points</strong></li>
            <li>4–5 days: <strong>5 points</strong></li>
            <li>6+ days: <strong>0 points</strong></li>
          </ul>
        </li>
        <li>
          <strong>5. Title Policy Responsibility (Weight: 1):</strong>
          <ul>
            <li>Buyer pays: <strong>0.5 points</strong></li>
            <li>Seller pays: <strong>0 points</strong></li>
          </ul>
        </li>
      </ul>
      <p className="tooltip-subheading">Example Calculation:</p>
      <div className="example-calculation">
        <p>Listing Price = $300,000</p>
        <p>
          Offer #1: $310,000, 14 days, 2%, 3 days, Buyer pays<br />
          Net Purchase Price = $310,000 - $0 - $0<br />
          Bonus Points = ((310,000 - 300,000) / 5,000) × 2 = 4<br />
          Score: 54.5 (Base) + 4 (Bonus) + 20 + 15 + 8 + 0.5 = <strong>102.0</strong>
        </p>
        <p>
          Offer #2: $330,000, 20 days, 1.5%, 4 days, Buyer pays<br />
          Net Purchase Price = $330,000 - $5,000 - $500 = $324,500<br />
          Bonus Points = ((324,500 - 300,000) / 5,000) × 2 = 9.8<br />
          Score: 54.5 (Base) + 9.8 (Bonus) + 15 + 12 + 5 + 0.5 = <strong>96.8</strong>
        </p>
        <p>
          Offer #3: $350,000, 25 days, 1%, 0 days, Seller pays<br />
          Net Purchase Price = $350,000 - $10,000 - $1,000 = $339,000<br />
          Bonus Points = ((339,000 - 300,000) / 5,000) × 2 = 15.6<br />
          Score: 54.5 (Base) + 15.6 (Bonus) + 10 + 10 + 10 + 0 = <strong>100.1</strong>
        </p>
        <p>
          Offer #4: $400,000, 30 days, 1%, 2 days, Buyer pays<br />
          Net Purchase Price = $400,000 - $0 - $0<br />
          Bonus Points = ((400,000 - 300,000) / 5,000) × 2 = 40<br />
          Score: 54.5 (Base) + 40 (Bonus) + 15 + 10 + 8 + 0.5 = <strong>128.0</strong>
        </p>
      </div>
      <div className="d-flex justify-content-end flex-wrap mt-4">
        <button
          className="calc-cancel-btn mr-0"
          onClick={hideModal}
        >
          Close
        </button>
      </div>
    </div>
  </ModalBody>
</Modal>
}

export default ScoringModal;
