import JSZip from  'jszip';
import { saveAs } from 'file-saver';

export const generateZipFromCloud = (docs, name) => { 
  const urls = docs;
    const zip = new JSZip()
    const folder = zip.folder('')
    urls.forEach((url)=> {
   const blobPromise =  fetch(url)    
    .then(function (response) {  
      console.log({response})             
        if (response.status === 200 || response.status === 0) {
            return Promise.resolve(response.blob());
        } else {
            return Promise.reject(new Error(response.statusText));
        }
    })


      const name = decodeURI(url.substring(url.lastIndexOf('%2F') + 3, url.indexOf('?alt')));
      folder.file(name, blobPromise)
    })

    zip.generateAsync({type:"blob"})
        .then(blob => {
          console.log(blob)
          saveAs(blob, name + ".zip");
        })
        .catch(e => console.log(e));
}