import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import TimeAgo from "react-timeago";
import { saveActivity } from "../Services/Activity";

class OfferComments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      dateTime: "",
      commentText: "",
      comments: [],
      allComments: [],
      isLoading: true,
      savingComment: false,
      filters: {
        senders: "",
        messageType: "",
      },
      messageTypeOptions: ["comment", "counter"],
    };
  }

  componentDidMount = async () => {
    let offerId = this.props.offerId;

    let ref = firebase.database().ref(`/OfferComments/${offerId}`);
    ref.on("value", (snap) => {
      let comments = [];
      snap.forEach((s) => {
        let comment = s.val();
        comment.isCounter = false; // Mark normal comments
        comments.push(comment);
      });
      comments.reverse();
      this.setState({ comments, isLoading: false }, this.updateAllComments);
    });
    
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.comments !== this.state.comments ||
      prevProps.counterOffers !== this.props.counterOffers
    ) {
      this.updateAllComments();
    }
    if (prevState.filters.senders !== this.state.filters.senders) {
      this.updateMessageTypeOptions();
    }
  }
  
  updateMessageTypeOptions = () => {
    const { senders, messageType } = this.state.filters;
    const filteredComments = this.state.allComments.filter(
      (c) => !senders || c.name === senders
    );
    const hasCounterComments = filteredComments.some((c) => c.isCounter);
    const messageTypeOptions = ["comment"];
    if (hasCounterComments) {
      messageTypeOptions.push("counter");
    }
    if (messageType === "counter" && !hasCounterComments) {
      this.setState({
        filters: { ...this.state.filters, messageType: "" },
        messageTypeOptions,
      });
    } else {
      this.setState({ messageTypeOptions });
    }
  };
  

  updateAllComments = () => {
    const counterComments = (this.props.counterOffers || []).map((offer) => ({
      name: offer.sentBy || "-",
      commentedAt: offer.sentAt ? offer.sentAt * 1000 : Date.now(),
      text: (
        <>
          {offer.offerData.comments}
        </>
      ), 
      isCounter: true,
    }));
    const allComments = [...this.state.comments, ...counterComments];
    this.setState({ allComments });
  };

  saveComment = async () => {
    if (!this.state.commentText || this.state.savingComment) return;
    const { user } = this.props;
    let offerId = this.props.offerId;
    let name = this.props.name || user.realtor_name;
    let comment = {
      text: this.state.commentText,
      name,
      commentedAt: new Date().getTime(),
      isCounter: false,
    };

    this.setState({
      savingComment: true,
    });
    firebase
      .database()
      .ref(`/OfferComments/${offerId}`)
      .push(comment, (err) => {
        if (!err) this.setState({ commentText: "", savingComment: false });
        else
          alert(
            "Unexpected error occurred while saving comment. Please try again later."
          );
      });

    saveActivity({
      text: `A new comment has been posted.`,
      userName: user.full_name || user.realtor_name,
      userId: user.uid,
    });
  };
 
  applyFilters = (allComments) => {
    const { senders, messageType } = this.state.filters;

    return allComments.map((c) => ({
      ...c,
      isHighlighted:
        (!senders || senders === c.name) &&
        (!messageType ||
          (messageType === "comment" && !c.isCounter) ||
          (messageType === "counter" && c.isCounter)),
    }));
  };

  handleFilterChange = (type, value) => {
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        [type]: value,
      },
    }));
  };

  render() {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const { isLoading, allComments } = this.state;
    const { name } = this.props;

    const filteredComments = this.applyFilters(allComments);

    const senders = Array.from(
      new Set(allComments.map((c) => c.name).filter((name) => name && name.trim()))
    );

    let ListComments = [];
    filteredComments.forEach((c, i) => {
      const commentParts = String(c?.text || "").split(urlRegex);

      const commentWithLinks = commentParts.map((part, index) => {
        if (part.match(urlRegex)) {
          return (
            <a
              key={index}
              href={part}
              target="_blank"
              rel="noopener noreferrer"
            >
              {part}
            </a>
          );
        } else {
          return <span key={index}>{part}</span>;
        }
      });

      ListComments.push(
        <li
          key={i}
          style={{
            backgroundColor: c.isHighlighted && (this.state.filters.senders || this.state.filters.messageType) && "#ffffcc"
          }}
        >
          <div className="comment-div d-flex">
            <div className="profile-img-user">
              <a>
                <img src="/images/face-placeholder.png" alt="" />
              </a>
            </div>
            <div className="comment-user-name-dark">
              <h5 style={c.name === name ? { color: "#ff214f" } : null}>
                {c.name} - <TimeAgo date={c.commentedAt} />
                {c.isCounter && (
                  <span className="text-red"> (Counter Comment)</span>
                )}
              </h5>
              <p>{c.text}</p>
            </div>
          </div>
        </li>
      );
    });

    let renderComments = isLoading ? (
      <>Loading Comments...</>
    ) : (
      <>{ListComments.length > 0 ? ListComments : <>No comment has been added yet.</>}</>
    );

    return (
      <div>
        <div className="filter-section d-flex offer-comments-filter-container">
          <div className="d-inline-block">
            <div className="d-inline-block">
              <select
                name="senderFilter"
                value={this.state.filters.senders}
                onChange={(e) => this.handleFilterChange("senders", e.target.value)}
                className="search-fields offers-search-fields mr-0 mr-md-4 offer-comments-filter"
              >
                <option value="">All Senders</option>
                {senders.map((sender, index) => (
                  <option key={index} value={sender}>
                    {sender}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="d-inline-block">
            <div className="d-inline-block">
              <select
                name="messageTypeFilter"
                value={this.state.filters.messageType}
                onChange={(e) =>
                  this.handleFilterChange("messageType", e.target.value)
                }
                className="search-fields offers-search-fields mr-1 md-4 offer-comments-filter"
              >
                <option value="">Message Type</option>
                {this.state.messageTypeOptions.includes("comment") && (
                  <option value="comment">Comment</option>
                )}
                {this.state.messageTypeOptions.includes("counter") && (
                  <option value="counter">Counter Comment</option>
                )}
              </select>
            </div>
          </div>
        </div>
        <ul style={{ maxHeight: "250px" }}>{renderComments}</ul>
      </div>
    );
  }
}

export default OfferComments;
