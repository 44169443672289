import React from "react";
import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 25,
    fontSize: 10,
    fontFamily: "Helvetica",
  },
  header: {
    fontSize: 14,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 5,
  },
  section: {
    marginBottom: 6,
  },
  blackBar: {
    backgroundColor: "#000",
    color: "#fff",
    padding: 4,
    fontSize: 11,
    fontWeight: "bold",
    marginTop: 8,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 2,
  },
  bold: {
    fontWeight: "bold",
  },
  table: {
    width: "100%",
    border: "1px solid black",
    marginVertical: 3,
  },
  tableRow: {
    flexDirection: "row",
    borderBottom: "1px solid black",
    padding: 3,
  },
  tableCell: {
    flex: 1,
  },
  largeText: {
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 5,
  },
  image: {
    width: 140,
    height: 140,
    alignSelf: "center",
    marginVertical: 5,
  },
  footer: {
    position: "absolute", 
    bottom: 10,
    left: 25,
    right: 25,
    fontSize: 8,
    textAlign: "center",
  },
  signatureRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    display: 'flex'
  },
  signatureLine: {
    borderBottom: "1px solid black",
    width: "98%",
    marginTop: 5,
  },
  signatureText: {
    textAlign: "center",
    fontSize: 9,
    marginTop: 5,
  },
  dateText: {
    textAlign: "center",
    fontSize: 9,
    marginTop: 5,
  },
  rowSpaceBtwn: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  dateContainer: {
    width: '70%',
    position: 'absolute',
    right: 0
  },
  agentName: {
    marginTop: 18
  },
  footerTxt: {
    fontSize: 6
  },
  logo: {
    width: 150, 
    alignSelf: "center",
    marginTop: -10
  },
  chartTextBox: {
    position: "absolute",
    textAlign: "center",
    alignItems: "center",
  }
});

const FullReportPDF = ({ offer, formData, calculatedData, titleFees, otherFees, chartImage, currentSeller }) => {
  if (!offer) return null;
  const currentDate = new Date().toLocaleDateString(); 

  const address = (seller) => {
    const { street_number, street_name, city, state, zip_code, location, unit_number } = seller;
    const getLocationWithUnitAndZip = () => {
      if (!location?.name) return null;
      let modifiedLocationName = location.name;
      if (unit_number && !location.name.includes(unit_number)) {
        const streetPattern = new RegExp(`(${street_number} ${street_name})`);
        modifiedLocationName = location.name.replace(streetPattern, `$1 ${unit_number}`);
      }
      if (!modifiedLocationName.includes(zip_code)) {
        modifiedLocationName += `, ${zip_code}`;
      }
      return modifiedLocationName;
    };

    if (!seller.ListingKey) {
      return getLocationWithUnitAndZip() || `${street_number} ${street_name} ${unit_number}, ${city}, ${state}, ${zip_code}`;
    }
    if (seller.hideAddress) {
      return `${city}, ${state}, ${zip_code || ""}`;
    }
    return `${street_number} ${street_name}, ${city}, ${state}, ${zip_code || ""}`;
  }

  const formatPhoneNumber = (phone) => {
    if (!phone) return ""; 
    const cleaned = ("" + phone).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : phone;
  };
  

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image style={styles.logo} src='https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fblack-logo.png?alt=media&token=eaa1f864-8856-4248-b050-5f2b1775465b' />
        <Text style={styles.header}>Estimated Seller's Net Sheet</Text>
        <Text style={{ textAlign: "center", fontSize: 9 }}>
         {address(currentSeller)}
        </Text>
        <Text style={{ textAlign: "center", fontSize: 9 }}>
          {currentSeller.seller_name} | {formatPhoneNumber(currentSeller.seller_phone)} | {currentSeller.seller_email}
        </Text>

        {chartImage && (
          <View style={{ position: "relative", alignItems: "center", justifyContent: "center", marginTop: 50 }}>
            <Image style={styles.image} src={chartImage} />
            <View style={styles.chartTextBox}>
              <Text 
                style={{
                  fontSize: 18,
                  fontWeight: "bold",
                  color: "#333",
                }}
                >Net At Close</Text>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  color: "#333",
                }}
              >
                ${Number(calculatedData?.netAtClose.toFixed() || 0).toLocaleString()}
              </Text>
            </View>
          </View>
        )}

        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.bold}>Sales Price:</Text>
            <Text>${Number(formData?.price || 0).toLocaleString()}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.bold}>Loan Type:</Text>
            <Text>{formData?.calcType}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.bold}>Loan Balance:</Text>
            <Text>${Number(formData?.loanBalance || 0).toLocaleString()}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.bold}>Prorated Tax Owed:</Text>
            <Text>${calculatedData?.propertyTaxes?.toLocaleString() || "0.00"}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.bold}>Closing Costs:</Text>
            <Text>${calculatedData?.closingCosts?.toLocaleString() || "0.00"}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.bold}>Seller Concessions:</Text>
            <Text>${Number(formData?.SellerConcession_seller || 0).toLocaleString()}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.bold}>Service Contract:</Text>
            <Text>${Number(formData?.serviceContract || 0).toLocaleString()}</Text>
          </View>
        </View>

        <Text style={styles.blackBar}>Detailed Closing Costs</Text>
        <View style={styles.table}>
          {titleFees.map((fee, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={styles.tableCell}>{fee.name}</Text>
              <Text style={styles.tableCell}>${fee.value.toLocaleString()}</Text>
            </View>
          ))}
        </View>

        <Text style={styles.blackBar}>Other Fees</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Buyer Broker Fee</Text>
            <Text style={styles.tableCell}>${calculatedData?.buyerBF?.toLocaleString() || "0.00"}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Seller Broker Fee</Text>
            <Text style={styles.tableCell}>${calculatedData?.sellerBF?.toLocaleString() || "0.00"}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Service Contract</Text>
            <Text style={styles.tableCell}>${formData?.serviceContract?.toLocaleString() || "0.00"}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableCell}>Miscellaneous</Text>
            <Text style={styles.tableCell}>${formData?.miscellaneous?.toLocaleString() || "0.00"}</Text>
          </View>
          {calculatedData?.specialLoanFee > 0 && (
            <View style={styles.tableRow}>
              <Text style={styles.tableCell}>{calculatedData?.specialLoanFeeLabel}</Text>
              <Text style={styles.tableCell}>${calculatedData?.specialLoanFee?.toLocaleString()}</Text>
            </View>
          )}
          {otherFees.map((fee, index) => (
            <View style={styles.tableRow} key={index}>
              <Text style={styles.tableCell}>{fee.name}</Text>
              <Text style={styles.tableCell}>${fee.value.toLocaleString()}</Text>
            </View>
          ))}
        </View>

        <View style={styles.largeText}>
          <Text>Net At Close: ${calculatedData?.netAtClose?.toLocaleString()}</Text>
        </View>

        <View style={styles.footer}>
          <View style={[styles.signatureRow]}>
            <View style={{ flexDirection: 'row', width: "45%"}}>
              <Text style={[styles.signatureText, {marginRight: 4}]}>Sign(X)</Text>
              <View style={styles.signatureLine} />
            </View>
            <View style={{ flexDirection: 'row', width: "45%"}}>
              <Text style={[styles.signatureText, {marginRight: 4}]}>Sign(Y)</Text>
              <View style={styles.signatureLine} />
              <View style={[styles.rowSpaceBtwn, styles.dateContainer]}>
                <Text style={styles.agentName}>{currentSeller.seller_name}</Text>
                <Text style={styles.currentDate}>Date: {currentDate}</Text>
              </View>
            </View>
          </View>

          <Text style={styles.footerTxt}>This estimate is provided for informational purposes only, based upon the above proposed purchase price, type of financing and projected closing date, has been prepared to assist in computing costs. Amounts will vary depending upon
differences between actual and estimated repairs that may occur in the transaction, assessments, liens, impound accounts, charges by lenders, escrow companies, title insurers and other service providers and other items. Not all liens
may yet have been identified. Broker commissions are not set by law and are fully negotiable.Neither Broker, Agent nor PalmAgent guarantee, and assumes no responsibility for the accuracy, timeliness, correctness, or
completeness of the above information. Any conclusions that users draw from the information presented here are their own and are not to be attributed to the Broker, Agent or PalmAgent. PalmAgent is not a financial institution
engaged in mortgage lending and/or loan originations. The interest rates listed are for exemplary purposes only. All numbers are estimates and should be viewed as such. Total monthly payment identified includes PITI (Principal,
Interest, Taxes and Insurance). Final numbers should be obtained from your lending institution prior to closing. By signing below client acknowledges that client has read, understands and agrees to this Disclaimer.</Text>
        </View>
      </Page>
    </Document>
  );
};

export default FullReportPDF;
