import React from 'react'
import 'firebase/auth'
import Main from '../templates/Main'
import BetaRoute from '../routes/BetaRoute'
import AuthenticatedRoute from '../routes/AuthenticatedRoute'

export default function MainRoute({ component: Component, ...rest }) {

  if(rest.authenticated){
    return (
      <AuthenticatedRoute
      {...rest}
      component={props => {
        return <Main {...props}>
            <Component {...props} />
        </Main>
        }
      }
    />
    );
  }

  return (
    <BetaRoute
      {...rest}
      component={props => {
        return <Main {...props}>
            <Component {...props} />
        </Main>
        }
      }
    />
  );
}
