import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import { firebaseConfig } from '../components/Firebase/firebase';
import firebase from 'firebase/app'
import 'firebase/firestore'
import "react-toastify/dist/ReactToastify.css"
import { Button } from 'reactstrap';
import ReactDOMServer from 'react-dom/server';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';
import { LiveBookingSuccess, JoinLiveVisit } from '../components/emailTemplates/LiveBookingEmails';
import moment from 'moment';
import axios from 'axios';
import { getCount } from '../Services/Comments-Count';
import BookingComments from '../components/BookingComments';
import BookingSubmitComment from '../components/BookingSubmitComment';
import AddLiveStreamAddon from './../components/LiveStream/AddLiveStreamAddon';
const sendEmail = firebase.functions().httpsCallable('sendMatchEmail');
const OpenTok = require('opentok'),

opentok = new OpenTok('46494562', '5ff86b7050a2edd2cf6af44454fc32bde05c034f');

class LiveBookings extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            liveBookings: [],
            loading: true,
            sellerId: null,
            busy: false,
            apiKey: '46494562',
            sessionId: '',
            token: '',
            startStream: false,
            cameraPosition: 'environment',
            pageUrl: '',
            bookingLiveCount: 0,
            userName: '',
            bookingId: '',
            liveStreamURL: '',
            speaker: true,
            mic: true,
            publisher: null,
            subscriber: null,
        }

        this.publisherProperties = {
            showControls: true,
            facingMode: this.state.cameraPosition,
            mirror: false
        };

        this.otPublisher = React.createRef();
        this.otSubscriber = React.createRef();
    }

    id() {
        const { match } = this.props
        const { params } = match
        const { id } = params
        return id
    }

    componentWillMount = async () => {
        const id = this.id()
        if (!id) {
            this.props.history.goBack()
            return
        }

        const db = firebase.firestore();
        let docs = await db.collection('liveVisitBookings').where('seller', '==', id).get();

        let bookings = [];
        docs.forEach(doc => {
            bookings.push({
                ...doc.data(),
                id: doc.id
            });
        });

        this.setState({
            bookings,
            loading: false
        });
    }

    toggleMic = () => {
        const { otPublisher } = this;
        if (otPublisher) {
            const { current } = otPublisher;
            if (current) {
                const publusher = current.getPublisher();
                if (publusher) {
                    const { mic } = this.state;
                    publusher.publishAudio(!mic);
                    this.setState({
                        mic: !mic
                    });
                }
            }
        }
    }

    endDestroyStreamSession = async () => {

        const { cloudFunctionHost } = firebaseConfig;
        
        const {sessionId, createdAt} = this.state;
        const { user:{uid} } = this.props;

        const timestamp = new Date().getTime()/1000;

        await axios({
            method: 'POST',
            url: `${cloudFunctionHost}/destroySession`,
            data: {
                sessionId,
                event: 'streamDestroyed',
                stream: {
                    createdAt,
                },
                timestamp,
                userId: uid,
            }
        });

        this.setState({ startStream: false })
    }

    toggleSpeaker = () => {
        const { otSubscriber } = this;
        if (otSubscriber) {
            const { current } = otSubscriber;
            if (current) {
                const subscriber = current.getSubscriber();
                if (subscriber) {
                    const { speaker } = this.state;
                    subscriber.subscribeToAudio(!speaker);
                    this.setState({
                        speaker: !speaker
                    });
                }
            }
        }
    }

    getSecondsFromSessions(sessions) {
        let netDuration = 0;
        (sessions || []).forEach(st => {
            netDuration += (st.endedAt - st.createdAt);
        });
        return netDuration;
    }

    getMinutesfromSessions = (sessions) => {
        const seconds = this.getSecondsFromSessions(sessions);
        return this.getMinutesfromSeocnds(seconds);
    }

    getMinutesfromSeocnds = (seconds) => {
        if (seconds === 0) {
            return 0;
        }
        return Math.round(seconds / 60);
    }

    componentDidMount = async () => {
        this.mounted = true;
        const { user } = this.props;
        const { full_name, realtor_name, lender_name, builder_name } = user;
        
        this.setState({
            userName: full_name || realtor_name || lender_name || builder_name,
            bookingId: localStorage['bookingId'] || '',
            cameraPosition: localStorage['cameraPosition'] || 'environment'
        });

        if (localStorage['cameraPosition']) {
            this.startSession({ id: localStorage['bookingId'] });
            delete localStorage['cameraPosition'];
            delete localStorage['bookingId'];
        }
    }

    componentWillUnmount = () => {
        this.mounted = false;
    }

    startSession = async booking => {
        const db = firebase.firestore();
        this.setState({
            busy: true
        });

        let bookingDetails = await db.collection('liveVisitBookings').doc(booking.id).get();
        bookingDetails = bookingDetails.data();

        getCount(booking.id, (count) => {
            this.setState({
                bookingLiveCount: count
            });
        });

        const createdAt = new Date().getTime()/1000;

        if (bookingDetails.liveStreamURL) {
            let sessionData = atob(bookingDetails.token.substring(4));
            sessionData = sessionData.split('&');
            let expiry = sessionData.filter(s => s.indexOf('expire_time') === 0);
            if (expiry.length) {
                expiry = parseInt(expiry[0].substring(12) + '000');
                let url = `${firebaseConfig.host}/live-visit/${booking.id}/${bookingDetails.sessionId}/${bookingDetails.token}`;
                if (expiry > new Date().getTime()) {            
                    this.setState({
                        createdAt,
                        sessionId: bookingDetails.sessionId,
                        token: bookingDetails.token,
                        startStream: bookingDetails.startStream,
                        busy: false,
                        pageUrl: bookingDetails.liveStreamURL,
                        liveStreamURL: bookingDetails.liveStreamURL,
                        bookingId: booking.id,
                        mystreamURL: url,
                    });
                    return;
                }
            }
        }

        opentok.createSession((err, session) => {
            if (err) return console.log(err);
            this.setState({ busy: true });
            let token = opentok.generateToken(session.sessionId);
            let url = `${firebaseConfig.host}/live-visit/${booking.id}/${session.sessionId}/${token}`;

            this.setState({
                createdAt,
                sessionId: session.sessionId,
                token,
                startStream: true,
                busy: false,
                pageUrl: url,
                liveStreamURL: url,
                mystreamURL: url,
                bookingId: booking.id
            });

            db.collection('liveVisitBookings').doc(booking.id).update({
                cameraPosition: this.state.cameraPosition,
                sessionId: session.sessionId,
                token: token,
                startStream: true,
                liveStreamURL: url,
                bookingId: booking.id,
                pageUrl: url
            });

            getCount(booking.id, (count) => {
                this.setState({
                    bookingLiveCount: count
                })
            });

            this.sendStreamURL(url, booking);
        });
    }

    completeVisit = async (status, booking) => {
        const db = firebase.firestore();
        delete localStorage['cameraPosition'];

        this.setState({
            busy: true,
            sessionId: '',
            token: '',
            startStream: false,
            cameraPosition: 'environment'
        });

        db.collection('liveVisitBookings').doc(booking.id).update({
            cameraPosition: '',
            sessionId: '',
            token: '',
            startStream: false,
            liveStreamURL: ''
        });

        db.collection("liveVisitBookings").doc(booking.id).update({ status }).then(async sellerRef => {
            this.setState({ busy: false });

            this.changeVisitStatus(status, booking);
            
            toast.success("visit has been marked as completed.", {
                position: toast.POSITION.TOP_RIGHT
            });

            this.setState({ busy: false });

            let seller = await db.collection('sellers').doc(booking.seller).get();
            let bookingCount = seller.data().liveBookings || 0;
            bookingCount = bookingCount - 1;
            bookingCount = (bookingCount < 0) ? 0 : bookingCount;
            db.collection('sellers').doc(booking.seller).update({ liveBookings: bookingCount }).then(seller => {
                console.log(seller);
                window.location.reload();
            }).catch(err => {
                console.log(err);
            });
        }).catch(error => {
            this.setState({ busy: false })
            console.error(error)
        })
    }

    changeVisitStatus = async (status, booking) => {
        const db = firebase.firestore();

        this.setState({
            busy: true
        })
        db.collection("liveVisitBookings").doc(booking.id).update({ currentAction: status, status }).then(sellerRef => {
            this.setState({ busy: false });
        }).catch(error => {
            this.setState({ busy: false })
            console.error(error)
        })
    }

    sendStreamURL = async (url, booking) => {
        let emailParams = {
            name: booking.name,
            email: booking.email,
            url
        };
        let body = ReactDOMServer.renderToStaticMarkup(JoinLiveVisit(emailParams));
        let subject = 'Live visit has been started. Please join ASAP.';
        let email = booking.email;

        this.setState({
            busy: true
        });
        await sendEmail({ subject, email, body });
        toast.success("Email to join has been sent successfully.", {
            position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
            busy: false
        })
    }

    sendStatusEmail = async (type, booking) => {
        let emailParams = {
            name: booking.name,
            email: booking.email
        };

        let body, subject = null;
        let email = booking.email;
        if (type === 'accept') {
            this.changeVisitStatus('Accept', booking);
            emailParams.body = 'Your invitation has been accepted. A representative will contact you shortly for instructions.';
            subject = 'The live visit booking request has been accepted.';
        }

        if (type === 'rechedule') {
            this.changeVisitStatus('Rescheduled', booking);
            subject = 'The live visit booking request has been rescheduled.';
            emailParams.body = 'Your invitation has been rescheduled. A representative will contact you shortly to reschedule.';
        }

        if (type === 'reject') {
            this.changeVisitStatus('Rejected', booking);
            let reason = window.prompt('What is the reason of rejection?');
            subject = 'The live visit booking request status.';
            emailParams.body = `Your invitation has been rejected due to the following reason. <br /><b>${reason}</b>`;
        }

        this.setState({
            busy: true
        })
        body = LiveBookingSuccess(emailParams);
        body = ReactDOMServer.renderToStaticMarkup(body);
        await sendEmail({ subject, email, body });

        toast.success("Email to join has been sent successfully.", {
            position: toast.POSITION.TOP_RIGHT
        });
        this.setState({
            busy: false
        });

        window.location.reload();
    }

    deleteBooking = async booking => {
        const db = firebase.firestore();
        await db.collection('liveVisitBookings').doc(booking.id).delete();
        delete localStorage['cameraPosition'];

        if (booking.status !== 'done') {
            let seller = await db.collection('sellers').doc(booking.seller).get();
            seller = seller.data();
            let liveBookings = 0;
            if (seller.liveBookings) liveBookings = (parseInt(seller.liveBookings) - 1);
            await db.collection('sellers').doc(booking.seller).update({
                liveBookings
            });
        }

        toast.success('Booking has been deleted successfully.', {
            position: toast.POSITION.TOP_RIGHT
        });

        window.location.reload();
    }

    copyStreamURL = () => {
        const { mystreamURL } = this.state;
        copy(mystreamURL, {
            debug: true
        });
        toast.success('URL has been copied to your clipboard successfully.', {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    render() {
        const { loading, error, bookings, mic, speaker } = this.state;
        const { streamingCredit, streamingTimeUsed } = this.props.user;
        const totalMinutes = this.getMinutesfromSeocnds(streamingCredit || 0);
        const usedMinutes = this.getMinutesfromSeocnds(streamingTimeUsed || 0);
        const canStartSession = usedMinutes < totalMinutes;
        let BookingsList;

        if (loading) {
            BookingsList =
                <table className="manage-table">
                    <tbody>
                        <tr className="responsive-table"><td><p>Loading</p></td></tr>
                    </tbody>
                </table>
        }
        else {
            if (error) {
                BookingsList = <table className="manage-table">
                    <tbody>
                        <tr className="responsive-table"><td><p>{error}</p></td></tr>
                    </tbody>
                </table>
            }
            else {
                BookingsList = <table style={{ fontSize: ' 13px', width: '100%' }} className="match-details live-bookings">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Date Time</th>
                            <th>Status</th>
                            <th>Minutes</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            bookings.map((b, i) => (
                                <tr key={i}>
                                    <td>{b.name}</td>
                                    <td>{b.phoneNo}</td>
                                    <td>{b.email}</td>
                                    <td>{moment(b.dateTime, 'MM-DD-YYYY HH:mm').format('MM-DD-YYYY hh:mm A')} {b.timeZone}</td>
                                    <td>{b.currentAction}</td>
                                    <td>{this.getMinutesfromSessions(b.sessionTimings)} minutes</td>
                                    <td>
                                        {
                                            (b.status !== 'done') ? <div>
                                                {
                                                    (b.status === 'new') ? <div>
                                                        <Button
                                                            className="btn btn-xs"
                                                            color="success"
                                                            disabled={this.state.busy}
                                                            onClick={() => this.sendStatusEmail('accept', b)}

                                                        >Accept</Button> &nbsp;
                                                            <Button
                                                            className="btn btn-xs"
                                                            color="warning"
                                                            disabled={this.state.busy}
                                                            onClick={() => this.sendStatusEmail('rechedule', b)}
                                                        >Reschedule</Button> &nbsp;
                                                            <Button
                                                            className="btn btn-xs"
                                                            color="danger"
                                                            disabled={this.state.busy}
                                                            onClick={() => this.sendStatusEmail('reject', b)}
                                                        >Reject</Button>
                                                    </div> : <div>
                                                            {canStartSession ? <Button
                                                                className="btn btn-xs"
                                                                color="success"
                                                                disabled={this.state.busy}
                                                                onClick={() => this.startSession(b)}
                                                            >Start Session</Button> : <Link style={{
                                                                color: '#fff',
                                                                backgroundColor: '#dc3545'
                                                            }} className="btn btn-xs" to="/liveStreamPackages">
                                                                    Renew
                                                            </Link>} &nbsp;
                                                            <Button
                                                                className="btn btn-xs"
                                                                color="success"
                                                                disabled={this.state.busy}
                                                                onClick={() => this.completeVisit('done', b)}
                                                            >Complete Visit</Button>
                                                        </div>
                                                }
                                            </div> : null
                                        }
                                        <Button
                                            className="btn btn-xs"
                                            color="danger"
                                            disabled={this.state.busy}
                                            onClick={() => this.deleteBooking(b)}
                                        >Delete</Button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table >
            }
        }

        let liveStream = null;
        if (this.state.startStream) {
            liveStream = <>
                <OTSession apiKey={this.state.apiKey} sessionId={this.state.sessionId} token={this.state.token}>
                    <div className="video-main">
                        <div className="blank-div"></div>
                        <OTStreams>
                            <OTSubscriber properties={
                                {
                                    showControls: false
                                }
                            } ref={this.otSubscriber} />
                        </OTStreams>

                        <OTPublisher
                            properties={{
                                showControls: false,
                                facingMode: this.state.cameraPosition,
                                mirror: false
                            }}
                            ref={this.otPublisher}
                            className="custom-video"
                        />



                        <div className="live-comment-setion">


                            <BookingComments bookingId={this.state.bookingId} name={this.state.userName} match={this.props.match} />

                            <div className="call-controls d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center flex-grow-1">
                                    <BookingSubmitComment bookingId={this.state.bookingId} name={this.state.userName} match={this.props.match} />
                                </div>
                                <div className="flex-fill justify-content-end">
                                    <ul className="d-flex justify-content-center align-items-center cntroll-call-btns">
                                        <li>
                                            <btn onClick={() => {
                                                let cameraPosition = this.state.cameraPosition;
                                                if (cameraPosition === 'user') cameraPosition = 'environment';
                                                else cameraPosition = 'user';

                                                localStorage['cameraPosition'] = cameraPosition;
                                                localStorage['bookingId'] = this.state.bookingId;
                                                this.setState({
                                                    cameraPosition
                                                });
                                                window.location.reload();
                                            }}>
                                                {speaker && <i className="fa fa-camera"></i>}
                                                {!speaker && <i className="fa fa-camera"></i>}
                                            </btn>
                                        </li>
                                        <li>
                                            <btn onClick={this.toggleSpeaker}>
                                                {speaker && <i className="fa fa-volume-up"></i>}
                                                {!speaker && <i className="fa fa-volume-off"></i>}
                                            </btn>
                                        </li>
                                        <li className="end-call-btn">
                                            <btn onClick={this.endDestroyStreamSession} >
                                                <i className="fa fa-phone"></i>
                                            </btn>
                                        </li>
                                        <li>
                                            <btn onClick={this.toggleMic}>
                                                {mic && <i className="fa fa-microphone"></i>}
                                                {!mic && <i className="fa fa-microphone-slash"></i>}
                                            </btn>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>

                    </div>
                </OTSession>

                <div className="video-controls">
                    <div>Live Users: <b>{this.state.bookingLiveCount}</b></div>
                    <button className="btn btn-xs btn-success" onClick={this.copyStreamURL}>Copy Stream URL</button>
                </div>
            </>
        }

        return (
            <div className="content-area5">
                <div className="dashboard-content">
                    <div className="dashboard-header clearfix">
                        <div className="row">
                            <div className="col-sm-12 col-md-4">
                                <h4>Live Bookings</h4>
                            </div>
                            <div style={{ textAlign: 'right', marginTop: "13px" }} className="col-sm-12 col-md-4">
                                <p>Used {usedMinutes} minutes out of {totalMinutes} minutes</p>
                            </div>
                            <div style={{ textAlign: 'right' }} className="col-sm-12 col-md-2">
                                <Link className="btn btn-theme btn-md" to="/liveStreamPackages">
                                    Renew
                                </Link>
                            </div>
                            <div style={{ textAlign: 'left' }} className="col-sm-12 col-md-2">
                                <AddLiveStreamAddon {...this.props} />
                            </div>

                        </div>
                    </div>
                    <div style={{ margin: '0 auto', padding: '20px', textAlign: 'center' }}>
                        {
                            this.state.startStream ? liveStream : null
                        }
                    </div>
                    <div className="dashboard-list">
                        <h3>Live Bookings</h3>
                        {BookingsList}
                    </div>
                </div>
            </div>
        )
    }
}

export default LiveBookings