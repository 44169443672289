import React, { Component } from "react";
import { Link } from "react-router-dom";
import House from "../../assets/icons/gallery/Pict.png";
import Person from "../../assets/icons/person.svg";
import Calendar from "../../assets/icons/calendar.svg";
import moment from "moment";

import firebase from "firebase/app";
import "firebase/firestore";

class LastAcceptedOffer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOffer: true,
    };
  }

  deleteFromList = async (id) => {
    const { user } = this.props;
    this.setState({
      showOffer: false,
    });
    const db = firebase.firestore();
    await db
      .collection("users")
      .doc(user.uid)
      .update({
        removedDashboardOffers: firebase.firestore.FieldValue.arrayUnion(id),
      });
  };

  render() {
    const { offer, bestMatch } = this.props;
    const { showOffer } = this.state;
    const sellers = this.props.sellers;
    let currentSeller = sellers.filter((s) => s.id === offer.seller);
    currentSeller =
      {
        id: currentSeller[0]?.id,
        ...currentSeller[0]?.data(),
      } || {};

    return (
      <div
        style={{
          display: showOffer ? "block" : "none",
        }}
      >
        <div
          className={
            bestMatch && bestMatch[0] && bestMatch[0].id === offer.id
              ? "best-match-properties-list-card"
              : "properties-list-card"
          }
        >
          {bestMatch && bestMatch[0] && bestMatch[0].id === offer.id && (
            <p className="best-match-tag">
              <i class="fa fa-heart"></i>BEST MATCH
            </p>
          )}
          <div className="properties-card-img">
            <img
              src={(offer.featured_image && offer.featured_image.url) || House}
              alt="House"
              style={{ width: "100%" }}
            />
          </div>
          <h3 className="sections-title">{offer.address}</h3>
          {offer.status === "Executed" ? (
            <>
            <span className="badge badge-success">
              <i className="fa fa-check"></i>Accepted
            </span>
            <span className="badge badge-success ml-2">
              <i className="fa fa-check"></i>Executed
            </span>
            </>
          ) : (
            <span className="badge badge-warning">
              <i className="fa fa-clock-o"></i>
              {offer.status}
            </span>
          )}
          <div className="accepted-detail">
            <ul>
              <li className="d-flex align-items-center">
                <img src={Person} alt="Person" />
                <p>Buyers Agent</p>
                <Link to="#" className="link">
                  {offer.agentName}
                </Link>
              </li>
              <li className="d-flex align-items-center">
                <img src={Calendar} alt="Calendar" />
                <p className="accepted-date">
                  Accepted on{" "}
                  {moment(offer.statusChangedAt * 1000).format("MMMM Do YYYY")}{" "}
                  ({moment(offer.statusChangedAt * 1000).fromNow()})
                </p>
              </li>
            </ul>
          </div>
          <div className="purchase-price">
            <h4>Purchase Price</h4>
            <p>
              {offer.purchasePrice
                ? "$" +
                  offer.purchasePrice
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : "N/A"}
            </p>
          </div>
          <div className="house-detail">
            <ul>
              <li className="d-flex align-items-center">
                <p>Bedrooms</p>
                <p>{currentSeller.bedrooms}</p>
              </li>
              <li className="d-flex align-items-center">
                <p>Bathrooms</p>
                <p>{currentSeller.bathrooms}</p>
              </li>
              <li className="d-flex align-items-center">
                <p>Sq ft</p>
                <p>{currentSeller.sqft} sqft</p>
              </li>
              <li className="d-flex align-items-center">
                <p>Year Built</p>
                <p>
                  {currentSeller.year_built
                    ? `${currentSeller.year_built}/Preowned`
                    : "N/A"}
                </p>
              </li>
            </ul>
            <button type="button" className="btn btn-theme btn btn-secondary ">
              <Link
                to={`/offers/${this.props.offer.id}`}
                type="button"
                className="link webkit-appearance-auto"
              >
                Review
              </Link>
            </button>
            <div
              onClick={() => this.deleteFromList(offer.id)}
              className="link delete-from-list"
            >
              Delete from list
            </div>
            <a
              href={`/sellers/${currentSeller.id}?trends=1`}
              target="_blank"
              className="text-center link text-black"
            >
              View OfferTrends
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default LastAcceptedOffer;
