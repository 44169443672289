import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import { firebaseConfig } from "./../Firebase/firebase";
const { cloudFunctionHost } = firebaseConfig;

class GoogleAutoComplete extends Component {
  state = {
    value: [],
  };

  onChange = async (value) => {
    const { onLocationChange, radius } = this.props;
    this.setState({ value });

    let location = {
      name: value.label,
    };
    if (!value.justValue) {
      const response = await fetch(
        `${cloudFunctionHost}/getLocationFromAddress?search=${value.label}`
      );
      console.log(response, "response");
      location = await response.json();
      location.radius = radius;
    }
    onLocationChange(location);
  };

  get = async (value, callback) => {
    const { addSearch } = this.props;
    try {
      const response = await fetch(
        `${cloudFunctionHost}/searchPlaces?search=${value}`
      );
      let results = await response.json();
      results = results.map((p) => {
        return {
          label: p.name,
          value: p.id,
        };
      });
      if (addSearch) {
        results = [
          {
            label: value,
            value: undefined,
            justValue: true,
          },
          ...results,
        ];
      }
      callback(results);
    } catch (error) {
      console.log({ error });
    }
  };

  render() {
    const { location } = this.props;
    let value = {};
    if (location) {
      value = {
        label: location.name || "",
        value: "",
      };
    }
    return (
      <AsyncSelect
        className="react-select-container"
        value={value}
        placeholder="Search"
        cacheOptions
        loadOptions={this.get}
        defaultOptions
        onChange={this.onChange}
      />
    );
  }
}

export default GoogleAutoComplete;
