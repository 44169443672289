import React, { useState, useEffect } from "react";
import LocationDetailsChart from "../../Charts/LocationDetailsChart";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

import styles from "./locationSummary.module.css";
import "../../../assets/css/analytics-mobile.css";
import arrowLeft from "../../../assets/icons/arrow-left.png";
import arrowRight from "../../../assets/icons/arrow-right.png";

function getCoordinatesByAddress(address) {
  return new Promise((resolve, reject) => {
    const apiKey = "AIzaSyDFBfVT1MqmWsNXGDrPtF_jK8wnQT1j6Ls";
    const geocodingUrl = `http://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    fetch(geocodingUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          const location = data.results[0].geometry.location;
          const latitude = location.lat;
          const longitude = location.lng;
          resolve({ latitude, longitude });
        } else {
          reject(new Error(data.status));
        }
      })
      .catch((error) => {
        console.error("Error:", error.message);
        reject(error);
      });
  });
}

const LocationDetails = ({ locations, google, locationsOffers, mobile }) => {
  const [markers, setMarkers] = useState([]);
  const [error, setError] = useState(null);
  const [showDetails, setShowDetails] = useState(false);

  const toggleShowDetails = () => {
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    const fetchCoordinates = async () => {
      try {
        const markerPromises = Object.keys(locations).map(async (address) => {
          const { location, state } = getAddressParts(address);
          const position = await getCoordinatesByAddress(address);
          return { address, location, state, position };
        });

        const resolvedMarkers = await Promise.all(markerPromises);
        setMarkers(resolvedMarkers);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchCoordinates();
  }, [locations]);

  const getAddressParts = (address) => {
    const parts = address.split(",");
    const location = parts[0].trim();
    let state = "";

    if (parts.length > 1) {
      const stateAndZip = parts[parts.length - 1].trim();
      const stateParts = stateAndZip.split(" ");
      if (stateParts.length > 1) {
        state = stateParts.slice(0, -1).join(" ");
      }
    }

    return { location, state };
  };

  return (
    <>
      {mobile ? (
        <>
          {" "}
          <button
            style={{
              marginBottom: "15px !important",
            }}
            className={`mobile-view-details-btn ${
              showDetails ? "float-left" : "float-right"
            }`}
            onClick={toggleShowDetails}
          >
            {!showDetails ? (
              <>
                View Details
                <img src={arrowRight} className="ml-2 mobile-icons" />
              </>
            ) : (
              <>
                <img src={arrowLeft} className="mr-2 mobile-icons" />
                View Map
              </>
            )}
          </button>
          <div
            className="mapWrapper"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#ffffff",
            }}
          >
            {!showDetails ? (
              <div
                style={{
                  width: "100%",
                  height: "390px",
                  border: "1px solid #999",
                  borderRadius: "4px",
                  marginTop: "15px !important",
                }}
              >
                <Map
                  google={google}
                  zoom={8}
                  initialCenter={{ lat: 32.7767, lng: -96.797 }} // Set your desired initial center coordinates
                  style={{ width: "100%", height: "390px" }}
                  containerStyle={{
                    position: "relative",
                    width: "100%",
                    height: "390px",
                  }}
                >
                  {locationsOffers &&
                    Object.values(locationsOffers).length &&
                    Object.values(locationsOffers).map((listing, index) => {
                      return (
                        <Marker
                          title={listing.address}
                          position={{
                            lat: Number(listing.latitude?.toFixed(6)),
                            lng: Number(listing.longitude?.toFixed(6)),
                          }}
                          icon={{
                            url: "https://i.ibb.co/8srdHmj/dot.png",
                            anchor: new window.google.maps.Point(12, 12),
                            scaledSize: new window.google.maps.Size(24, 24),
                          }}
                        />
                      );
                    })}
                </Map>
              </div>
            ) : (
              <div className={`mob-locationDetailsChart`}>
                <LocationDetailsChart locations={locations} mobile={mobile} />
              </div>
            )}
          </div>
        </>
      ) : (
        <div
          className="mapWrapper"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "#ffffff",
          }}
        >
          <div
            style={{
              width: "49%",
              height: "390px",
              border: "1px solid #999",
              borderRadius: "4px",
            }}
          >
            <Map
              google={google}
              zoom={8}
              initialCenter={{ lat: 32.7767, lng: -96.797 }} // Set your desired initial center coordinates
              style={{ width: "100%", height: "390px" }}
              containerStyle={{
                position: "relative",
                width: "100%",
                height: "390px",
              }}
            >
              {locationsOffers &&
                Object.values(locationsOffers).length &&
                Object.values(locationsOffers).map((listing, index) => {
                  return (
                    <Marker
                      title={listing.address}
                      position={{
                        lat: Number(listing.latitude?.toFixed(6)),
                        lng: Number(listing.longitude?.toFixed(6)),
                      }}
                      icon={{
                        url: "https://i.ibb.co/8srdHmj/dot.png",
                        anchor: new window.google.maps.Point(12, 12),
                        scaledSize: new window.google.maps.Size(24, 24),
                      }}
                    />
                  );
                })}
            </Map>
          </div>
          <div className={styles.locationDetailsChart}>
            <LocationDetailsChart locations={locations} />
          </div>
        </div>
      )}
    </>
  );
};

export default LocationDetails;

// export default GoogleApiWrapper({
//   apiKey: 'AIzaSyDFBfVT1MqmWsNXGDrPtF_jK8wnQT1j6Ls' // Replace with your Google Maps API key
// })(LocationDetails);

// export default LocationDetails
