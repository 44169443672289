import React from "react";
import { Container, Row, Col, Input } from "reactstrap";
import RealtorProfile from "./RealtorProfile";
import Helpbutton from "components/HelpButton";
import firebase from "firebase/app";
import "firebase/firestore";
import 'firebase/functions'
import { firebaseConfig } from './../components/Firebase/firebase';

import copy from 'copy-to-clipboard';
import { toast } from "react-toastify";

export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      readyToLoad: false,
      user: this.props.user,
      selectedRole: this.props.user.role
    };
  }

  copyToClipboard = (text) => {
    
    copy(text);
    toast.success('Code has been copied to your clipboard successfully.', {
        position: toast.POSITION.TOP_RIGHT
    });
  }

  render() {
    const { user } = this.state;
    return <div className="content-area5">
      <div className="dashboard-content activities-dashboard">
        <Container>
          <Row>
            <Col>
              <h5 className="history-of-changes all-users-heading">Embedable Code</h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>You need to copy the following code and paste in the head or at the bottom of the HTML page.</p>
              <div className="code-container" onClick={() => this.copyToClipboard(`<script src="https://storage.googleapis.com/homematchx-prod.appspot.com/cdn/offer-btn/main.js" defer></script>`)}>
                <Input disabled value={`<script src="https://storage.googleapis.com/homematchx-prod.appspot.com/cdn/offer-btn/main.js" defer></script>`} />
                <i className="fa fa-copy embedable-code-icon"></i>
              </div>
            </Col>
          </Row>

          <br />

          <Row>
            <Col>
              <p>You need to paste this code on the page where you want to show Make An Offer button. And you need to add mls ID in the attribute in your website.</p>
              <div className="code-container" onClick={() => this.copyToClipboard(`<button data-mls="ENTER_THE_MLS_ID_HERE" data-key="${user.uid}">Button</button>`)}>
                <Input disabled value={`<button data-mls="ENTER_THE_MLS_ID_HERE" data-key="${user.uid}">Button</button>`} />
                <i className="fa fa-copy embedable-code-icon"></i>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  }
}