import React from "react";
import LocationCard from "./LocationCard";

import styles from "./locationSummary.module.css";

const LocationSummary = ({ locations, allOffers, mobile }) => {
  const data = Object.entries(locations).sort((a, b) => b[1] - a[1]);
  const citiesAndStates = data.map((addresses) => {
    return addresses.map((address) => {
      if (typeof address === "string") {
        const addressComponents = address?.split(",");
        const city = addressComponents[1].trim();
        const state = addressComponents[2].trim().split(" ")[0];
        return city ? `${city}, ${state}` : state;
      } else {
        return address;
      }
    });
  });


  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        background: "#ffffff",
        minHeight: "300px",
        position: "relative",
      }}
    >
      <h4 className={`d-none d-md-block ${styles.title}`}>Places with the highest number of offers</h4>
      <h6 className={`d-block d-md-none ${styles.title}`}>Places with the highest number of offers</h6>
      {!citiesAndStates?.length ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <span>There is no data...</span>
        </div>
      ) : (
        <>
          {!mobile ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                columnGap: "16px",
                marginTop: "20px",
              }}
            >
              {citiesAndStates.slice(0, 4).map(([key, value]) => (
                <LocationCard
                  mobile={mobile}
                  value={
                    allOffers > 0 ? Math.round((value * 100) / allOffers) : 0
                  }
                  total={allOffers}
                  color="#109CF1"
                  title={key}
                  number={value}
                />
              ))}

              {/* <LocationCard value={31.9} total={100} color={'#109CF1'} title={'Jacksonville, FL'} number={15} />
          <LocationCard value={14.9} total={100} color={'#109CF1'} title={'Miami, FL'} number={7} />
          <LocationCard value={12.8} total={100} color={'#109CF1'} title={'Tampa, FL'} number={6} />
          <LocationCard value={10.6} total={100} color={'#109CF1'} title={'Orlando, FL'} number={12} /> */}
            </div>
          ) : (
            <div className="row">
              {citiesAndStates.slice(0, 4).map(([key, value]) => (
                <LocationCard
                  mobile={mobile}
                  value={
                    allOffers > 0 ? Math.round((value * 100) / allOffers) : 0
                  }
                  total={allOffers}
                  color="#109CF1"
                  title={key}
                  number={value}
                />
              ))}

              {/* <LocationCard value={31.9} total={100} color={'#109CF1'} title={'Jacksonville, FL'} number={15} />
          <LocationCard value={14.9} total={100} color={'#109CF1'} title={'Miami, FL'} number={7} />
          <LocationCard value={12.8} total={100} color={'#109CF1'} title={'Tampa, FL'} number={6} />
          <LocationCard value={10.6} total={100} color={'#109CF1'} title={'Orlando, FL'} number={12} /> */}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default LocationSummary;
