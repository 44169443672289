import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { customizeLabels, drawCustomBorderRadius, drawWhiteLine } from 'utils/Functions';

import styles from './index.module.css';

export default function RatioDetails({ brockerageInfo, legendFilter }) {
  const [loader, setLoader] = useState(false);

  const getChartData = () => {
    const categories = [];
    const totalSumArrayOffers = [];
    const totalSumArrayHomes = [];

    const dataKeys = Object.keys(brockerageInfo);

    for (let key of dataKeys) {
      if(key != 'You') {
        categories.push(key);
       !legendFilter.includes("Average offers price") ? totalSumArrayOffers.push(
           Math.round(
              brockerageInfo[key].totalSum / brockerageInfo[key].offersCount
           )
        ) : totalSumArrayOffers.push(0);
        !legendFilter.includes("Average homes price") ? totalSumArrayHomes.push(
           Math.round(
              brockerageInfo[key].actual_price / brockerageInfo[key].offersCount
           )
        ) : totalSumArrayHomes.push(0);
      }
    }

    return {
      categories,
      series: [
        {
          name: 'Average homes price',
          data: totalSumArrayHomes,
          color: '#28A745'
        },
        {
          name: 'Average offers price',
          data: totalSumArrayOffers,
          color: '#109CF1'
        }
      ]
    };
  };
  const dataValues = getChartData();

  const drawCustomLabels = (chart) => {
    drawWhiteLine(chart)
    const ctx = chart.ctx;

    chart.data.datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      const elements = meta.data;
      if (!meta.hidden)
      elements.forEach((element, elementIndex) => {
        const maxValue = Math.max(...dataset.data);
        if ((maxValue / dataset.data[elementIndex] <= 40)) {
          drawCustomBorderRadius(element, ctx, true);
        }
        });
    });
  };
  const plugin = {
    id: 'increase-legend-spacing',
    beforeInit(chart) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 35;
      };
    },
    afterDatasetsDraw: (chart) => drawCustomLabels(chart)
  };

  const categoriesLabels = useMemo(() => customizeLabels(dataValues.categories), [dataValues.categories]);

  const data = {
    labels: categoriesLabels,
    datasets: [
      {
        label: dataValues?.series[0]?.name,
        data: dataValues.series[0].data.map((el) => (!el ? 0 : el)),
        backgroundColor: '#28A745',
        categoryPercentage: 0.5,
        // barPercentage: 0.6,
        barThickness: 12,
      },
      {
        label: "",
        data: [0],
        backgroundColor: "#fff",
        categoryPercentage: 0.5,
        barPercentage: 0.6,
        barThickness: 4,
      },
      {
        label: dataValues?.series[1]?.name,
        data: dataValues.series[1].data.map((el) => (!el ? 0 : el)),
        backgroundColor: '#109CF1',
        categoryPercentage: 0.5,
        // barPercentage: 0.6,
        barThickness: 12,
      }
    ]
  };

  useEffect(() => {
    setLoader(false);
    setTimeout(() => {
      setLoader(true);
    }, 200);
  }, [brockerageInfo]);

  return (
    <div
      style={{
        width: `${dataValues.series[0].data?.length * 100 > 950 ? dataValues.series[0].data?.length * 100 : 950}px`,
        height: '450px',
        position: 'relative'
      }}
      className={styles.test}
    >
      {(dataValues?.categories?.length === 0) ? <div style={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          left: 0
        }}>

          <span>
            There is no data...
          </span>
        </div> :
        loader ? <Bar
          height="0"
          options={{
            interaction: {
              mode: 'index',
              intersect: false
            },
            scales: {
              ticks: {
                backdropPaddingY: 0.1,
                backdropPaddingX: 0.1,
                precision: 0.1
              },
              xAxes: [
                {
                  gridLines: {
                    display: false
                  },
                  ticks: {
                    beginAtZero: false,
                    backdropPaddingX: 0.1,
                    backdropPaddingY: 0.1,
                    maxRotation: 0,
                    showLabelBackdrop: true,
                    mirror: true,
                    fontSize: 12,
                    suggestedMax: 2,
                    padding: 15
                  }
                }
              ],
              yAxes: [
                {
                  gridLines: {
                    borderDash: [5, 5],
                    lineWidth: 2,
                    borderDashOffset: 4,
                    color: 'rgba(235, 235, 235, 1)',
                    zeroLineColor: 'rgba(235, 235, 235, 1)',
                    zeroLineWidth: 2,
                    drawTicks: false,
                    tickMarkLength: 12
                  },
                  ticks: {
                    fontFamily: 'Raleway',
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    callback: (value) => {
                      if (value === 0) {
                        return value;
                      } else if (value < 1000) {
                        return value + "K";
                      } else if (value >= 1000 && value < 1000000) {
                        return (value / 1000).toFixed(0) + "K";
                      } else if (value >= 1000000 && value < 1000000000) {
                        return (value / 1000000).toFixed(0) + "M";
                      } else if (value >= 1000000000 && value < 1000000000000) {
                        return (value / 1000000000).toFixed(0) + "B";
                      } else if (value >= 1000000000000) {
                        return (value / 1000000000000).toFixed(2) + "T";
                      }
                    },
                    backdropPaddingX: 0.1,
                    backdropPaddingY: 0.1,
                    padding: 15
                  }
                }
              ]
            },
            responsive: true,
            legend: {
              display: false,
            },
            title: {
              display: true
            }
          }} data={data} plugins={[plugin]} /> :
          <img
            src="/img/loader.gif"
            style={{
              position: "absolute",
              transform: "translate(1210%, 420%)",
            }}
          />}
    </div>

  );
}
