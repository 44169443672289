import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import Alert from "components/Alert";
import "firebase/analytics";
import StepWizard from "react-step-wizard";
import Signup from "./Signup";
import Subscription from "./Subscription";
import SubscriptionForm from "./SubscriptionForm";

function StepWizards(props) {
  const [step, setStep] = useState(1);
  const [customerId, setCustomerId] = useState(null);

  return (
    <React.Fragment>
      <div
        style={{
          backgroundImage: "url(/images/PIVVtxk342w.jpg)",
        }}
        className="contact-section overview-bgi"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className={
                  step.activeStep === 2
                    ? `pricing-from-content-box form-content-box`
                    : step.activeStep === 3
                    ? `pricing-payment-form-content-box form-content-box`
                    : `form-content-box`
                }
              >
                <div className="details">
                  <div className="text-center">
                    <Link to="/">
                      <img
                        src="/img/logos/black-logo.png"
                        alt="black-logo"
                        className="cm-logo"
                      />
                    </Link>
                    <h3>
                      {step.activeStep === 2
                        ? "Select a plan"
                        : step.activeStep === 3
                        ? "Enter your payment details"
                        : "Create an account"}
                    </h3>
                  </div>
                  <StepWizard onStepChange={(step) => setStep(step)}>
                    <Signup setCustomerId={setCustomerId} />
                    <Subscription />
                    <SubscriptionForm
                      user={props.user}
                      customerId={customerId}
                      userId={props.user?.uid}
                    />
                  </StepWizard>
                </div>
                {step === 1 || step.activeStep === 1 ? (
                  <div className="footer">
                    <span>
                      Already a member?&nbsp;
                      <Link to="login">Login here</Link>
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default StepWizards;
