import React, { Component } from "react";
import { Link } from "react-router-dom";
import Alert from "components/Alert";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import firebase from "firebase";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      inputs: {
        email: "",
        password: "",
        BCRWW: "",
        ZXZLM: "",
      },
      formsSubmitted: {},
      loading: false,
    };
  }

  handleLoginSubmit(e) {
    e.preventDefault();

    const {email, password } = this.state.inputs;
    this.login(() => this.props.functions.login(email, password));
  }

  handleChange(e, inputId) {
    const { inputs } = this.state;
    inputs[inputId] = e.target.value;
    this.setState({ inputs });
  }

  loginWithProvider(providerName) {
    this.login(() => this.props.functions.loginWithProvider(providerName));
  }

  login(loginFunction) {
    const context = this;

    this.setState({
      loading: true,
      error: null,
    });
    loginFunction()
      .then(() => {
        let redirect =
          localStorage.redirect ||
          JSON.stringify({ pathname: "/", search: "" });
        localStorage.removeItem("redirect");
        redirect = JSON.parse(redirect);
        redirect = redirect.pathname + redirect.search;
        window.location.href = redirect;
      })
      .catch(function (error) {
        context.setState({
          error: error,
          loading: false,
        });
      });
  }

  render() {
    let alert = null;

    const { error, loading } = this.state;

    if (error) {
      const { message } = error;
      alert = <Alert>{message}</Alert>;
    }

    return (
      <React.Fragment>
        <div
          style={{ backgroundImage: "url(/images/1ddol8rgUH8.jpg)" }}
          className="contact-section overview-bgi"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-content-box">
                  <div className="details">
                    <Link to="/">
                      <img
                        src="/img/logos/black-logo.png"
                        alt="black-logo"
                        className="cm-logo"
                      />
                    </Link>
                    <h3>Sign into your account</h3>
                    <p>Please login to open offer or create account</p>
                    <form
                      onSubmit={(e) => {
                        if (!this.state.formsSubmitted.login) {
                          this.handleLoginSubmit(e);
                        }
                      }}
                    >
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          className="input-text"
                          onChange={(e) => this.handleChange(e, "email")}
                          value={this.state.inputs["email"]}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          className="input-text"
                          onChange={(e) => this.handleChange(e, "password")}
                          value={this.state.inputs["password"]}
                        />
                      </div>
                      <div className="checkbox">
                        <div className="ez-checkbox pull-left">
                          <label>
                            <input
                              type="checkbox"
                              className="ez-hide"
                              onChange={(e) => this.handleChange(e, "BCRWW")}
                              value={this.state.inputs["BCRWW"]}
                            />
                            &nbsp; Remember me
                          </label>
                        </div>
                        <Link
                          className="link-not-important pull-right"
                          to="/passwordReset"
                        >
                          Forgot Password
                        </Link>
                        <div className="clearfix" />
                      </div>
                      <div className="form-group mb-0">
                        {alert}
                        <button
                          type="submit"
                          disabled={loading}
                          className="btn-md button-theme btn-block"
                        >
                          login
                        </button>
                      </div>
                    </form>
                    <ul className="social-list clearfix">
                      <li>
                        <button
                          className="facebook-bg"
                          onClick={() => this.loginWithProvider("facebook")}
                        >
                          <i className="fa fa-facebook"></i>
                        </button>
                      </li>
                      <li>
                        <button
                          className="google-bg"
                          onClick={() => this.loginWithProvider("google")}
                        >
                          <i className="fa fa-google-plus"></i>
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="footer">
                    <span>
                      Don't have an account?&nbsp;
                      <Link to="/signup">Register here</Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter">
  Launch demo modal
</button> */}

       
      </React.Fragment>
    );
  }
}

export default Login;
