import React, { Component, Fragment } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import firebase from "firebase/app";
import SelectFollowers from "./SelectFollowers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import AsyncSelect from "react-select/lib/Async";
import Select from "react-select";

class CreateGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      groupName: "",
      followers: [],
      isLoading: false,
      // selectedAgents: [],
      selectedAgents: this.props.selectedAgentsToCreate
        ? this.props.selectedAgentsToCreate.map((agent) => {
            return {
              data: {
                id: agent.id,
                agentName: agent.agentName || agent.buyerName,
                address: agent.address,
              },
              value: agent.id,
              label: agent.agentName || agent.buyerName,
              description: agent.address,
            };
          })
        : [],
      groups: [],
      showMyGroups: false,
    };
  }

  componentDidMount() {
    let groupsData = [];
    const db = firebase.firestore();
    db.collection("network")
      .doc(this.props.user?.uid)
      .collection("buyerAgentGroups")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = {
            id: doc.id,
            ...doc.data(),
          };
          groupsData.push(data);
        });
        if (groupsData.length > 0) {
          this.setState({
            groups: groupsData,
            showMyGroups: true,
          });
        }
      });
  }

  componentDidUpdate(prevProps, prevState) {
    const { agents, agentsToUpdate, updateGroupName } = this.props;
    if (
      prevProps.selectedAgentsToCreate !== this.props.selectedAgentsToCreate
    ) {
      this.setState({
        selectedAgents: this.props.selectedAgentsToCreate.map((agent) => {
          return {
            data: {
              id: agent.id,
              label: agent.agentName || agent.buyerName,
              description: agent.address,
            },
            value: agent.id,
            label: agent.agentName || agent.buyerName,
            description: agent.address,
          };
        }),
      });
    }
    if (prevProps.agentsToUpdate !== agentsToUpdate) {
      this.setState({
        selectedAgents: this.props.agentsToUpdate.map((agent) => {
          return {
            data: {
              id: agent.id,
              label: agent.agentName || agent.buyerName,
              description: agent.address,
            },
            value: agent.id,
            label: agent.agentName || agent.buyerName,
            description: agent.address,
          };
        }),
        groupName: updateGroupName,
      });
    }
  }

  toggle = () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  };

  onChange = async ({ target }) => {
    const { name: key, value } = target;
    this.setState({
      [key]: value,
    });
  };

  onCreate = () => {
    const { groupName, followers, selectedAgents } = this.state;
    const { user, comingFrom, agents, isUpdate, updateGroupId } = this.props;

    let toBeAdded = agents.filter((agent) => {
      return selectedAgents.some((item) => {
        return item.value === agent.id;
      });
    });

    if (comingFrom === "analytics") {
      if (isUpdate) {
        this.setState({
          isLoading: true,
        });
        const db = firebase.firestore();
        db.collection("network")
          .doc(user.uid)
          .collection("buyerAgentGroups")
          .doc(updateGroupId)
          .update({
            name: groupName,
            agents: toBeAdded.map((agent) => {
              return {
                id: agent.id,
                name: agent.agentName || agent.buyerName,
                email: agent.email || agent.buyerEmail,
                phoneNumber: agent.phoneNumber,
              };
            }),
          })
          .then(() => {
            this.setState({ isLoading: false });
            toast.success("Group Updated Successfully.");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isLoading: false });
            toast.error("Unable to update group, please try again!");
          });
      } else {
        const groupId = groupName.toLowerCase().replace(" ", "-");
        this.setState({
          isLoading: true,
        });
        const db = firebase.firestore();
        db.collection("network")
          .doc(user.uid)
          .collection("buyerAgentGroups")
          .doc(groupId)
          .set(
            {
              name: groupName,
              agents: toBeAdded.map((agent) => {
                return {
                  id: agent.id,
                  name: agent.agentName || agent.buyerName,
                  email: agent.email || agent.buyerEmail,
                  phoneNumber: agent.phoneNumber,
                };
              }),
            },
            { merge: true }
          )
          .then(() => {
            this.setState({ isLoading: false });
            toast.success("Group Created Successfully.");
          })
          .catch((error) => {
            console.log(error);
            this.setState({ isLoading: false });
            toast.error("Unable to create group, please try again!");
          });
      }
    } else {
      const groupId = groupName.toLowerCase().replace(" ", "-");
      this.setState({
        isLoading: true,
      });
      const db = firebase.firestore();
      db.collection("network")
        .doc(user.uid)
        .collection("groups")
        .doc(groupId)
        .set(
          {
            name: groupName,
            followers,
          },
          { merge: true }
        )
        .then(() => {
          this.setState({ isLoading: false });
          toast.success("Group Created Successfully.");
        })
        .catch((error) => {
          console.log(error);
          this.setState({ isLoading: false });
          toast.error("Unable to create group, please try again!");
        });
    }
  };

  selectOnChange = (selectedOption) => {
    this.setState({
      selectedAgents: selectedOption,
    });
  };

  customFilter = (option, inputValue) => {
    return option.data.description
      .toLowerCase()
      .includes(inputValue.toLowerCase());
  };

  render() {
    const {
      modal,
      isLoading,
      groupName,
      selectedAgents,
      showMyGroups,
      groups,
    } = this.state;
    const {
      comingFrom,
      agents,
      selectedAgentsToCreate,
      agentsToUpdate,
      isUpdate,
    } = this.props;

    const options = agents.map((agent) => {
      return {
        label: agent.agentName || agent.buyerName,
        value: agent.id,
        description: agent.address,
      };
    });

    return (
      <Fragment>
        {comingFrom === "analytics" ? (
          <>
            {isUpdate ? (
              <Button
                onClick={this.toggle}
                className="btn btn-theme btn btn-secondary share-btn-dashboard d-inline mt-0 mr-2"
              >
                Update Group
              </Button>
            ) : (
              <Button
                onClick={this.toggle}
                className="follow-my-listing-btn mr-2"
              >
                <i className="fa fa-plus mr-2" aria-hidden="true"></i>
                Create Group{" "}
                {comingFrom === "analytics" && selectedAgentsToCreate?.length
                  ? `(${selectedAgentsToCreate?.length})`
                  : ""}
              </Button>
            )}
          </>
        ) : (
          <Button onClick={this.toggle} className="btn btn-theme btn-md">
            Create Group{" "}
          </Button>
        )}

        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className="modal-danger modal-lg"
        >
          <ModalHeader toggle={this.toggle}>Create Group</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for="groupName">Group Name</Label>
                <Input
                  type="text"
                  name="groupName"
                  id="groupName"
                  onChange={this.onChange}
                  value={groupName}
                />
              </FormGroup>
              <FormGroup>
                {comingFrom === "analytics" ? (
                  <>
                    <Label for="groupName">Select Agents</Label>
                    <Select
                      className="react-select-groups"
                      value={selectedAgents}
                      isMulti
                      placeholder="Name, Address, City, State, Zip"
                      cacheOptions
                      options={options.map((option) => ({
                        ...option,
                        data: option,
                      }))}
                      defaultOptions
                      filterOption={this.customFilter}
                      onChange={this.selectOnChange}
                    />
                  </>
                ) : (
                  <>
                    <Label for="groupName">Followers & Following</Label>
                    <SelectFollowers
                      {...this.props}
                      onChange={(followers) => {
                        this.setState({
                          followers: (followers || []).map((f) => f.value),
                        });
                      }}
                    />
                  </>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={this.onCreate}
              disabled={isLoading}
            >
              {" "}
              {isLoading
                ? `${
                    comingFrom === "analytics" && isUpdate
                      ? "Updating..."
                      : "Creating..."
                  }`
                : `${
                    comingFrom === "analytics" && isUpdate ? "Update" : "Create"
                  }`}
            </Button>{" "}
            <Button color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}

export default CreateGroup;
