import React from "react";
import DoughnutLocationChart from "../../Charts/DoughnutLocationChart";

const LocationCard = ({ value, total, color, title, number, mobile }) => {
  return (
    <>
      {!mobile ? (
        <div
          style={{
            width: "24%",
            background: "#FFFFFF",
            border: "1px solid #EBEBEB",
            borderRadius: "4px",
            padding: "20px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                color: "#727272",
                fontSize: "12px",
                fontFamily: "Raleway",
                fontWeight: 500,
              }}
            >
              {title}
            </span>
            <h4
              style={{
                color: "#222222",
                fontFamily: "Poppins",
                fontWeight: 600,
                marginTop: "5px",
              }}
            >
              {number}
            </h4>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DoughnutLocationChart
                value={value}
                total={total}
                color={color}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="col-6 mt-2 p-0 m-0 px-1">
        <div
          className="col-12"
          style={{
            background: "#FFFFFF",
            border: "1px solid #EBEBEB",
            borderRadius: "4px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span
              style={{
                color: "#727272",
                fontSize: "12px",
                fontFamily: "Raleway",
                fontWeight: 500,
              }}
            >
              {title}
            </span>
            <h4
              style={{
                color: "#222222",
                fontFamily: "Poppins",
                fontWeight: 600,
                marginTop: "5px",
              }}
            >
              {number}
            </h4>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DoughnutLocationChart
                value={value}
                total={total}
                color={color}
              />
            </div>
          </div>
        </div>
        </div>
      )}
    </>
  );
};

export default LocationCard;
