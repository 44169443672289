import React from "react";
import { Container } from "reactstrap";
import "firebase/firestore";
import "../assets/css/analytics-page.css";
import sendToTitleEmail from "../templates/email/send-to-title-email-preview";

const SendToTitlePreview = (props) => {
  if (!localStorage["send-to-title-email-preview"])
    return (window.location.href = "/");

  const { inputs, seller, offerId } = JSON.parse(
    localStorage["send-to-title-email-preview"]
  );


  return (
    <div className="analytics-main">
      <Container className="dashboard-main">
        <div
          dangerouslySetInnerHTML={{
            __html: sendToTitleEmail(inputs, seller, offerId),
          }}
        />
      </Container>
    </div>
  );
};

export default SendToTitlePreview;
