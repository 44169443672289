import React, { useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import Alert from "components/Alert";
import "firebase/analytics";
import { set, update } from "lodash";
import mastercard from "../assets/icons/mastercard.png";
import nextPrev from "../assets/icons/next-prev.png";
import axios from "axios";
import qs from "qs";
import { toast } from "react-toastify";
import { get } from "underscore";

function MySubscriptions(props) {
  const cancelStripeSubscription = firebase
    .functions()
    .httpsCallable("cancelStripeSubscription");

  const [isSubscribed, setIsSubscribed] = useState(
    props.user?.subscriptionId ? true : false
  );
  const [cardEmail, setCardEmail] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expDate, setExpDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [cardName, setCardName] = useState("");
  const [customerId, setCustomerId] = useState(props.user?.stripeCustomerId);
  const [cardsHistory, setCardsHistory] = useState([]);

  const [errorMessage, setErrorMessage] = useState({
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [isUpdatingCard, setIsUpdatingCard] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [cardData, setCardData] = useState({});
  const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState("");
  const [activePlan, setActivePlan] = useState(
    props.user?.subscriptionId && subscriptionStatus === "active" ? 2 : 1
  );

  useEffect(async () => {
    console.log(props.user);
    // const db = firebase.firestore();
    // db.collection("users")
    //   .doc(props.user?.uid || props.user?.id)
    //   .update({
    //     subscriptionId: firebase.firestore.FieldValue.delete(),
    //     subscriptionEndDate: firebase.firestore.FieldValue.delete(),
    //     subscriptionStatus: firebase.firestore.FieldValue.delete(),
    //     stripeCustomerId: firebase.firestore.FieldValue.delete(),
    //     subscriptionHistory: firebase.firestore.FieldValue.delete(),
    //   });

    const getSubscriptionDetails = firebase
      .functions()
      .httpsCallable("getSubscriptionDetails");
    if (props.user?.subscriptionId) {
      getSubscriptionDetails({
        subscriptionId: props.user?.subscriptionId,
      }).then((res) => {
        setCardData(res.data.card);
        setSubscriptionData(res.data.subscription);
        setSubscriptionStatus(res.data.subscription.status);
      });
    }

    if (props.user?.subscriptionHistory?.length) {
      for (let i = 0; i < props.user?.subscriptionHistory?.length; i++) {
        getSubscriptionDetails({
          subscriptionId: props.user?.subscriptionHistory[i]?.subscriptionId,
        }).then((res) => {
          setCardsHistory((prevState) => [...prevState, res.data.card]);
        });
      }
    }

    if (props.user?.subscriptionEndDate) {
      if (
        subscriptionStatus != "active" &&
        new Date(props.user?.subscriptionEndDate * 1000) < new Date(Date.now())
      ) {
        setIsSubscriptionExpired(true);
      }
    }
  }, []);

  const formatCardNumber = (input) => {
    const formattedInput = input.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let formattedNumber = "";
    for (let i = 0; i < formattedInput.length; i += 4) {
      formattedNumber += formattedInput.slice(i, i + 4) + " ";
    }
    return formattedNumber.trim();
  };

  const formatExpDate = (input) => {
    const formattedInput = input.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    if (formattedInput.length > 2) {
      return `${formattedInput.slice(0, 2)}/${formattedInput.slice(2)}`;
    }
    return formattedInput;
  };

  const handleSubsription = async () => {
    const db = firebase.firestore();
    const createStripeCustomerOnSignUp = firebase
      .functions()
      .httpsCallable("createStripeCustomerOnSignUp");

    const createStripeSubscription = firebase
      .functions()
      .httpsCallable("createStripeSubscription");

    let data = qs.stringify({
      "card[number]": cardNumber.replace(/\s/g, ""),
      "card[exp_month]": expDate.split("/")[0],
      "card[exp_year]": expDate.split("/")[1],
      "card[cvc]": cvv,
      // key: "pk_test_RO9Jp6dNdbOOLlxVx4sLOKB300KXRYY0gn",
      key: "pk_live_txUDcEIQu5Ijd8jMpMIiSjPd00QKj9yMpL",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.stripe.com/v1/tokens",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    if (!cardNumber) {
      return setErrorMessage({
        message: "Please enter card number",
        type: "cardNumber",
      });
    }
    if (!expDate) {
      return setErrorMessage({
        message: "Please enter card expiry date",
        type: "expDate",
      });
    }
    if (!cvv) {
      return setErrorMessage({
        message: "Please enter card cvv",
        type: "cvv",
      });
    }

    if (activePlan !== 2) {
      return toast.error("Please select a plan");
    }
    setLoading(true);

    if (!props.user?.stripeCustomerId) {
      createStripeCustomerOnSignUp({
        email: props.user?.email || props.user?.realtor_email,
      }).then((res) => {
        setCustomerId(res?.data?.customer.id);
        db.collection("users")
          .doc(props.user?.uid || props.user?.id)
          .update({
            stripeCustomerId: res?.data?.customer.id,
          })
          .then(() => {
            axios
              .request(config)
              .then((response) => {
                createStripeSubscription({
                  customerId: res?.data?.customer.id,
                  token: response.data.id,
                })
                  .then((result) => {
                    const db = firebase.firestore();
                    db.collection("users")
                      .doc(props.user?.uid || props.user?.id)
                      .update({
                        subscriptionId: result?.data?.subscription?.id,
                        subscriptionHistory:
                          firebase.firestore.FieldValue.arrayUnion({
                            subscriptionId: result?.data?.subscription?.id,
                            subscriptionStatus:
                              result?.data?.subscription?.status,
                            subscriptionPlanName:
                              result?.data?.subscription?.plan?.nickname,
                            subscriptionPlanAmount:
                              result?.data?.subscription?.plan?.amount,
                            subscriptionPlanCreated:
                              result?.data?.subscription?.plan?.created,
                            subscriptionEndDate:
                              result?.data?.subscription?.current_period_end,
                          }),
                        subscriptionEndDate:
                          result?.data?.subscription?.current_period_end,
                        subscriptionStatus: result?.data?.subscription?.status,
                      })
                      .then(() => {
                        setLoading(false);
                        toast.success("Subscribed successfully");
                        setTimeout(() => {
                          window.location.href = "/dashboard";
                        }, 2000);
                      })
                      .catch((error) => {
                        toast.error("Failed to subscribe");
                      });
                  })
                  .catch((error) => {
                    toast.error("Failed to subscribe");
                  });
              })
              .catch((error) => {
                toast.error("Invalid card details");
              });
          });
      });
    } else {
      axios
        .request(config)
        .then((response) => {
          createStripeSubscription({
            customerId: customerId,
            token: response.data.id,
          }).then((result) => {
            const db = firebase.firestore();
            db.collection("users")
              .doc(props.user?.uid || props.user?.id)
              .update({
                subscriptionId: result?.data?.subscription?.id,
                subscriptionHistory: firebase.firestore.FieldValue.arrayUnion({
                  subscriptionId: result?.data?.subscription?.id,
                  subscriptionStatus: result?.data?.subscription?.status,
                  subscriptionPlan: result?.data?.subscription?.plan?.id,
                  subscriptionPlanName:
                    result?.data?.subscription?.plan?.nickname,
                  subscriptionPlanAmount:
                    result?.data?.subscription?.plan?.amount,
                  subscriptionPlanInterval:
                    result?.data?.subscription?.plan?.interval,
                  subscriptionPlanIntervalCount:
                    result?.data?.subscription?.plan?.interval_count,
                  subscriptionPlanCurrency:
                    result?.data?.subscription?.plan?.currency,
                  subscriptionPlanTrialPeriodDays:
                    result?.data?.subscription?.plan?.trial_period_days,
                  subscriptionPlanCreated:
                    result?.data?.subscription?.plan?.created,
                }),
                subscriptionEndDate:
                  result?.data?.subscription?.current_period_end,
                subscriptionStatus: result?.data?.subscription?.status,
              })
              .then(() => {
                setLoading(false);
                toast.success("Subscribed successfully");
                setTimeout(() => {
                  window.location.href = "/my-subscriptions";
                }, 2000);
              })
              .catch((error) => {
                toast.error("Failed to subscribe");
              });
          });
        })
        .catch((error) => {
          toast.error("Invalid card details");
        });
    }
  };

  const updateCard = async () => {
    const db = firebase.firestore();
    const updateStripeSubscription = firebase
      .functions()
      .httpsCallable("updateStripeSubscription");

    let data = qs.stringify({
      "card[number]": cardNumber.replace(/\s/g, ""),
      "card[exp_month]": expDate.split("/")[0],
      "card[exp_year]": expDate.split("/")[1],
      "card[cvc]": cvv,
      key: "pk_test_RO9Jp6dNdbOOLlxVx4sLOKB300KXRYY0gn",
      // key: "pk_live_txUDcEIQu5Ijd8jMpMIiSjPd00QKj9yMpL",
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://api.stripe.com/v1/tokens",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    if (!cardNumber) {
      return setErrorMessage({
        message: "Please enter card number",
        type: "cardNumber",
      });
    }
    if (!expDate) {
      return setErrorMessage({
        message: "Please enter card expiry date",
        type: "expDate",
      });
    }
    if (!cvv) {
      return setErrorMessage({
        message: "Please enter card cvv",
        type: "cvv",
      });
    }
    setLoading(true);
    axios
      .request(config)
      .then((response) => {
        updateStripeSubscription({
          customerId: customerId,
          token: response.data.id,
          subscriptionId: props.user?.subscriptionId,
        }).then((result) => {
          console.log(result);
          toast.success("Card updated successfully");
          setLoading(false);
          setTimeout(() => {
            window.location.href = "/my-subscriptions";
          }, 2000);
        });
      })
      .catch((error) => {
        toast.error("Invalid card details");
      });
  };

  return (
    <div className="content-area5 dashboard-content">
      <div className="dashboard-header clearfix">
        <div className="row">
          <div className="col-sm-12 col-md-12">
            <h4>My Subscriptions</h4>
          </div>
        </div>
      </div>
      <div className="submit-address dashboard-list">
        <div className="row">
          <div className="col-12 col-md-6 col-xl-3">
            <div
              onClick={() => setActivePlan(1)}
              className={`${
                activePlan === 1
                  ? "pricing-card pricing-card-selected"
                  : "pricing-card"
              }`}
            >
              <div className="pricing-card-text">
                <div className="text-center">
                  <p className="pricing-card-green-text">
                    Free Plan. Try it out and build strong offer data on the
                    Automation Platform.
                  </p>
                  <h3>FREE</h3>
                </div>
                <ul>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>Monetize your
                    listing activity data on Homematchx (Keep 80% of revenue)
                  </li>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>Get paid
                    instantly
                  </li>
                  <li>
                    <i className="fa fa-times mr-2 times-icon"></i>Advertise
                    your global listing activity data across platforms
                  </li>
                  <li>
                    <i className="fa fa-times mr-2 times-icon"></i>Official
                    Sales Report
                  </li>
                  <li>
                    <i className="fa fa-times mr-2 times-icon"></i>Agent Support
                    (Standard)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-xl-3 mt-3 mt-md-0 ml-0 ml-xl-4">
            <div
              onClick={() => setActivePlan(2)}
              className={`${
                activePlan === 2
                  ? "pricing-card pricing-card-selected"
                  : "pricing-card"
              }`}
            >
              <div className="pricing-card-text">
                <div className="text-center">
                  <p className="pricing-card-green-text">
                    The intentional plan. Access unlimited Global Offer Data
                    across the platform.
                  </p>
                  <h3>
                    $14.99 <span className="pricing-year">/year</span>
                  </h3>
                </div>
                <ul>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>Monetize your
                    listing activity data on Homematchx (Keep 100% of revenue)
                  </li>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>Get paid
                    instantly
                  </li>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>Advertise
                    your global listing activity data across platforms
                  </li>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>Official
                    Sales Report
                  </li>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>Agent Support
                    (Standard)
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-1"></div>
          {isSubscribed && !isUpdatingCard && !isSubscriptionExpired ? (
            <div className="col-12 col-xl-4 mt-3 mt-xl-0">
              <div className="text-center">
                <h3 className="p-0 m-0 pb-3">Your Subscription</h3>
                <div className="mt-3">
                  <span className="font-weight-bold">
                    <i
                      className={`fa ${
                        cardData?.brand == "Visa"
                          ? "fa-cc-visa"
                          : cardData?.brand == "MasterCard"
                          ? "fa-cc-mastercard"
                          : "fa-credit-card"
                      }`}
                      style={{
                        color: "#1A1F71",
                      }}
                    ></i>
                  </span>{" "}
                  **** **** **** {cardData?.last4}
                  <i
                    className="fa fa-pencil ml-3"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsUpdatingCard(true);
                    }}
                  ></i>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-8">
                  <p className="font-weight-bold">Plan</p>
                  <p className="font-weight-bold">Status</p>
                  <p className="font-weight-bold">Next Payment</p>
                  <p className="font-weight-bold">Amount</p>
                </div>
                <div className="col-4">
                  <p>Premium</p>
                  <p>{subscriptionData?.status}</p>
                  <p>
                    {new Date(
                      subscriptionData?.current_period_end * 1000
                    ).toLocaleDateString()}
                  </p>
                  <p>${subscriptionData?.plan?.amount / 100}</p>
                </div>
              </div>
              <hr />
              {subscriptionData?.status == "active" && (
                <p className="my-4">
                  Your subscription will automatically renew on{" "}
                  {new Date(
                    subscriptionData?.current_period_end * 1000
                  ).toLocaleDateString()}
                  . You can cancel your subscription at any time.
                </p>
              )}
              {subscriptionData.status == "active" && (
                <div className="text-center">
                  <button
                    className="btn btn-outline-danger pricing-btn text-capitalize"
                    onClick={() => {
                      setLoading(true);
                      cancelStripeSubscription({
                        subscriptionId: props.user?.subscriptionId,
                      }).then((res) => {
                        if (res?.data?.subscription?.status == "canceled") {
                          toast.success("Subscription canceled successfully");
                          setTimeout(() => {
                            window.location.href = "/my-subscriptions";
                          }, 2000);
                          // });
                        } else {
                          toast.error("Failed to cancel subscription");
                        }
                      });
                    }}
                  >
                    {loading ? "Please Wait..." : "Cancel Subscription"}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <div className="col-12 col-xl-4 mt-3 mt-xl-0 p-0">
              <div className="text-center">
                <h3 className="p-0 m-0 pb-3">
                  {isUpdatingCard ? "Update Card" : "Subscribe"}
                </h3>
                <p className="mt-3">
                  {isUpdatingCard
                    ? "Please enter your new card details to update your card."
                    : "Please enter your card details to subscribe."}
                </p>
              </div>

              <div className="row mt-3 mx-auto">
                <div className="col-12 p-0 m-0">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    Email
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-1"
                    value={cardEmail}
                    onChange={(e) => setCardEmail(e.target.value)}
                  />
                </div>
                <div className="col-12 p-0 m-0 mt-3">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    Card Information
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-1 pl-5"
                    value={formatCardNumber(cardNumber)}
                    style={{
                      backgroundImage: `url(${mastercard})`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "left 0.5rem center",
                      backgroundSize: "contain",
                      paddingLeft: "55px !important",
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 19)
                        setCardNumber(e.target.value);
                    }}
                  />
                  {errorMessage.type == "cardNumber" && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                      className="mb-0"
                    >
                      {errorMessage.message}
                    </p>
                  )}
                </div>

                <div className="col-6 p-0 m-0 mt-3 pr-1">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    Expiry (MM/YY)
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-1"
                    value={formatExpDate(expDate)}
                    onChange={(e) => {
                      if (e.target.value.length <= 5)
                        setExpDate(e.target.value);
                    }}
                    placeholder="MM/YY"
                  />
                  {errorMessage.type == "expDate" && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                      className="mb-0"
                    >
                      {errorMessage.message}
                    </p>
                  )}
                </div>
                <div className="col-6 p-0 m-0 mt-3 pl-1">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    CVC
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-1"
                    value={cvv}
                    onChange={(e) => {
                      if (e.target.value.length <= 3) {
                        setCvv(e.target.value);
                      }
                    }}
                  />
                  {errorMessage.type == "cvv" && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        fontWeight: "600",
                      }}
                      className="mb-0"
                    >
                      {errorMessage.message}
                    </p>
                  )}
                </div>
                <div className="col-12 p-0 m-0 mt-3">
                  <p
                    className="mb-1"
                    style={{
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#000000",
                    }}
                  >
                    Name on card
                  </p>
                  <input
                    type="text"
                    className="w-100 thankme-textarea p-1"
                    value={cardName}
                    onChange={(e) => setCardName(e.target.value)}
                  />
                </div>
              </div>
              <div className="row mt-3 mx-auto">
                {isUpdatingCard && (
                  <button
                    className="btn btn-outline-danger pricing-btn text-capitalize mr-2"
                    onClick={() => {
                      setIsUpdatingCard(false);
                    }}
                  >
                    Cancel
                  </button>
                )}
                <button
                  className="btn btn-outline-danger pricing-btn text-capitalize"
                  onClick={() => {
                    if (isUpdatingCard) {
                      updateCard();
                    } else {
                      handleSubsription();
                    }
                  }}
                >
                  {loading
                    ? "Please Wait..."
                    : isUpdatingCard
                    ? "Update Card"
                    : "Subscribe"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MySubscriptions;
