const dummyOffer = {
  id: "jncM3QMXdArwphHOntRa",
  bedrooms: 3,
  status: "Not Accepted",
  buyerEmail: "test@gmail.com",
  phoneNumber: "123-123-1234",
  updateHistory: [
    "07/10/2023 Offer has been marked as verified.",
    "07/10/2023 Offer has been marked as not verified.",
    "07/11/2023 Offer has been marked as verified.",
    "07/11/2023 The seller agent has sent a counter offer.",
    "07/11/2023 The agent has Decline an offer.",
    "07/18/2023 Offer has been marked as not verified.",
    "07/18/2023 Offer has been marked as verified.",
    "07/19/2023 Offer has been marked as verified.",
  ],
  financingType: "Conventional",
  sender: "",
  licenseNo: "1111111",
  address: "7309 Elm Grove, Little Elm, TX 76227",
  featured_image: {
    url: "https://dvvjkgh94f2v6.cloudfront.net/d27f9895/425657114/83dcefb7.jpeg",
  },
  agentName: "Test Name",
  rightToTerminate: "Partial Waiver",
  saleOfOtherProperty: "No",
  documents: [
    {
      name: "7309_elm_grove_offer.pdf",
      path: "https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Foffers%2F1689011382_7309_elm_grove_offer.pdf?alt=media&token=865ffc6e-4f11-42ec-9b26-d2f217012119",
    },
    {
      name: "addendum_concerning_right_to_terminate_due_to_lenders_appraisal__21218.pdf",
      path: "https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Foffers%2F1689013781_addendum_concerning_right_to_terminate_due_to_lenders_appraisal__21218.pdf?alt=media&token=5d808deb-09ab-4b7a-84cc-97e8f8e05ad5",
    },
  ],
  leasebackDays: "14",
  optionFee: "350",
  possession: "Leaseback",
  notifiedBy: "Text",
  viewedAt: 1689013881,
  email: "test@dummy.com",
  closingDate: "2023-08-10",
  createdAt: 1689013857,
  downPayment: "103500",
  earnestMoney: "4350",
  brokerageName: "VP Realty Services",
  bathrooms: 2,
  viewed: true,
  buyer: "",
  comments:
    "Hi! This a Dummy Offer.",
  sqft: 0,
  purchasePrice: 435000,
  globalOfferDataAccess: true,
  numberOfDays: 31,
  offer_expire_date: "",
  titlePolicyExpense: "Seller",
  seller_id: "e567hngvces34f5gh78",
  verified: true,
  sellerEmail: "inkrealtygroup@gmail.com",
  actual_price: 449900,
  seller: "aa41e6c6bc205335a8cd7a87b511b4a8",
  statusChangedAt: 1689110782,
  search: [
    "inkrealtygroup@gmail.com",
    "inkrealtygroup@gmail.com",
    "shashi@zenappy.com",
  ],
  buyerName: "",
  counterOffers: [
    {
      sentBy: "Test Agent",
      offerData: {
        leasebackDays: "14",
        optionFee: "350",
        nonRealtyItem: "N/A",
        downPayment: "108500",
        earnestMoney: "4400",
        possession: "Leaseback",
        closingDate: "2023-08-10",
        financingType: "Conventional",
        saleOfOtherProperty: "No",
        rightToTerminate: "Partial Waiver",
        optionDays: "3",
        financeAmount: "331500",
        sellerConcessions: "N/A",
        purchasePrice: 440000,
      },
      sentById: "dfghjklyt45678",
      sentAt: 1689099875,
    },
  ],
  financeAmount: 331500,
  viewedBy: "Stephen Lewis",
};

export default dummyOffer;
