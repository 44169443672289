import React, { useEffect, useState } from "react";
import { Container, Row, Col, Modal, ModalHeader, ModalBody, FormGroup, Input } from "reactstrap";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import firebase from "firebase/app";

const AllUsers = (props) => {
  const [listingsPerPageFilter, setListingsPerPageFilter] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [comment, setComment] = useState('');
  const [email, setEmail] =  useState('');
  const [totalUsers, setAllUsers] = useState([]);

  useEffect(() => {
    if(props.user.broker_license) {
      const db = firebase.firestore();
      db.collection('realtors').where('broker_license','==', props.user.broker_license).get().then(user => {
        setAllUsers(user.docs);
      });
    }
  }, [])

  const sendInvite = () => {
    const sendEmail = firebase.functions().httpsCallable("sendMatchEmail");
    const body = `Hello Dear,<br /><br />
    You have been invited by ${props.user.realtor_name} from Homematchx. Click Here to join Homematchx.<br /><br /><br />
    <b>Comment: </b> ${comment}

    Regards,<br />
    Homematchx`

    sendEmail({ subject: `Join Homematchx`, email, body });

    toast.success(`Invite has been sent successfully to ${email}.`);
    setOpenModal(!openModal);
    setComment('')
    setEmail('')
  }

  const showInviteModal = <Modal dal isOpen={openModal} size="md" fade={false} toggle={() => setOpenModal(!openModal)}>
  <ModalHeader className="modal-header" toggle={() => setOpenModal(!openModal)}></ModalHeader>
    <ModalBody className="modal-body">
      <Container>
        <Row>
          <Col>
            <p className="invite-user-heading">Invite User</p>
            <FormGroup className="form-group pt-14">
              <Input
                name="nonRealtyItem"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="input-text custom-input"
                placeholder="Email here"
              />
            </FormGroup>
            <FormGroup className="form-group pt-14">
              <Input
                type="textarea"
                name="comments"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="input-text input-area"
                placeholder="Add invitation comments here..."
                required
              />
            </FormGroup>
            <div className="flex-center">
              <button className="cancel-btn mVertical-30" onClick={() => setOpenModal(false)}>Close</button>
              <button className="cancel-btn mVertical-30" style={{backgroundColor: '#28A745'}} onClick={sendInvite}>Invite</button>
            </div>
          </Col>
        </Row>
    </Container>
  </ModalBody>
</Modal> 

  return (
      <div className="content-area5">
        {showInviteModal}
        <div className="dashboard-content activities-dashboard">
          <Container>
            <Row>
              <Col>
                <h5 className="history-of-changes all-users-heading">All Users</h5>
              </Col>
            </Row>
            <Row>    
              <Col>
                <div className="all-offers">
                  <Row>
                    <Col className="row-spaceBtwn">
                      <button className="invite-user-btn" onClick={() => setOpenModal(true)}>Invite User</button>
                      <div className="period-filter d-flex justify-content-end align-items-center mb-4">
                        <span className="period-filter-label result-per-page-label">Result Per Page:</span>
                        <div className="d-inline-block">
                          <select
                            name="offerStatusFilter"
                            value={listingsPerPageFilter}
                            // onChange={e => {
                            //   setListingsPerPageFilter(e.target.value)
                            //   setTotalPages(allOffers.length/e.target.value)
                            //   handlePageClick({
                            //     selected: 0
                            //   })
                            // }}
                            className="search-fields offers-search-fields mr-0 mr-md-4"
                          >                           
                            <option>{listingsPerPageFilter}</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Borker no</th>
                          <th>License No</th>
                          <th className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          totalUsers.map(u => {
                            const user = u.data()
                            // if(user.realtor_name === 'Robert Lewis') console.log('---------------> ', user);
                            console.log(user.realtor_email, user.realtor_name)
                            return <tr key={u.id} className="all-offers-table-row analytics-find-buyer-table">
                              <td className="address-content-width">
                                <Link to={`/account/${u.id}`} className="user-profile-btn">
                                  {user.realtor_name} ({user.authRole || 'Agent'})
                                </Link>
                              </td>
                              <td>
                                <p className="users-table-data">{user.realtor_email}</p>
                              </td>
                              <td>
                                <p className="users-table-data">{user.broker_license}</p>
                              </td>
                              <td>
                                <p className="users-table-data">{user.realtor_license}</p>
                              </td>
                              <td className="align-column-center">
                                <Link to={`/analytics/${u.id}`} className="other-agents-filter mb-8">
                                  View Analytics
                                </Link>
                                <Link to={`/dashboard/${u.id}`} className="other-agents-filter mb-8">
                                  View Dashbaord
                                </Link>
                              </td>
                            </tr>
                          })
                        }                
                      </tbody>
                    </table>
                  </div>
                </div>   
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }


export default AllUsers;
