import React from "react";
import moment from "moment";

function offerDetails(requestInfo, sellerDetails, offerId) {
  if (requestInfo.financingType === "Cash") {
    requestInfo.downPayment = requestInfo.purchasePrice;
    requestInfo.financeAmount = 0;
  }
  
  return `<!DOCTYPE html>
    <html lang="en" style="font-family:'Raleway', sans-serif;-webkit-text-size-adjust:100%;-ms-text-size-adjust:100%;-webkit-tap-highlight-color:transparent;">
      <head>
        <meta charset="utf-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
        <meta content="width=device-width,initial-scale=1" name="viewport">
        <title>
          Homematchx
        </title>
        <meta content="width=device-width, initial-scale=1.0, shrink-to-fit=no" name="viewport">  
        <link href="./assets/css/custom.css" rel="stylesheet">
        <style type="text/css">
          @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,700;1,900&display=swap');
              @media (max-width: 550px) {
                .home-with-logo {
                  height: 360px !important;
                }
              }
              @media (max-width: 500px) {
                .home-with-logo {
                  height: 326px !important;
                }
                .logo {
                  position: relative !important;
                  z-index: 1 !important;
                  width: 80px !important;
                  height: 33px !important;
                  margin-top: 29px !important;
                  margin-left: 4% !important;
                }
              }
              @media (max-width: 450px) {
                .home-with-logo {
                  height: 294px !important;
                }
              }
              @media (max-width: 460px) {
                .home-img {
                  margin: 16px 0 0 !important;
                  height: auto !important;
                  width: 100% !important;
                }
                .row {
                  display: inline-block !important;
                  width: 100% !important;
                }
                .agent-details {
                  display: inline-table !important;
                  height: auto !important;
                  max-height: none !important;
                  text-align: center !important;
                }
                .flex-spacebtwn {
                  display: block !important;
                }
                .property-img {
                  width: 100% !important;
                  height: auto !important;
                  margin: 0 !important;
                }
                .mobile-badge-row {
                  width: auto !important;
                  display: -webkit-inline-box !important;
                }
                .price-details {
                  width: auto !important;
                }
                .agent-profile {
                  width: 100% !important;
                }
                .width-50 {
                  display: block !important;
                  width: 100% !important;
                  min-width: 100% !important;
                }
                .ist-table {
                  border-right: 0 !important;
                }
                .mobile-table-margin {
                  margin-top: 20px !important;
                }
                .offer-tag {
                  margin-top: 5px !important;
                  margin-bottom: 0 !important;
                }
                .property-details {
                  margin: 20px 0 5px !important;
                }
                .logo {
                  position: relative !important;
                  z-index: 1 !important;
                  width: 60px !important;
                  height: 26px !important;
                  margin-top: 21px !important;
                  margin-left: 5% !important;
                }
                .bold-td {
                  width: 160px !important;
                }
              }
              @media (max-width: 415px) {
                .home-with-logo {
                  height: 272px !important;
                }
              }
              @media (max-width: 376px) {
                .home-with-logo {
                  height: 248px !important;
                }
              }
              @media (max-width: 320px) {
                .home-with-logo {
                  height: 210px !important;
                }
                .logo {
                  position: relative !important;
                  z-index: 1 !important;
                  width: 51px !important;
                  height: 22px !important;
                  margin-top: 18px !important;
                  margin-left: 5% !important;
                }
              }
        </style>
      </head>
      <body style="margin:0;font-family:'Raleway', sans-serif;height:100%;width:100%;background:#f8f8f8;">
        <p/>
        </p>
        <div class="container" style="width:100%;max-width:600px;padding:0;background:white;padding-bottom:0;overflow:hidden;margin:30px auto;margin-top:30px;margin-bottom:30px;">
          <div class="home-with-logo" style="background-image:url(https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fhome.png?alt=media&token=3dbd5ba5-5d30-42bd-a9b4-7e127f914312);height:391px;width:100%;background-repeat:no-repeat;background-size:contain;">
            ${
              sellerDetails.licenseNo === "9005664"
                ? `<img src="https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fagent-logo1.png?alt=media&token=a05bd164-dc49-4185-8c4e-18623830fbae" alt="logo" class="logo" style="position:relative;z-index:1;width:101px;height:37px;margin-top:36px;margin-left:4%;"/>`
                : `<img src="https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fblack-logo.png?alt=media&token=eaa1f864-8856-4248-b050-5f2b1775465b" alt="homematchx-logo" style="position:relative;z-index:1;width:101px;height:28px;margin-top:36px;margin-left:4%;"/>`
            }
            <!-- <img src="https://i.ibb.co/zSXgz6F/home.png" alt="home" class="home-img"> -->
          </div>
          <div class="inner-container" style="margin:0 4.5%;">
            <p style="font-size: 16px; font-weight: 500; line-height: 27px;">
              <span style="font-weight: 700; font-size: 16px; line-height: 40px; margin-bottom: 20px;">Hello ${
                sellerDetails.userName || ""
              },</span><br>
              ${requestInfo.comments || "N/A"}
            </p>
            <h4 style="text-align: center; font-weight: 700; font-size: 16px; text-align: center; margin: 37px 0 19px 0;">Please consider our offer highlights below.</h4>
            <div class="table-container" style="border:1px solid #f5f5f5;padding:16px;">
              <div class="flex-spacebtwn" style="display:flex;flex-direction:row;justify-content:space-between;">
                <div class="row" style="display:flex;flex-direction:row;">
                  <img src="${
                    sellerDetails.featured_image.url ||
                    "https://homematchx.com/static/media/house-avatar.2d5a9ac4.jpg"
                  }" class="property-img" style="width:142px;height:90px;margin-right:16px;"/>
                  <div>
                    <p class="offer-tag" style="font-size:8.6px;font-weight:500;color:#999999;margin:0;margin-bottom:5px;">Offer</p>
                    <div class="row mobile-badge-row" style="display:flex;flex-direction:row;">
                      <p class="badge review" style="font-size:7px;font-weight:bold;border-radius:4px;padding:3px 8px;margin-right:4px;background-color:#FFC107;color:#222222;">In Review</p>
                      <p class="badge received" style="font-size:7px;font-weight:bold;border-radius:4px;padding:3px 8px;margin-right:4px;background-color:#A3A4A5;color:white;">Received</p>
                    </div>
                    <h5 class="property-address" style="color:#222222;font-size:10.75px;font-weight:bold;margin:0;">
                      ${
                        sellerDetails.street_number
                          ? sellerDetails.street_number
                          : ""
                      } ${
    sellerDetails.street_name ? sellerDetails.street_name : ""
  }, ${sellerDetails.city ? sellerDetails.city + ", " : ""} ${
    sellerDetails.state ? sellerDetails.state : ""
  }, ${sellerDetails.zip_code ? sellerDetails.zip_code : ""}
                    </h5>
                    <p class="buyers-agent" style="color:#999999;font-size:8.6px;font-weight:500;">
                      <img src="https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fperson.png?alt=media&token=49259c6f-3ff2-42e0-88ad-c21bcb1a59c2" style="margin-right: 4px;" height="6.45" width="5.37">
                      buyers agent <span class="buyer-agent-name" style="text-decoration:underline;color:#222222;">
                        ${requestInfo.agentName}
                      </span>
                    </p>
                    <p class="ad-time" style="color:#222222;font-size:8.6px;font-weight:500;">
                      <img src="https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fcalendar.png?alt=media&token=00d05cf2-58b8-4b23-bc3d-cf9ca728524d" style="margin-right: 4px;" height="6.45" width="5.91">
                      ${moment(requestInfo.createdAt).format("MMMM Do YYYY, [at] h:mm:ss a")} CST (${moment(requestInfo.createdAt).fromNow()})
                    </p>
                  </div>
                </div>
                <div class="price-details" style="padding:16px;background-color:#E9ECEF;width:140px;">
                  ${requestInfo.purchasePrice ? `<p style="font-size: 8.6px; font-weight: 500; color: #222222; line-height: 9px; margin: 0 0 6px 0;">Purchase Price</p>
                  <p style="font-weight: bold; line-height: 11px; color: #222222; font-size: 10.75px; margin: 0;">${
                    requestInfo.purchasePrice ? "$" : ""
                  }${requestInfo.purchasePrice}</p>` : ""}
                  
                  ${requestInfo.downPayment ? `
                    <p style="font-size: 8.6px; font-weight: 500; color: #222222; line-height: 9px; margin: 10.75px 0 6px 0;">Down Payment</p>
                    <p style="font-weight: bold; line-height: 11px; color: #222222; font-size: 10.75px; margin: 0;">${
                    requestInfo.downPayment ? `$${requestInfo.downPayment}` : ""
                  }
                  </p>` : ""}
                  ${requestInfo.financeAmount !== undefined ? `
                    <p style="font-size: 8.6px; font-weight: 500; color: #222222; line-height: 9px; margin: 10.75px 0 6px 0;">Finance Amount</p>
                    <p style="font-weight: bold; line-height: 11px; color: #DC3545; font-size: 10.75px; margin: 0;">$${requestInfo.financeAmount}</p>
                  ` : ''}                  
                </div>
              </div>
              <div class="property-details" style="margin:52px 0;">
                <table width="100%" style="border-collapse:collapse;">
                  <tr style="height:32.24px;vertical-align:baseline;">
                    <td class="width-50" style="width:50%;">
                      <table class="ist-table" style="border-collapse:collapse;border-right:13px solid white;width:100%;">
                        ${requestInfo.financingType ? `<tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                          <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Finance Type</td>
                          <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                            requestInfo.financingType || ""
                          }</td>
                        </tr>` : ""}
                        ${requestInfo.downPayment ? `<tr style="height:32.24px;vertical-align:center;">
                          <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Down Payment</td>
                          <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                            requestInfo.downPayment
                              ? `$${requestInfo.downPayment}`
                              : ""
                          }</td>
                        </tr>`: ""}
                        ${requestInfo.financeAmount !== undefined ? `
                          <tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                            <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Finance Amount</td>
                            <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">$${requestInfo.financeAmount}</td>
                          </tr>
                        ` : ''}                        
                        ${requestInfo.sellerConcessions ? `<tr style="height:32.24px;vertical-align:center;">
                          <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Seller Concession</td>
                          <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                            requestInfo.sellerConcessions
                              ? `$${requestInfo.sellerConcessions}`
                              : ""
                          }</td>
                        </tr>` : ""}
                        ${requestInfo.earnestMoney ? `<tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                          <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Earnest Money Deposit</td>
                          <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                            requestInfo.earnestMoney
                              ? `$${requestInfo.earnestMoney}`
                              : ""
                          }</td>
                        </tr>` : ""}
                        ${requestInfo.titlePolicyExpense ? `<tr style="height:32.24px;vertical-align:center;">
                          <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Survey Expense</td>
                          <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                            requestInfo.titlePolicyExpense || ""
                          }</td>
                        </tr>` : ""}
                        ${requestInfo.residentialServiceContract ? `<tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                          <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Service Contract</td>
                          <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">
                          ${
                            requestInfo.residentialServiceContract
                              ? `$${requestInfo.residentialServiceContract}`
                              : ""
                          }</td>
                        </tr>` : ""}
                         ${
                           requestInfo.offer_expire_date
                             ? `<tr style="height:32.24px;vertical-align:center;">
                          <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Offer Expire Date</td>
                          <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${requestInfo.offer_expire_date}</td>
                        </tr>`
                             : ""
                         }
                      </table>
                    </td>
                    <td class="width-50" style="width:50%;">
                      <div class="mobile-table-margin">
                        <table width="100%" style="border-collapse:collapse;">
                          ${requestInfo.closingDate ? `<tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                            <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Closing Date</td>
                            <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                              requestInfo.closingDate || ""
                            }</td>
                          </tr>` : ""}
                          ${requestInfo.leasebackDays ? `<tr style="height:32.24px;vertical-align:center;">
                            <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Leaseback Days</td>
                            <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                              requestInfo.leasebackDays
                                ? requestInfo.leasebackDays + " days"
                                : ""
                            }</td>
                          </tr>` : ""}
                          ${requestInfo.possession ? `<tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                            <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Possession</td>
                            <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                              requestInfo.possession || ""
                            }</td>
                          </tr>` : ""}
                          ${requestInfo.optionFee || requestInfo.optionDays ? `<tr style="height:32.24px;vertical-align:center;">
                            <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Option Fee and Option Days</td>
                            <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">
                            ${
                              requestInfo.optionFee
                                ? `$${requestInfo.optionFee}`
                                : ""
                            }
                            ${
                              requestInfo.optionDays
                                ? ", " + requestInfo.optionDays + " days"
                                : ""
                            }</td>
                          </tr>` : ""}
                          ${requestInfo.nonRealtyItem ? `<tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                            <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Non Realty Item</td>
                            <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                              requestInfo.nonRealtyItem || ""
                            }</td>
                          </tr>` : ""}
                          ${requestInfo.saleOfOtherProperty ? `<tr style="height:32.24px;vertical-align:center;">
                            <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Sale of Other Property</td>
                            <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                              requestInfo.saleOfOtherProperty || ""
                            }</td>
                          </tr>` : ""}
                          ${requestInfo.rightToTerminate ? `<tr style="height:32.24px;vertical-align:center;background-color:#E9ECEF;">
                            <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Right To Terminate Due to Lender’s Appraisal</td>
                            <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${
                              requestInfo.rightToTerminate || ""
                            }</td>
                          </tr>` : ""}
                          ${requestInfo.bac ? `<tr style="height:32.24px;vertical-align:center;">
                            <td class="bold-td" style="color:#222222;font-size:8.6px;line-height:10px;font-weight:bold;padding:0 16px;">Buyers Agent Compensation</td>
                            <td class="normal-td" style="font-size:8.6px;color:#222222;font-weight:500;line-height:10px;padding:0 16px;">${requestInfo.bac || 'N/A'}${requestInfo.bac_type === "Percentage" ? "%" : ""}</td>
                          </tr>` : ""}
                        </table>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div style="text-align: center; display: grid; margin: 25px 0;">
              <a href="https://homematchx.com/offers/${offerId}?curItem=1&totalItems=1" class="review-offer" style="margin:9px auto;width:240px;padding:13px;font-weight:500;font-size:18px;color:#ffffff;background-color:#092267;border-radius:5px;border:1px solid #092267;cursor:pointer;text-decoration:none;">Review offer</a>
              <a href="#" class="share" style="margin:9px auto;width:240px;padding:13px;font-weight:500;font-size:18px;color:#092267;background-color:white;border-radius:5px;border:1px solid #092267;cursor:pointer;text-decoration:none;">Share</a>
              <a href="#" class="confirm-receipt" style="font-size:16px;color:#666666;margin:9px auto;padding:13px;font-weight:500;text-decoration:underline;background-color:transparent;border:0;cursor:pointer;">Confirm receipt</a>
            </div>
          </div>
          <div class="agent-details" style="background-color:#F4F4F4;padding:25px 0;display:flex;flex-direction:row;max-height:250px;">
            <div class="agent-profile" style="text-align:center;border-right:1px solid #DFDFDF;min-width:240px;margin:auto;">
              <img src=${
                sellerDetails?.seller_email === "inkrealtygroup@gmail.com"
                  ? "https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fagent.png?alt=media&token=eb00ef64-b69a-4d70-a70f-2a7383bae9f2"
                  : "https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fsimple-avatar.png?alt=media&token=70d58991-4616-46af-b287-7fe0d29d8426"
              } class="agent" style="width:110px;height:110px;border-radius:110px;" />
              <p class="agent-name" style="color:#000000;font-size:16px;line-height:22px;font-weight:700;margin: 8px 0">${
                sellerDetails?.seller_name
              }</p>
              ${
                sellerDetails?.seller_email === "inkrealtygroup@gmail.com"
                  ? `<p class="agent-company" style="font-weight:400;font-size:14px;color:#000000;margin:0;">Agent, Ink Realty Group</p>`
                  : ""
              }
            </div>
            <div class="agent-contact-info" style="padding:0 26px;">
              <p style="font-size: 14px; font-weight: 400; color: #000000;">Should you have any questions or would like to speak about the offer, please contact me below.</p>
              <div style="margin: 25px 0;">
                ${
                  sellerDetails?.seller_email === "inkrealtygroup@gmail.com"
                    ? `<p style="font-size: 16px; font-weight: 400; color: #000000; margin: 8px 0;">Direct:  <b>469-912-0465</b></p>`
                    : ""
                }
                <p style="font-size: 16px; font-weight: 400; color: #000000; margin: 8px 0;">Office:  <b>${sellerDetails?.seller_phone?.replace(
                  /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
                  "$1$2$3-$4$5$6-$7$8$9$10"
                )}</b></p>
              </div>
              <a href="mailto:${
                sellerDetails?.seller_email
              }" style="color: #092267; font-size: 16px; text-decoration: underline; cursor: pointer !important;">${
                sellerDetails?.seller_email
  }</a>
            </div>
          </div>

          <!-- <div class="agent-details" style="background-color:#F4F4F4;padding:25px 0;display:flex;flex-direction:row;max-height:250px;">
            <div class="agent-profile" style="text-align:center;border-right:1px solid #DFDFDF;min-width:240px;margin:auto;">
              <img src=${
                requestInfo.licenseNo === "9005664"
                  ? "https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fagent.png?alt=media&token=eb00ef64-b69a-4d70-a70f-2a7383bae9f2"
                  : "https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fsimple-avatar.png?alt=media&token=70d58991-4616-46af-b287-7fe0d29d8426"
              } class="agent" style="width:110px;height:110px;border-radius:110px;" />
              <p class="agent-name" style="color:#000000;font-size:16px;line-height:22px;font-weight:700;margin: 8px 0">${
                requestInfo.agentName
              }</p>
              ${
                requestInfo.licenseNo === "9005664"
                  ? `<p class="agent-company" style="font-weight:400;font-size:14px;color:#000000;margin:0;">Agent, Ink Realty Group</p>`
                  : ""
              }
            </div>
            <div class="agent-contact-info" style="padding:0 26px;">
              <p style="font-size: 14px; font-weight: 400; color: #000000;">Should you have any questions or would like to speak about the offer, please contact me below.</p>
              <div style="margin: 25px 0;">
                ${
                  requestInfo.licenseNo === "9005664"
                    ? `<p style="font-size: 16px; font-weight: 400; color: #000000; margin: 8px 0;">Direct:  <b>469-912-0465</b></p>`
                    : ""
                }
                <p style="font-size: 16px; font-weight: 400; color: #000000; margin: 8px 0;">Office:  <b>${requestInfo.phoneNumber?.replace(
                  /(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)(\d)/,
                  "$1$2$3-$4$5$6-$7$8$9$10"
                )}</b></p>
              </div>
              <a href="mailto:${
                requestInfo.buyersEmail
              }" style="color: #092267; font-size: 16px; text-decoration: underline; cursor: pointer !important;">${
    requestInfo.buyersEmail
  }</a>
            </div>
          </div> -->
          <div class="about-us" style="padding:26px 30px;background-color:#092267;text-align:center;">
            <img src="https://firebasestorage.googleapis.com/v0/b/homematchx-prod.appspot.com/o/images%2Fhomematchx-white-logo.png?alt=media&token=064fcc2e-09ef-44c2-b341-5bf3def2040e" width="247" height="43">
            <p style="color: #ffff; font-size: 14px; font-weight: 500; line-height: 27px;">Homematchx is a market intelligence tool to enhances transparency when selling real estate by analyzing and aggregating purchase offers. It's designed to save agents time in managing offers terms and conditions when presenting to sellers. HMX collects and analyzes offer data to help sellers make better decisions when selling their home. </p>
          </div>
        </div>
        <footer>
        </footer>
      </body>
    </html>`;
}

export default offerDetails;
