import React, { useEffect, useState } from "react";
import Webcam from "react-webcam";
import firebase from "firebase/app";
import "firebase/firestore";
import UseWindowDimensions from '../components/Dimensions';

const videoConstraints = {
  facingMode: "right",
  width: 1920,
  height: 1080
};
var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const WebcamStreamCapture = (props) => {
  const { getVideoSource, modalToggle } = props;
  const webcamRef = React.useRef(null);
  const mediaRecorderRef = React.useRef(null);
  const [mic, setMic] = useState(true);
  const [mediaStreamId, setMediaStreamId] = useState();
  const [startRecording, setStartRecording] = React.useState(false);
  const [pauseRecording, setPauseRecording] = React.useState(false);
  const [capturing, setCapturing] = React.useState(false);
  const [recordedChunks, setRecordedChunks] = React.useState([]);
  const { width } = UseWindowDimensions();

  useEffect(() => {
    handleDownload()
  }, [recordedChunks])

  const handleStartCaptureClick = React.useCallback(() => {
    setCapturing(true);
    if (webcamRef.current.stream) {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: isSafari ? "video/mp4" : "video/webm"
      });
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
    }
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handlePauseCaptureClick = React.useCallback(() => {
    setPauseRecording(true)
    mediaRecorderRef.current.pause();
  }, [mediaRecorderRef, webcamRef]);

  const handleResumeCaptureClick = React.useCallback(() => {
    setPauseRecording(false)
    mediaRecorderRef.current.resume();
  }, [mediaRecorderRef, webcamRef]);

  const handleStopCaptureClick = React.useCallback(() => {

    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing, MediaRecorder]);

  const arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  const base64toBlob = (base64Data, contentType) => {
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  // const handleDownload = React.useCallback(() => {
  //   if (recordedChunks.length) {
  //     const url = URL.createObjectURL(new Blob(recordedChunks));
  //     const a = document.createElement("a");
  //     document.body.appendChild(a);
  //     a.style = "display: none";
  //     a.href = url;

  //     getVideoSource(url, new Blob(recordedChunks))
  //     modalToggle()
  //   }
  // }, [recordedChunks]);

  const handleDownload = React.useCallback(() => {
    if (recordedChunks.length) {
      const mimeType = isSafari ? "video/mp4" : "video/webm";
      const recordedBlob = new Blob(recordedChunks, { type: mimeType });
      const url = URL.createObjectURL(recordedBlob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;

      getVideoSource(url, recordedBlob);
      modalToggle()
    }
  }, [recordedChunks]);

  return (
    <>
      <div className="video-recorder-frame-container">
        <Webcam
          audio={mic}
          ref={webcamRef}
          controls={true}
          videoConstraints={videoConstraints}
          onUserMedia={e => setMediaStreamId(e.id)}
          style={{
            width: "100%",
            height: width < 450 ? 250 : 435,
            objectFit: "fill",
          }}
        />
      </div>
      <div className="row-spaceBtwn record-video-action-container">
        <div className="row-container volume-controls-container">
          <div className="range-slider-container">
            <img src={require('../assets/icons/greenMic.png')} />
            <label className="switch">
              <input type="checkbox" value={mic} defaultChecked={mic} onChange={e => setMic(e.target.checked)} />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        {mediaStreamId && <div>
          {startRecording ?
            <button className="play-pause-btn" onClick={() => pauseRecording ? handleResumeCaptureClick() : handlePauseCaptureClick()}>
              <img src={pauseRecording ? require('../assets/icons/play.png') : require('../assets/icons/pauseIcon.png')} />
            </button>
            : <button className="start-recording-btn" onClick={() => {
              setStartRecording(true)
              handleStartCaptureClick()
            }}>
              <img src={require('../assets/icons/Ellipse.png')} />
              Start Recording
            </button>}
        </div>}
        <div style={{ minWidth: 200 }}>
          {startRecording && <div className="row-container">
            <button className="restart-button" onClick={() => {
              setRecordedChunks([])
              setStartRecording(true)
              handleStartCaptureClick()
            }}>
              Restart
            </button>
            <button className="finish-recording-btn" onClick={() => {
              handleStopCaptureClick()
              setStartRecording(false)
            }}>
              <img src={require('../assets/icons/whiteCheck.png')} />
              Finish recording
            </button>
          </div>}
        </div>
      </div>
    </>
  );
};

export default WebcamStreamCapture;