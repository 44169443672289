import React, { useEffect, useState } from "react";
import Select from "react-select";

function Step1({ user, offers, ...props }) {
  const options = offers.map((offer) => ({
    value: offer.id,
    label: offer.address,
  }));

  return (
    <div className="row">
      <div className="col-md-12">
        <p>Select an executed offer from the dropdown below.</p>
      </div>
      <div className="col-md-12">
        <Select
          options={options}
          //   onChange={(e) => props.setSelectedOfferId(e.value)}
        />
      </div>
      <div className="col-md-12">
        <button className="btn btn-success float-right"
        onClick={() => props.nextStep()}
        >Next</button>
        <button className="btn btn-primary float-right mr-2">Cancel</button>
        <button className="btn btn-primary float-right mr-2">Back</button>
      </div>
    </div>
  );
}

export default Step1;
