import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, Button, Table } from "reactstrap";
import NumberFormat from "react-number-format";
import Dropzone from "react-dropzone";
import moment from "moment";

// Helper function to format currency or date values
const formatValue = (item) => {
  if (item.isCurrency) {
    return (
      <NumberFormat
        value={item.value}
        displayType="text"
        thousandSeparator
        prefix="$"
      />
    );
  } else if (item.isDate) {
    return moment(item.value).format("YYYY-MM-DD");
  }
  // Plain text (plus optional suffix)
  return item.suffix ? `${item.value}${item.suffix}` : item.value;
};

const SettleOfferTermsModal = (props) => {
  const { showModal, hideModal, offer, latestAmmendOffer, onSettlementDrop, toggleSettleTermsConfirmationModal, showSettleTermsConfirmationPopup } = props;
  const [showFullTable, setShowFullTable] = useState(false);

  const fields = [
    { label: "Offer Type", key: "offerType" },
    { label: "Buyer's Agent Compensation", key: "bac" },
    { label: "Purchase Price", key: "purchasePrice", isCurrency: true },
    { label: "Closing Date", key: "closingDate", isDate: true },
    { label: "Possession", key: "possession" },
    { label: "Finance Type", key: "financingType" },
    { label: "Non Realty Item", key: "nonRealtyItem" },
    { label: "Down Payment", key: "downPayment", isCurrency: true },
    { label: "Sale of Other Property", key: "saleOfOtherProperty" },
    { label: "Finance Amount", key: "financeAmount", isCurrency: true },
    { label: "Right To Terminate Due to Lender’s Appraisal", key: "rightToTerminate" },
    { label: "Seller Concession", key: "sellerConcessions", isCurrency: true },
    { label: "Earnest Money Deposit", key: "earnestMoney", isCurrency: true },
    { label: "Leaseback Days", key: "leasebackDays", suffix: " days" },
    { label: "Title Expense", key: "titlePolicyExpense" },
    { label: "Option Fee", key: "optionFee", isCurrency: true },
    { label: "Option Days", key: "optionDays", suffix: " days" },
  ];

  const data = fields.map((field) => {
    const rawValue =
      latestAmmendOffer && latestAmmendOffer[field.key] != null
        ? latestAmmendOffer[field.key]
        : offer && offer[field.key] != null
        ? offer[field.key]
        : null;

    return {
      ...field,
      value: rawValue,
    };
  });

  const filteredData = data.filter((item) => item.value !== null && item.value !== undefined);

  const rowsToShow = showFullTable ? filteredData : filteredData.slice(0, 4);

  return (
    <>
      <Modal isOpen={showModal} toggle={hideModal} size="lg">
        <ModalHeader toggle={hideModal}>Finalize Closing Terms</ModalHeader>
        <ModalBody>
          <p className="mt-4">
          You are about to finalize the closing terms for the property at{" "}
          <span style={{fontWeight: 'bold'}}>{offer.address}</span>, with a purchase price of <NumberFormat
              value={latestAmmendOffer?.purchasePrice || offer.purchasePrice}
              displayType="text"
              style={{fontWeight: 'bold'}}
              thousandSeparator
              prefix="$"
            />. Please review all terms carefully before proceeding. Once the closing terms are settled, no further changes can be made, including amendments, status, or execution updates.
          </p>

          <Table className="settle-offer-table">
            <tbody>
              {rowsToShow.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="font-weight-bold">{item.label}</div>
                    <div>{formatValue(item)}</div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <div className="text-center">
            {filteredData.length > 4 && (
            <Button
            className="view-scoring-details-btn mb-0"
            onClick={() => setShowFullTable(!showFullTable)}
            >
            {showFullTable ? "Show Less" : "Show More"}
            <img
              className={showFullTable ? "arrow-down-flipped" : "arrow-down-unflipped"}
              src={require("../assets/icons/list-arrow-down-green.png")}
              alt="Toggle arrow"
            />
            </Button>
            )}
          </div>
          <div className="terms-and-conditions-heading mt-3">
            Settlement document (Optional):
          </div>

          <Dropzone
            onDrop={(files, rejectedFiles) => onSettlementDrop(files, rejectedFiles)}
            maxSize={20000000}
            disabled={props.uploadingDocument}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  id="settlementDropZone"
                  className="dropzone dropzone-design flex-center"
                  style={{
                    height: 240,
                    fontSize: 12,
                    padding: 10,
                    marginBottom: 20,
                    marginTop: 15,
                  }}
                >
                  <div className="dz-message" style={{ fontSize: 14 }}>
                    <span>
                      {props.uploadingDocument
                        ? "Uploading settlement document..."
                        : "Please upload the settlement document."}
                    </span>
                    <br />
                    {!props.uploadingDocument && (
                      <span>Drop file less than 20 MB here to upload.</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Dropzone>

          {props.settlementDocs?.length > 0 && (
            <div className="terms-and-conditions-heading mb-2">
              {props.settlementDocs.length > 1
                ? "Uploaded Settlement Documents"
                : "Uploaded Settlement Document"}
            </div>
          )}

          {props.settlementDocs?.map((doc, key) => (
            <div className="download-details" key={key}>
              <span className="margin-0">{`Settlement Doc. ${doc.name}`}</span>
              <a href={doc.path} target="_blank" rel="noopener noreferrer">
                <img src={require("../assets/icons/Plus.png")} className="download-doc" alt="download" />
              </a>
              <img
                onClick={() => props.deleteSettlementDocument(doc)}
                src={require("../assets/icons/bin.png")}
                alt="Delete"
                className="ml-2 delete-btn-icon"
                style={{ cursor: "pointer" }}
              />
            </div>
          ))}

          <div className="settle-offer-action-buttons">
            <Button color="secondary" onClick={hideModal}>
              Cancel & Review 
            </Button>
            <Button disabled={props.uploadingDocument} className="settle-btn"  onClick={toggleSettleTermsConfirmationModal}>
              {props.settleOfferLoading ? "Please wait..." : "Confirm & Finalize"}
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={showSettleTermsConfirmationPopup} toggle={toggleSettleTermsConfirmationModal} size="md">
        <ModalHeader toggle={toggleSettleTermsConfirmationModal}>
          Confirm Finalization
        </ModalHeader>
        <ModalBody>
          <p>
            You are about to finalize the closing terms for{" "}
            <span style={{ fontWeight: "bold" }}>{offer.address}</span>. Please review carefully. Once finalized, terms cannot be changed.
          </p>
          <div className="settle-offer-action-buttons">
            <Button color="secondary" onClick={toggleSettleTermsConfirmationModal}>
              Cancel
            </Button>
            <Button disabled={props.settleOfferLoading} className="settle-btn" onClick={() => props.saveSettlementOffer()}>
              {props.settleOfferLoading ? "Please wait..." : "Confirm & Finalize"}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default SettleOfferTermsModal;
