import React, { Component } from 'react'
import { connect } from "react-redux";
import { Link } from 'react-router-dom'
import Header from 'components/Header'
import Footer from 'components/Footer'
import firebase from 'firebase/app'
import 'firebase/auth'
import * as actions from './../Store/Actions/type';

class Main extends Component {
    constructor(props) {
        super(props)

        this.state = {
            error: '',
            inputs: {
                PPMJH: ''
            },
            formsSubmitted: {},
            codes:[],
        }
    }

    componentDidMount(){
        const {dispatch,user} = this.props;
        if(user){
            const db = firebase.firestore();
            db.collection('subscriptions').doc(user.uid).onSnapshot(snap => {
                const data = snap.data();
                dispatch({
                    type:actions.SET_EXPIRY,
                    payload: (data) ?  data.expiry : 0
                });
            });

            db.collection('trackingCodes').get().then(snap => {
                const codes = [];
                snap.docs.forEach(doc => {
                    codes.push(doc.data().code);
                });
                this.setState({
                    codes
                });
            });
        }
    }

    render() {
        const {codes} = this.state;
        return (
            <>
                <header className="main-header header-transparent sticky-header">
                    <Header {...this.props} />
                </header>
                {this.props.children}
                <Footer {...this.props} />
                <div className="sub-footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8">
                                <p className="copy">
                                    Copyright © 2025&nbsp;
                                    <Link to="#">Homematchx.</Link>
                                    Trademarks and brands are the property of
                                    their respective owners.
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-4">
                                <ul className="social-list clearfix">
                                    <li>
                                        <a className="facebook" rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/homematchx">
                                            <i className="fa fa-facebook" />
                                        </a>
                                    </li>
                                    <li>
                                        <a className="twitter" rel="noopener noreferrer" target="_blank" href="https://twitter.com/homematchx">
                                            <i className="fa fa-twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a className="linkedin" rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/homematchx">
                                            <i className="fa fa-linkedin" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tracking-codes" >
                    {codes.forEach(c => {
                        // eslint-disable-next-line
                        (new Function (c))()
                    })}
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ PaymentReducer }) => {
    return {
      ...PaymentReducer
    };
  };
  
  const mapDispatchToProps = dispatch => {
    return {
      dispatch
    };
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Main);

