import React, { Component } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import Alert from "components/Alert";
import "firebase/analytics";
import { update } from "lodash";
import nextPrev from "../assets/icons/next-prev.png";
let { logEvent } = firebase.analytics();

class Subscription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: "",
      loading: false,
      formsSubmitted: {},
      activeBtn: 0,
    };
  }

  render() {
    let alert = null;
    const { error, loading, activeBtn } = this.state;

    if (error) {
      alert = <Alert>{error.message}</Alert>;
    }

    return (
      <>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="pricing-card">
              <div className="pricing-card-text">
                <div className="text-center">
                  <p className="pricing-card-green-text">
                    Try it out and build strong offer data on the Automation
                    Platform.
                  </p>
                  <h3>FREE</h3>
                </div>
                <ul>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>
                    Monetize your listing activity data on Homematchx (Keep 80%
                    of revenue)
                  </li>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>
                    Get paid instantly
                  </li>
                  <li>
                    <i className="fa fa-times mr-2 times-icon"></i>
                    Advertise your global listing activity data across platforms
                  </li>
                  <li>
                    <i className="fa fa-times mr-2 times-icon"></i>
                    Official Sales Report
                  </li>
                  <li>
                    <i className="fa fa-times mr-2 times-icon"></i>
                    Agent Support (Standard)
                  </li>
                </ul>
              </div>
              <div className="text-center mt-3">
                <button
                  className={`btn btn-outline-danger w-100 pricing-btn text-capitalize ${
                    activeBtn == 1 && "pricing-btn-active"
                  }`}
                  onClick={(e) => {
                    // this.handleSignupSubmit(e);
                    this.setState({ activeBtn: 1 });
                  }}
                >
                  Choose Plan
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6 mt-3 mt-md-0">
            <div className="pricing-card">
              <div className="pricing-card-text">
                <div className="text-center">
                  <p className="pricing-card-green-text">
                    The intentional plan. Access unlimited Global Offer Data
                    across the platform.
                  </p>
                  <h3>
                    $14.99 <span className="pricing-year">/year</span>
                  </h3>
                </div>
                <ul>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>
                    Monetize your listing activity data on Homematchx (Keep 100%
                    of revenue)
                  </li>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>
                    Get paid instantly
                  </li>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>
                    Advertise your global listing activity data across platforms
                  </li>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>
                    Official Sales Report
                  </li>
                  <li>
                    <i className="fa fa-check mr-2 check-icon"></i>
                    Agent Support (Standard)
                  </li>
                </ul>
              </div>
              <div className="text-center mt-3">
                <button
                  className={`btn btn-outline-danger w-100 pricing-btn text-capitalize ${
                    activeBtn == 2 && "pricing-btn-active"
                  }`}
                  onClick={(e) => {
                    // this.handleSignupSubmit(e);
                    this.setState({ activeBtn: 2 });
                  }}
                >
                  Choose Plan
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <button
            className="next-prev-btn mr-2"
            onClick={(e) => {
              this.props.previousStep();
            }}
          >
            <img
              src={nextPrev}
              className="backArrow"
              alt="back arrow"
              style={{ marginBottom: "2px", height: "10px" }}
            />
            Previous
          </button>
          {/* </div>
          <div className="col-12 col-md-6 col-lg-6"> */}
          <button
            className="next-prev-btn"
            onClick={(e) => {
              e.preventDefault();
              activeBtn == 1
                ? (window.location.href = "/dashboard")
                : this.props.nextStep();
            }}
          >
            Next
            <img
              src={nextPrev}
              className="backArrow ml-2"
              alt="back arrow"
              style={{
                transform: "rotate(180deg)",
                marginBottom: "2px",
                height: "10px",
              }}
            />
          </button>
          <button
            className="next-prev-skip-btn ml-2"
            onClick={(e) => {
              // skip all next steps
              window.location.href = "/dashboard";
            }}
          >
            Skip
          </button>
        </div>
      </>
    );
  }
}

export default Subscription;
