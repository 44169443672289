import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import LoadingComponent from "../components/Common/Loader";
import DataList from "./../components/DataList";
import firebase from "firebase/app";
import "firebase/firestore";
import FilterBox from "../components/FilterBox";
import SellerTile from "../components/SellerTile";
import { Media } from "react-breakpoints";
import { getFilteredHomes } from "../utils/Functions";
import { geohashQueryBounds, distanceBetween } from "geofire-common";
import JoyRide from "react-joyride";

const pageSize = 48;
let lastVisible = {};

class Homes extends DataList {
  constructor(props) {
    super(props, "sellers");

    const filterObj = localStorage.getItem("homeFilters") || "{}";

    this.state = {
      filters: JSON.parse(filterObj),
      collapsed: true,
      docs: [],
      hasMore: true,
      lengthBeforeFiltering: 0,
      isLoading: false,
      joyRide: this.props.user?.hasOwnProperty("sellerTileJoyRide")
        ? this.props.user?.sellerTileJoyRide
        : true,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const filters = this.state.filters;
    const prevFilters = prevState.filters;
    const hasChanged = (name) => filters[name] !== prevFilters[name];

    const fields = [
      ...new Set(Object.keys(filters).concat(Object.keys(prevFilters))),
    ];

    if (fields.some((f) => hasChanged(f))) {
      lastVisible = {};
      this.setState(
        {
          docs: [],
        },
        this.fetchResults
      );
    }
  }

  componentWillMount() {
    const { user } = this.props;
    if (!this.props.match.params.uid)
      return (window.location.href = "/homes/" + user.uid);
    lastVisible = {};
    this.setState(
      {
        hasMore: true,
      },
      this.fetchResults
    );
  }

  fetchResults() {
    let { docs } = this.state;

    const db = firebase.firestore();
    const { filters } = this.state ? this.state : {};
    const oldSize = docs.length;
    let searches = filters.searches;
    if (!searches && filters.location) {
      searches = [filters.location.name];
    }

    let { location } = filters;
    let generalQuery = db
      .collection("sellers")
      .where("MlsStatus", "==", "Active")
      .where("draft", "==", false)
      .where("seller_id", "==", this.props.match.params.uid)
      .where("removed", "==", false);
    let locationList = [];
    if (location) {
      let { lat, lng } = location;
      const bounds = geohashQueryBounds(
        [lat, lng],
        filters.distance ? parseInt(filters.distance) * 1000 : 10 * 1000
      ); // default distance 10 KM
      for (const b of bounds) {
        let q = generalQuery
          .orderBy("location.geohash")
          .startAt(b[0])
          .endAt(b[1]);
        locationList.push(q.get());
      }
    }
    const queries = (searches || [""]).map((search, index) => {
      if (filters && search) {
        generalQuery = generalQuery.where(
          "search",
          "array-contains",
          search.toLowerCase()
        );
      }

      if (lastVisible[index])
        generalQuery = generalQuery.startAfter(lastVisible[index]);

      // const keys = Object.keys(filters).filter(key => {
      //   return filters[key];
      // });

      // if(keys?.length === 0)
      //   generalQuery = generalQuery.orderBy("createdAt_unix", "desc")

      generalQuery = generalQuery.limit(pageSize);

      return generalQuery;
    });

    this.setState({
      isLoading: true,
    });

    const otherquery = queries.map((q) => q.get());

    const promises = [...otherquery, ...locationList];
    // const promises = [...otherquery]

    Promise.all(promises).then(
      (snapshots) => {
        const docSets = snapshots.map((s, index) => {
          if (s.docs.length > 0) {
            lastVisible[index] = s.docs[s.docs.length - 1];
          }
          return s.docs;
        });

        for (const docSet of docSets) {
          for (const doc of docSet) {
            if (!docs.some((d) => d.id === doc.id)) {
              docs.push(doc);
            }
          }
        }

        const lengthBeforeFiltering = docs.length;

        // client side filtering
        docs = getFilteredHomes(docs, filters);
        if (this.mounted) {
          this.setState({
            docs,
            hasMore: docs.length !== oldSize,
            lengthBeforeFiltering,
            isLoading: false,
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  componentDidMount() {
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  commitSearch(inputs) {
    const filters = Object.assign({}, inputs);
    this.setState({ filters, hasMore: true });
    lastVisible = {};
    localStorage.setItem("homeFilters", JSON.stringify(filters));
  }

  filters() {
    const form = (
      <div className="sidebar-left banner">
        <div className="widget advanced-search submit-address dashboard-list">
          <FilterBox
            title="Search Homes"
            commitSearch={this.commitSearch.bind(this)}
            filters={this.state.filters}
          />
        </div>
      </div>
    );

    return (
      <div className="col-md-3">
        <Media>
          {({ breakpoints, currentBreakpoint }) => {
            let content;
            if (breakpoints[currentBreakpoint] > breakpoints.mobileLandscape) {
              content = form;
            } else {
              const buttonContent = this.state.collapsed ? (
                <span>
                  <i className="fa fa-angle-double-down"></i>&nbsp;Show filters
                </span>
              ) : (
                <span>
                  <i className="fa fa-angle-double-up"></i>&nbsp;Hide filters
                </span>
              );

              content = (
                <div>
                  <button
                    style={{
                      marginBottom: 10,
                      border: 0,
                      cursor: "pointer",
                      backgroundColor: "transparent",
                    }}
                    onClick={(e) =>
                      this.setState({ collapsed: !this.state.collapsed })
                    }
                  >
                    {buttonContent}
                  </button>
                  {this.state.collapsed ? null : form}
                </div>
              );
            }

            return content;
          }}
        </Media>
        <a
          href="https://www.dallascowboys.com"
          rel="noopener noreferrer"
          target="_blank"
          style={{ marginBottom: 10 }}
        >
          <img alt="" className="img-fluid" src="/img/ads/a1.png"></img>
        </a>
      </div>
    );
  }

  filterMarkup() {
    const { filters } = this.state;
    const keys = Object.keys(filters).filter((k) => filters[k]);

    if (keys.length > 0) {
      return (
        <p style={{ marginBottom: 0, marginTop: 20 }}>
          <small>Filters: </small>
          {keys.map((k) => {
            let text = "";
            if (filters[k].constructor === Object) {
              text = filters[k].name;
            } else {
              text =
                filters[k].constructor === Array
                  ? filters[k].join(", ")
                  : filters[k];
            }
            return !text ? null : (
              <span key={k}>
                <span className="badge badge-secondary">{text}</span>
                <button
                  style={{
                    border: 0,
                    cursor: "pointer",
                    padding: "0px",
                    backgroundColor: "transparent",
                    color: "red",
                    marginLeft: "3px",
                  }}
                  onClick={(e) => this.removeFilter(k)}
                >
                  <i className="fa fa-times" />
                </button>
                &nbsp;&nbsp;
              </span>
            );
          })}
        </p>
      );
    } else {
      return null;
    }
  }

  removeFilter(filter) {
    const { filters } = this.state;
    const newFilters = Object.assign({}, filters);
    newFilters[filter] = "";
    localStorage.setItem("homeFilters", JSON.stringify(newFilters));
    this.setState({ filters: newFilters, hasMore: true });
  }

  render() {
    return (
      <React.Fragment>
        <div className="properties-section-body" style={{ paddingTop: 10 }}>
          <div className="container-fluid">
            <div className="row">
              {this.filters()}
              <div className="col-md-9">
                <a
                  href="https://www.ticketmaster.com"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img alt="" className="img-fluid" src="/img/ads/a2.png"></img>
                </a>

                {this.filterMarkup()}
                {this.results()}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  results() {
    const {
      docs,
      filters,
      hasMore,
      lengthBeforeFiltering,
      isLoading,
      joyRide,
    } = this.state;

    const { user } = this.props;

    const heading = (
      <h4 style={{ paddingTop: 25, paddingBottom: 25 }}>Homes for Sale</h4>
    );

    if (isLoading) {
      return (
        <React.Fragment>
          <LoadingComponent />
        </React.Fragment>
      );
    }

    if (!lengthBeforeFiltering) {
      return (
        <React.Fragment>
          {heading}
          <p>No results Found.</p>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <JoyRide
          size={30}
          steps={[
            {
              target: "#copy-icon-1",
              title: "Copy Link",
              content:
                "To recieve offers on this listing click the box to copy the link and paste in your MLS offer instructions.",
              disableBeacon: true,
              placement: "right",
            },
          ]}
          run={joyRide}
          callback={(data) => {
            const { action, status } = data;
            if (action === "close") {
              this.setState({ joyRide: false });
            }
            if (status === "finished") {
              this.setState({ joyRide: false });
              const db = firebase.firestore();
              db.collection("users").doc(user?.uid).update({
                sellerTileJoyRide: false,
              });
            }
          }}
          continuous={true}
          showProgress={true}
          showSkipButton={true}
          styles={{
            buttonNext: {
              color: "#ffffff",
              lineHeight: "0.9",
              padding: "10px",
            },
          }}
        />
        {heading}
        {/* <h6 style={{ paddingBottom: 15 }}>
          These homes are up for sale or will be in the near future
        </h6> */}
        <InfiniteScroll
          className="row"
          pageStart={0}
          loadMore={() => {
            this.fetchResults();
          }}
          hasMore={hasMore}
          loader={<React.Fragment key="0" />}
        >
          {docs.map((d, index) => (
            <SellerTile
              filters={filters}
              key={index + 1}
              id={index + 1}
              doc={d}
              user={this.props.user}
            />
          ))}
        </InfiniteScroll>
      </React.Fragment>
    );
  }
}

export default Homes;
